

























import { Component, Vue, PropSync, Emit, Watch } from 'vue-property-decorator';

@Component
export default class Fotter extends Vue {
  @PropSync('tableLength', { required: true }) items!: number;

  @PropSync('loading', { required: true }) loadingData!: boolean;

  @Watch('tableLength')
  resetPage(): void {
    this.page = 1;
  }

  public itemsPerPage = parseInt(localStorage.getItem('recordsPerPage') ?? '15', 10);

  public page = 1;

  get pageCount(): number {
    return Math.ceil(this.items / this.itemsPerPage); // round up
  }

  public setItemsPerPage(perPage: number): void {
    this.itemsPerPage = perPage;
    this.page = 1;
    this.setItemsRange();
  }

  @Emit('itemsRange')
  public setItemsRange(): number[] {
    return [this.itemsPerPage * (this.page - 1), this.page * this.itemsPerPage];
  }
}
