import * as XLSX from 'xlsx';
import { FetchedItem, Header } from '@/interfaces';
import { Export, ExportType } from '@/models/Export';

export default function exportData(
  format: Export,
  items: FetchedItem[],
  fileName: string,
  sheetName: string,
  selectedHeaders: Header[]
): void {
  const delimiter = ';';
  const indentations: number[] = [];
  const filteredItems = items.filter((e) => !e.artificial);
  filteredItems.forEach((e) => {
    if (!e.parentsIds) {
      indentations.push(0);
    } else {
      indentations.push(e.parentsIds.length + 1);
    }
  });
  const modefiedItems: { [key: string]: string | number | boolean | number[] | undefined }[] =
    filteredItems.map((e) => {
      const item: (string | number | boolean | number[] | undefined)[][] = [];
      selectedHeaders.forEach((header) => {
        const x: (string | number | boolean | number[] | undefined)[] = [];
        x.push(header.value);
        x.push(e[header.value]);
        item.push(x);
      });
      return Object.fromEntries(item);
    });
  const headers = selectedHeaders.map((e) => e.text);
  const rows: Array<string[]> = [headers];
  modefiedItems.forEach((record, index) => {
    const row = Object.values(record);
    const modefiedRows = row.map((e) => {
      if (!e) {
        return '';
      }
      if (indentations[index]) {
        const value = `${e?.toString().replaceAll(';', ',').replaceAll('\n', ' ')}`;
        return value.padStart(value.length + indentations[index] * 4, ' ');
      }
      return `${e?.toString().replaceAll(';', ',').replaceAll('\n', ' ')}`;
    });
    rows.push(modefiedRows as string[]);
  });
  if (format === ExportType.XSLX) {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(rows);
    workbook.SheetNames.push(sheetName.toUpperCase());
    workbook.Sheets[sheetName.toUpperCase()] = worksheet;
    XLSX.writeFileXLSX(workbook, `${fileName}.xlsx`);
  } else {
    const rowsDelimited = rows.map((e) => e.join(delimiter)).join('\n');
    const blob = new Blob([rowsDelimited], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', `${fileName}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
