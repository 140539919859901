


































import { Component, Vue, Prop, Emit, PropSync } from 'vue-property-decorator';
import { StatisticDataBOM, StatisticParameter } from '@/interfaces';
import StackedBarChart from '@/components/Statistics/Containers/Charts/StackedBarCharts.vue';
import StatusTableBOM from '@/components/Statistics/Containers/Tables/StatusTableBOM.vue';

@Component({
  components: {
    StackedBarChart,
    StatusTableBOM,
  },
})
export default class StatisticBOMContainer extends Vue {
  @Prop({ required: true }) data!: StatisticDataBOM;

  @Prop({ default: false }) showTotal!: boolean;

  @PropSync('loading') isLoading!: boolean;

  @Emit('clickTableValue')
  public clickTableValue(value: StatisticParameter): StatisticParameter {
    return value;
  }
}
