


























































import { Component, Vue, PropSync } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import { required, password } from '@/validation/rules';
import { setPassword } from '@/api/user';

@Component
export default class NewPassForm extends Vue {
  @PropSync('token', { required: true }) passwordToken!: string;

  @PropSync('uid', { required: true }) uid64!: string;

  public isLoading = false;

  public showPassword = false;

  public showPasswordConfirmation = false;

  public password = '';

  public passwordConfirmation = '';

  public match = true;

  public valid = false;

  public requiredRule = required;

  public passwordRule = password;

  public errorText: TranslateResult = '';

  async mounted(): Promise<void> {
    const res = await setPassword(
      this.passwordToken,
      this.uid64,
      this.password,
      this.passwordConfirmation,
      true
    );
    this.handleResponse(res, true);
    this.showPassword = true;
    this.showPasswordConfirmation = true;
  }

  public async newPassword(): Promise<void> {
    if (this.passwordConfirmation === this.password) {
      this.isLoading = true;
      const res = await setPassword(
        this.passwordToken,
        this.uid64,
        this.password,
        this.passwordConfirmation,
        false
      );
      this.isLoading = false;
      if (res === 409) {
        this.errorText = this.$t('login.newPass.username');
        this.match = false;
      } else {
        this.handleResponse(res, false);
      }
    } else {
      (this.$refs.form as Vue & { reset: () => boolean }).reset();
      this.errorText = this.$t('login.newPass.error');
      this.match = false;
    }
  }

  private handleResponse(res: number, atStart: boolean): void {
    let title = '';
    let message = '';
    let error = 'true';
    switch (res) {
      case 200:
        title = this.$t('login.newPassDone.title').toString();
        message = this.$t('login.newPassDone.message').toString();
        error = 'false';
        break;
      case 400:
        title = this.$t('login.newPass.serverError.title').toString();
        message = this.$t('login.newPass.serverError.message').toString();
        break;
      case 404:
        title = this.$t('login.newPass.userError.title').toString();
        message = this.$t('login.newPass.userError.message').toString();
        break;
      case 410:
        title = this.$t('login.newPass.tokenError.title').toString();
        message = this.$t('login.newPass.tokenError.message').toString();
        break;
      case 500:
        title = this.$t('login.newPass.serverError.title').toString();
        message = this.$t('login.newPass.serverError.message').toString();
        break;
      default:
        break;
    }
    if ((res !== 202 && atStart) || !atStart) {
      this.$router.push({
        name: 'login.info',
        params: {
          title,
          message,
          error,
        },
      });
    }
  }
}
