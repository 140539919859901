// eslint-disable-next-line no-shadow
enum ExportType {
  XSLX = 'XLSX',
  CSV = 'CSV',
  XML = 'XML',
  ALL = 'ALL',
}

type Export = 'XLSX' | 'CSV' | 'XML' | 'ALL';

export { Export, ExportType };
