import { TranslateResult } from 'vue-i18n';
import i18n from '@/plugins/i18n';

const required = (value: string | number): boolean | string => (value !== '' && value !== undefined && value !== 0 && value !== null) || 'Required';

const emailAddress = (value: string): boolean | TranslateResult => {
  const pattern = /^\S+@\S+\.\S+$/;
  if (!value || value === '') {
    return false;
  }

  return pattern.test(value) || i18n.t('rules.email');
};

const password = (value: string): boolean => {
  const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,16})/;
  if (!value) {
    return false;
  }

  return pattern.test(value);
};

const codeValidation = (value: string): boolean | TranslateResult => {
  const pattern = /^([0-9]{6})$/;
  if (!value) {
    return false;
  }

  return pattern.test(value) || i18n.t('rules.code');
};

export { required, emailAddress, password, codeValidation };
