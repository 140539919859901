


























import { Component, Vue, Inject } from 'vue-property-decorator';
import _, { Dictionary } from 'lodash';
import TableComponent from '@/components/Table/TableComponent.vue';
import SearchComponent from '@/components/Dashboard/SearchComponent.vue';
import { createQuery } from '@/helpers/URLFilters';
import { Filter, View, FetchedData, Sorting, IExport, IFilterEmit } from '@/interfaces';
import { mainFilters } from '@/configuration';
import { fetchDashboard } from '@/api/dashboard';
import { exportMain } from '@/api/export';
import { refreshToken, cancelAllRequests } from '@/api/axios-base';
import eventBus from '@/eventBus';
import Filters from '@/models/Filters';
import exportData from '@/helpers/exportData';
import User from '@/models/User';
import UIPermissions from '@/models/UIPermissions';

@Component({
  components: {
    SearchComponent,
    TableComponent,
  },
})
export default class Dashboard extends Vue {
  @Inject() user!: User;

  public subFilters: Filter[] = [];

  public mainFilters: Filter[] = [];

  private recordsPerPage = parseInt(localStorage.getItem('recordsPerPage') ?? '15', 10);

  public itemsRange: number[] = this.defaultRange;

  public data: FetchedData = {
    itemsLength: 0,
    items: [],
    views: [],
  };

  private headersOnly = true;

  private countingLength = true;

  public isLoadingNewRecords = true;

  private query: Dictionary<string | (string | null)[]> = {};

  private sorting: Sorting = {
    name: '',
    order: 0,
  };

  public defaultView: View = {
    name: 'Default',
    fields: [
      'Part_Number',
      'Customer_Parts',
      'Type',
      'Vendor_Part_Status',
      'Description_Revision',
      'Vendor',
      'Vendor_Part_Number',
      'Budgetary_Price',
      'Lead_Time',
      'RoHS_Status',
      'Datasheet_URL',
      'Additional_Datasheet_URL',
      'Mass',
      'Packing_Type',
      'Packing_Size',
      'MSL',
      'ESD_Level',
      'MTBF',
      'MTBF_Standard',
      'Country_of_Origin',
      'Last_Time_Buy',
      'Value_SI_Class',
      'Alternatives',
      'MD_Service_Status',
      'Schematic_Part',
      'PCB_Footprint',
      'PCB_Alternate_Footprint',
      '3D_Model_DWF',
    ],
    default: false,
    selected: true,
  };

  public notSortable: string[] = [
    Filters.CUSTOMER_PART_OWNERS,
    Filters.CUSTOMER_PARTS,
    Filters.CUSTOMER_PART_STATUSES,
    Filters.CUSTOMER_PROJECTS,
    Filters.ALTIUM_PCB_FOOTPRINT_LEAST,
    Filters.ALTIUM_PCB_FOOTPRINT_MOST,
    Filters.ALTIUM_PCB_FOOTPRINT_NOMINAL,
    Filters.ALTIUM_SCHEMATIC_PART,
    Filters.ALTIUM_SECOND_CUSTOM_FOOTPRINTS,
    Filters.THREE_D_MODEL_DWF,
    Filters.THREE_D_MODEL_IPT,
    Filters.THREE_D_MODEL_STP,
    Filters.PCB_ALTERNATE_FOOTPRINT,
    Filters.PCB_FOOTPRINT,
    Filters.SCHEMATIC_PART,
  ];

  public disableFilterFields: string[] = [
    Filters.CUSTOMER_PART_OWNERS,
    Filters.CUSTOMER_PARTS,
    Filters.CUSTOMER_PART_STATUSES,
    Filters.CUSTOMER_PROJECTS,
    Filters.VENDOR_PART_NUMBER,
    Filters.PART_CLASS,
  ];

  public isExportLoading = false;

  public isExportDisabled = true;

  get defaultRange(): number[] {
    return [0, this.recordsPerPage];
  }

  get canExportAllData(): boolean {
    return this.user.permissions.includes(UIPermissions.ALL_DATA_EXPORT);
  }

  async mounted(): Promise<void> {
    this.getURLParams();
    this.headersOnly = !(!!this.subFilters.length || !!this.mainFilters.length);
    await this.fetchData();
    this.headersOnly = false;
    this.countingLength = true;
    this.isLoadingNewRecords = false;
    eventBus.$on('getQueryByAttributes', async (query: Dictionary<string | (string | null)[]>) => {
      if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
        await this.$router.replace({ query });
        this.isLoadingNewRecords = true;
        this.subFilters = [];
        this.mainFilters = [];
        this.getURLParams();
        this.itemsRange = this.defaultRange;
        this.countingLength = true;
        await this.fetchData();
        this.isLoadingNewRecords = false;
      }
    });
    eventBus.$on('resetForm', async () => {
      if (this.subFilters.length || this.mainFilters.length) {
        this.isLoadingNewRecords = true;
        this.subFilters = [];
        this.mainFilters = [];
        this.updateURL();
        this.itemsRange = this.defaultRange;
        this.countingLength = true;
        this.headersOnly = true;
        await this.fetchData();
        this.headersOnly = false;
        this.isLoadingNewRecords = false;
      }
    });
    eventBus.$on('updateSubFilters', async (headers: string[]) => {
      this.subFilters = this.subFilters.filter((e) => headers.includes(e.value));
    });
  }

  public async fetchData(): Promise<void> {
    this.isExportDisabled = true;
    const fetchedData = await fetchDashboard({
      lowerThreshold: this.itemsRange[0],
      upperThreshold: this.itemsRange[1],
      filters: this.query,
      sortingName: this.sorting.name,
      sortingOrder: this.sorting.order,
      headersOnly: this.headersOnly,
      countingLength: this.countingLength,
    });
    if (this.countingLength) {
      this.data = fetchedData;
    } else {
      this.data.items = fetchedData.items;
      this.data.views = fetchedData.views;
    }
    this.countingLength = false;
    this.isExportDisabled = false;
  }

  public async exportTable(exportDataParams: IExport): Promise<void> {
    this.isExportLoading = true;
    if (exportDataParams.format === 'ALL') {
      const headers = exportDataParams.headers.map((e) => e.value);
      await exportMain({
        lowerThreshold: 0,
        upperThreshold: 1000000,
        filters: this.query,
        sortingName: this.sorting.name,
        sortingOrder: this.sorting.order,
        headers,
      });
    } else {
      const fileName = `MainTable_${new Date().getFullYear()}_${new Date().getMonth()}_${new Date().getDate()}`;
      const { items } = this.data;
      exportData(exportDataParams.format, items, fileName, 'DASHBOARD', exportDataParams.headers);
    }
    this.isExportLoading = false;
  }

  public filterByAttributes(): void {
    this.updateURL();
  }

  public async filterByMainFilters(data: IFilterEmit): Promise<void> {
    this.mainFilters = data.filters;
    if (data.doFiltering) {
      this.isLoadingNewRecords = true;
      this.updateURL();
      this.itemsRange = this.defaultRange;
      this.countingLength = true;
      await this.fetchData();
      this.isLoadingNewRecords = false;
    }
  }

  public async filterBySubFilters(data: IFilterEmit): Promise<void> {
    this.subFilters = data.filters;
    if (data.doFiltering) {
      this.isLoadingNewRecords = true;
      this.updateURL();
      this.itemsRange = this.defaultRange;
      this.countingLength = true;
      await this.fetchData();
      this.isLoadingNewRecords = false;
    }
  }

  public async changeSorting(sorting: Sorting): Promise<void> {
    this.isLoadingNewRecords = true;
    this.sorting = sorting;
    cancelAllRequests();
    refreshToken();
    await this.fetchData();
    this.isLoadingNewRecords = false;
  }

  public async changeItemsRange(range: number[]): Promise<void> {
    this.isLoadingNewRecords = true;
    this.countingLength = this.itemsRange[1] - this.itemsRange[0] !== range[1] - range[0];
    this.recordsPerPage = range[1] - range[0];
    localStorage.setItem('recordsPerPage', this.recordsPerPage.toString());
    this.itemsRange = range;
    await this.fetchData();
    this.isLoadingNewRecords = false;
  }

  public updateURL(): void {
    this.query = this.$route.query;
    const mainFiltersQuery: Dictionary<string | (string | null)[]> = createQuery(this.mainFilters);
    const subFiltersQuery: Dictionary<string | (string | null)[]> = createQuery(this.subFilters);
    const newQuery: Dictionary<string | (string | null)[]> = Object.assign(mainFiltersQuery, {
      ...subFiltersQuery,
    });
    if (!_.isEqual(JSON.parse(JSON.stringify(this.query)), newQuery)) {
      this.$router.replace({ query: newQuery });
    }
    this.query = newQuery;
    this.getURLParams(newQuery);
  }

  public getURLParams(query: _.Dictionary<string | (string | null)[]> | null = null): void {
    const filters = mainFilters.map((e) => e.filter.value);
    this.query = query || this.$route.query;
    Object.entries(this.query).forEach(([key, value]) => {
      if (filters.includes(key)) {
        this.mainFilters.push({
          text: key.replaceAll('_', ' '),
          search: value.toString(),
          value: key,
        });
      } else {
        this.subFilters.push({
          text: key.replaceAll('_', ' '),
          search: value.toString(),
          value: key,
        });
      }
    });
  }
}
