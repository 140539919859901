












import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import InfoContainer from '@/components/ProductDetail/InfoContainer.vue';
import { DetailItem, FetchedItemDetail } from '@/interfaces';
import { fetchGeneralInfo } from '@/api/drilldown';
import eventBus from '@/eventBus';

@Component({
  components: {
    InfoContainer,
  },
})
export default class GeneralInfo extends Vue {
  @Prop() id!: string;

  @Watch('id')
  private async updateData(newVal: string): Promise<void> {
    this.data = [];
    this.sessionStorage.clear();
    eventBus.$emit('changeAttributesLength', this.sessionStorage.length);
    this.data = await fetchGeneralInfo(newVal);
  }

  public isLoading = true;

  private sessionStorage = window.sessionStorage;

  private data: FetchedItemDetail[] = [];

  get mappedData(): DetailItem[] {
    const data = Array.from(this.data);
    const mappedData: DetailItem[] = data.map((e) => {
      const element = { ...e, selected: null };
      return element;
    });
    return mappedData;
  }

  async mounted(): Promise<void> {
    this.data = await fetchGeneralInfo(this.id);
    this.isLoading = false;
  }
}
