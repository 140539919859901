import getAxiosBase, { cancelSource } from '@/api/axios-base';
import { View, AddView, UpdateDefaultView, UpdateFieldsView, Customer, AddCustomerView } from '@/interfaces';

export async function fetchTableViews(type: string): Promise<View[]> {
  const tableViews: View[] = await getAxiosBase()
    .get('/notefied/table/', {
      params: {
        type,
      },
      signal: cancelSource,
    })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
      return [];
    });
  return tableViews;
}

export async function addTableViews(data: AddView): Promise<number> {
  const status: number = await getAxiosBase()
    .post('/notefied/table/', { ...data }, { signal: cancelSource })
    .then((response) => response.status);
  return status;
}

export async function deleteTableViews(name: string, type: string): Promise<string> {
  const nameOfDefault: string = await getAxiosBase()
    .delete('/notefied/table/', { data: { name, type }, signal: cancelSource })
    .then((response) => {
      if (response.status === 200) {
        return response.data.name;
      }
      return '';
    });
  return nameOfDefault;
}

export async function updateDefaultTableViews(data: UpdateDefaultView): Promise<number> {
  const status: number = await getAxiosBase()
    .put('/notefied/table/default/', { ...data }, { signal: cancelSource })
    .then((response) => response.status);
  return status;
}

export async function updateFieldsTableViews(data: UpdateFieldsView): Promise<number> {
  const status: number = await getAxiosBase()
    .put('/notefied/table/', { ...data }, { signal: cancelSource })
    .then((response) => response.status);
  return status;
}

export async function getAvailableCustomers(): Promise<Customer[]> {
  const availableCustomers = await getAxiosBase().get('/notefied/user/customers/').then((response) => {
    if (response.status === 200) {
      return response.data[0];
    }
    return [];
  });
  return availableCustomers;
}

export async function addCustomerView(data: AddCustomerView): Promise<number> {
  const status: number = await getAxiosBase()
    .post('/notefied/table/customer', { ...data }, { signal: cancelSource })
    .then((response) => response.status);
  return status;
}
