














































































import { Component, Vue, Prop, PropSync, Emit } from 'vue-property-decorator';
import BarChart from '@/components/Statistics/Containers/Charts/BarChart.vue';
import PieChart from '@/components/Statistics/Containers/Charts/PieChart.vue';
import PartsStatusTable from '@/components/Statistics/Containers/Tables/PartsStatusTable.vue';
import { StatisticDataMain } from '@/interfaces';

@Component({
  components: {
    BarChart,
    PieChart,
    PartsStatusTable,
  },
})
export default class StatisticMixContainer extends Vue {
  @Prop({ required: true }) data!: StatisticDataMain;

  @Prop({ default: false }) showPieChart!: boolean;

  @PropSync('loading') isLoading!: boolean;

  @Emit('clickTableValue')
  public clickTableValue(value: string): string {
    return value;
  }
}
