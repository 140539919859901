import { render, staticRenderFns } from "./InfoContainer.vue?vue&type=template&id=c324aa36&scoped=true&"
import script from "./InfoContainer.vue?vue&type=script&lang=ts&"
export * from "./InfoContainer.vue?vue&type=script&lang=ts&"
import style0 from "./InfoContainer.vue?vue&type=style&index=0&id=c324aa36&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c324aa36",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VCard,VCardText,VCardTitle,VCheckbox,VIcon})
