



import { Component, Vue, PropSync } from 'vue-property-decorator';
import VueApexCharts from 'vue-apexcharts';
import { ApexOptions } from 'apexcharts';
import { Series, StatisticDataBOM } from '@/interfaces';

@Component({
  components: {
    VueApexCharts,
  },
})
export default class StackedBarChart extends Vue {
  @PropSync('chartData', { required: true }) data!: StatisticDataBOM;

  public categories: number[] = [
    new Date().getFullYear(),
    new Date().getFullYear() + 1,
    new Date().getFullYear() + 2,
    new Date().getFullYear() + 3,
    new Date().getFullYear() + 4,
  ];

  public series: Series[] = [];

  public colors: string[] = [];

  public mounted(): void {
    const copiedData = Array.from(this.data.status);
    copiedData.reverse().forEach((e) => {
      this.series.push({
        name: e.name,
        data: [e.year, e.year_1, e.year_2, e.year_3, e.year_4],
      });
      this.colors.push(e.color);
    });
  }

  get max(): number {
    return this.data.status.reduce(
      (previousValue, currentValue) => previousValue + currentValue.year,
      0
    );
  }

  get labelColor(): string {
    return this.$vuetify.theme.dark ? 'white' : 'black';
  }

  get themeValue(): string {
    return this.$vuetify.theme.dark ? 'dark' : 'light';
  }

  get chartOptions(): ApexOptions {
    return {
      colors: this.colors,
      chart: {
        stacked: true,
        stackType: 'normal',
        animations: {
          enabled: false,
        },
        toolbar: {
          tools: {
            download: false,
          },
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      xaxis: {
        tooltip: {
          enabled: false,
        },
        labels: {
          show: true,
          style: {
            colors: this.labelColor,
          },
        },
        categories: this.categories,
      },
      yaxis: {
        max: this.max,
        labels: {
          style: {
            colors: this.labelColor,
          },
        },
      },
      legend: {
        show: true,
        position: 'top',
        labels: {
          useSeriesColors: true,
        },
      },
      tooltip: {
        theme: this.themeValue,
        x: {
          show: true,
        },
      },
      stroke: {
        width: 1,
        colors: ['#fff'],
      },
      dataLabels: {
        enabled: false,
      },
    };
  }
}
