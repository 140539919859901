

















import { Component, Vue, Emit, Prop } from 'vue-property-decorator';

@Component
export default class DialogConfirm extends Vue {
  @Prop({ required: true }) danger!: boolean;

  public visible = false;

  private confirmDelete = true;

  public loading = false;

  mounted(): void {
    setTimeout(() => ((this.$refs.btnFocus as Vue).$el as HTMLElement).focus());
  }

  get type(): string {
    if (this.danger) {
      return '#FF5252';
    }
    return 'primary';
  }

  @Emit('cancel')
  public cancel(): boolean {
    return this.visible;
  }

  @Emit('confirm')
  public confirm(): boolean {
    this.loading = true;
    return this.confirmDelete;
  }
}
