





































































































































import { Component, Vue, PropSync, Emit, Watch, Prop, Inject } from 'vue-property-decorator';
import { Dictionary } from 'lodash';
import draggable from 'vuedraggable';
import { Header, View, FetchedItem, Sorting, AdditionalDataInfo, IExport } from '@/interfaces';
import { Export } from '@/models/Export';
import Fotter from '@/components/Table/Fotter.vue';
import HeadersDialog from '@/components/Table/HeadersDialog.vue';
import SaveViewDialog from '@/components/Table/SaveViewDialog.vue';
import ExportTableDialog from '@/components/Table/ExportTableDialog.vue';
import CustomRow from '@/components/Table/CustomRow.vue';
import CustomTableGroup from '@/components/Table/CustomTableGroup.vue';
import eventBus from '@/eventBus';
import AdminViewTable from '@/components/Table/AdminViewTool.vue';
import User from '@/models/User';
import UIPermissions from '@/models/UIPermissions';

@Component({
  components: {
    draggable,
    HeadersDialog,
    SaveViewDialog,
    ExportTableDialog,
    Fotter,
    CustomRow,
    CustomTableGroup,
    AdminViewTable,
  },
})
export default class Table extends Vue {
  @Inject() user!: User;

  @PropSync('data', { required: true }) items!: FetchedItem[];

  @PropSync('headers', { required: true }) tableHeaders!: Header[];

  @PropSync('dataLength') itemsLength!: number;

  @PropSync('loading', { required: true }) loadingData!: boolean;

  @PropSync('filters', { required: true }) filtersData!: boolean;

  @PropSync('exportLoading', { default: false }) isExportLoading!: boolean;

  @PropSync('disabledExport', { required: true }) isExportDisabled!: number[];

  @Prop({ type: Object }) defaultView!: View;

  @Prop({ type: Array, default: () => [] }) views!: View[];

  @Prop({ type: Boolean, default: false }) grouped!: boolean;

  @Prop({ type: Boolean, default: false }) expandable!: boolean;

  @Prop({ default: false }) enableXML!: boolean;

  @Prop({ default: false }) enableAll!: boolean;

  @Prop({ default: false }) expandAll!: boolean;

  @Prop({ required: false }) header!: string;

  @Watch('views')
  onViewsChange(): void {
    this.updatedSelectedHeaders();
  }

  @Watch('headers')
  onHeadersChange(newVal: Header[], oldVal: Header[]): void {
    if (oldVal.length === 0) {
      this.updatedSelectedHeaders();
      Object.keys(this.establishQuery).forEach((e) => {
        if (!this.fields.includes(e)) this.fields.push(e);
      });
    }
  }

  public isExpanded = false;

  public permissions = UIPermissions;

  public fullscreen = false;

  private sessionStorage = window.sessionStorage;

  private toBeDeleted = '';

  private filteredHeaders: Header[] = [];

  public query: Dictionary<string | (string | null)[]> = {};

  public fields: string[] = [];

  public range: number[] = [];

  public sorting: Sorting = {
    name: '',
    order: 0,
  };

  get selectedHeaders(): Header[] {
    return this.filteredHeaders;
  }

  set selectedHeaders(headers: Header[]) {
    this.updateHeaderOrder(headers.map((e) => e.value));
    this.filteredHeaders = headers;
  }

  get establishQuery(): Dictionary<string | (string | null)[]> {
    const keys = Object.keys(this.sessionStorage);
    const keysQuery = Object.keys(this.$route.query);
    const query: Dictionary<string | (string | null)[]> = {};
    keys.forEach((e) => {
      const value = this.sessionStorage.getItem(e);
      query[e] = value || '';
    });
    keysQuery.forEach((e) => {
      const value = this.$route.query[e];
      query[e] = value || '';
    });
    this.query = query;
    return this.query;
  }

  set establishQuery(value: Dictionary<string | (string | null)[]>) {
    this.query = value;
  }

  mounted(): void {
    eventBus.$on('removeAttribute', (value: string) => {
      delete this.establishQuery[value];
      this.toBeDeleted = value;
    });
    eventBus.$on('removeAllAttributes', (values: string[]) => {
      values.forEach((value) => {
        delete this.establishQuery[value];
      });
    });
    eventBus.$on('updateHeaders', (value: string[]) => {
      this.updateHeader(value);
    });
    eventBus.$on('resetForm', async () => {
      this.views.forEach((e) => {
        if (e.default) {
          e.selected = true;
        }
      });
      this.updatedSelectedHeaders();
    });
  }

  public updatedSelectedHeaders(): void {
    const organizeHeaders: Header[] = [];
    const selectedView = this.views.find((e) => e.selected);
    let fields: string[] = [];
    if (selectedView) {
      fields = selectedView?.fields.filter((e) => e !== this.toBeDeleted);
    }
    if (!selectedView && this.fields.length === 0) {
      this.fields = this.defaultView.fields;
    } else if (selectedView) {
      this.fields = fields;
    }
    this.fields.forEach((e) => {
      const selectedHeader = this.tableHeaders.find((element) => element.value === e);
      if (selectedHeader) {
        organizeHeaders.push(selectedHeader);
      }
    });
    this.fields = this.fields.filter((e) => e !== this.toBeDeleted);
    this.filteredHeaders = organizeHeaders;
  }

  public updateHeader(headers: string[]): void {
    const remainParameters = this.selectedHeaders.filter((e) => headers.includes(e.value));
    const remainParametersValues = remainParameters.map((e) => e.value);
    const newParameters = this.tableHeaders.filter(
      (e) => headers.includes(e.value) && !remainParametersValues.includes(e.value)
    );
    this.selectedHeaders = remainParameters.concat(newParameters);
    this.fields = headers;
    this.changeFilters();
  }

  public updateHeaderOrder(headers: string[]): void {
    this.fields = headers;
    this.changeFilters();
  }

  public sort(name: string, sortable: boolean): void {
    if (sortable) {
      if (this.sorting.name !== name) {
        this.sorting = {
          name,
          order: 1,
        };
      } else if (this.sorting.name === name && this.sorting.order === 1) {
        this.sorting = {
          name,
          order: -1,
        };
      } else {
        this.sorting = {
          name: '',
          order: 0,
        };
      }
      this.changeSorting();
    }
  }

  @Emit('toogleFullscreen')
  public toogleFullscreen(): boolean {
    this.fullscreen = !this.fullscreen;
    return this.fullscreen;
  }

  @Emit('updateViews')
  public updateViews(views: View[]): View[] {
    const selectedView = views.find((e) => e.selected);
    if (selectedView) {
      this.updateHeader(selectedView.fields);
    }
    return views;
  }

  @Emit('changeFilters')
  public changeFilters(): string[] {
    return this.fields;
  }

  @Emit('changeItemsRange')
  public itemsRange(payload: number[]): number[] {
    this.range = payload;
    return this.range;
  }

  @Emit('changeSorting')
  public changeSorting(): Sorting {
    this.items = [];
    const sorting: Sorting = {
      name: this.sorting.name.replaceAll(' ', '_'),
      order: this.sorting.order,
    };
    return sorting;
  }

  @Emit('exportTable')
  public exportTable(format: Export): IExport {
    return {
      format,
      headers: this.selectedHeaders,
    };
  }

  @Emit('expandItemInformation')
  public expandItemInformation(additionalDataInfo: AdditionalDataInfo): AdditionalDataInfo {
    return additionalDataInfo;
  }

  @Emit('fetchMoreData')
  public fetchMoreData(id: number): number {
    return id;
  }
}
