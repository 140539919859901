




































import { Component, Vue, PropSync, Prop } from 'vue-property-decorator';
import {
  StatisticDataMain,
  BarAndPieChartDataExtended,
  StatisticHeader,
  StatisticUrlQuerySettings,
} from '@/interfaces';
import ValueOrigin from '@/models/ValueOrigin';

@Component
export default class PartsTable extends Vue {
  @PropSync('tableData', { required: true }) data!: StatisticDataMain;

  @Prop({ required: true }) settings!: StatisticUrlQuerySettings[];

  @Prop({ default: '' }) incomeValue!: string;

  public items: BarAndPieChartDataExtended[] = [];

  public headers: StatisticHeader[] = [
    { value: 'name' },
    { value: 'quantity' },
    { value: 'percent' },
  ];

  public getRouterToValue(type: string): string {
    const url = ['/main?'];
    this.settings.forEach((e) => {
      if (url.length >= 2) url.push('&');
      if (e.value === ValueOrigin.CONSTANT) {
        url.push(`${e.name}=${e.constant}`);
      } else if (e.value === ValueOrigin.LOCAL) {
        url.push(`${e.name}=${type}`);
      } else if (e.value === ValueOrigin.GLOBAL) {
        url.push(`${e.name}=${this.incomeValue}`);
      }
    });
    return url.join('');
  }

  public goToUrl(value: string): void {
    window.open(value, '_blank');
  }

  public mounted(): void {
    this.data.status.forEach((element) => {
      this.items.push({
        name: element.name,
        quantity: element.quantity,
        percent: `${((element.quantity / this.data.totalParts) * 100).toFixed(2).toString()}%`,
        color: element.color,
      });
    });
    this.items.push({
      name: this.$t('statistic.total').toString(),
      quantity: this.data.totalParts,
      percent: '100%',
      color: '#333333',
    });
  }
}
