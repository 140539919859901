


















import { Component, Mixins } from 'vue-property-decorator';
import TableComponent from '@/components/Table/TableComponent.vue';
import TableMixin from '@/mixins/tableMixins';
import Filters from '@/models/Filters';
import { fetchCustomerParts } from '@/api/drilldown';
import { View } from '@/interfaces';

@Component({
  components: {
    TableComponent,
  },
})
export default class CustomerParts extends Mixins(TableMixin) {
  public defaultView: View = {
    name: 'Default',
    fields: ['Customer', 'Customer_Part_Number', 'Customer_Part_Status', 'Customer_Projects'],
    default: false,
    selected: true,
  };

  public notSortable: string[] = [Filters.CUSTOMER];

  async mounted(): Promise<void> {
    this.getURLParams();
    await this.fetchData();
    this.isLoadingNewRecords = false;
    this.emitListener();
  }

  public async fetchData(): Promise<void> {
    this.isExportDisabled = true;
    this.data = await fetchCustomerParts({
      id: this.id,
      filters: this.query,
      lowerThreshold: this.itemsRange[0],
      upperThreshold: this.itemsRange[1],
      sortingName: this.sorting.name,
      sortingOrder: this.sorting.order,
    });
    this.isExportDisabled = false;
  }
}
