






















































import { Component, Vue, Prop, Inject, Watch } from 'vue-property-decorator';
import {
  AmbientLight,
  DirectionalLight,
  GridHelper,
  PerspectiveCamera,
  Scene,
  WebGLRenderer,
} from 'three';
import * as THREE from 'three';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { fetchThreeDModelFiles, fetchThreeDModelImages } from '@/api/drilldown';
import fetchFile from '@/api/file';
import { FetchedThreeDImagesData, ThreeDModelsOptions, FetchedThreeDFilesData } from '@/interfaces';
import eventBus from '@/eventBus';
import User from '@/models/User';

@Component
export default class ThreeDModel extends Vue {
  @Inject() user!: User;

  @Prop() id!: string;

  @Watch('imageStl')
  onImageChange(): void {
    if (this.imageStl?.img) {
      this.createScene();
    } else {
      this.isAvailable = false;
    }
  }

  public images: FetchedThreeDFilesData[] = [];

  public imagesStl: FetchedThreeDImagesData[] = [];

  public selectedDownload = '';

  public selectedName = '';

  public isLoading = true;

  public isLoadingImage = true;

  public isAvailable = false;

  public isDownloading = false;

  public canDownload = false;

  get names(): string[] {
    return this.images.map((e) => e.name);
  }

  get imageStl(): FetchedThreeDImagesData | undefined {
    return this.imagesStl.find((e) => e.name === this.selectedName);
  }

  get image(): FetchedThreeDFilesData | undefined {
    return this.images.find((e) => e.name === this.selectedName);
  }

  get options(): ThreeDModelsOptions[] {
    return [
      {
        text: '3D Model DWF',
        disabled: !this.user.downloadPermissions.includes('3D Model DWF') || !this.image?.names.dwf,
      },
      {
        text: '3D Model IPT',
        disabled: !this.user.downloadPermissions.includes('3D Model IPT') || !this.image?.names.ipt,
      },
      {
        text: '3D Model STP',
        disabled: !this.user.downloadPermissions.includes('3D Model STP') || !this.image?.names.stp,
      },
    ];
  }

  get selectedFile(): string {
    if (this.selectedDownload === '3D Model DWF' && this.image && this.image.names.dwf) {
      return this.image.names.dwf;
    }
    if (this.selectedDownload === '3D Model IPT' && this.image && this.image.names.ipt) {
      return this.image.names.ipt;
    }
    if (this.selectedDownload === '3D Model STP' && this.image && this.image.names.stp) {
      return this.image.names.stp;
    }
    return '';
  }

  async mounted(): Promise<void> {
    fetchThreeDModelFiles(this.id).then((data) => {
      this.images = data.files;
      this.canDownload = data.download;
      this.isLoading = false;
      if (this.images.length) {
        this.selectedName = this.images[0].name;
        if (this.imagesStl.length) {
          this.$nextTick(() => {
            this.createScene();
          });
        }
      }
    });
    fetchThreeDModelImages(this.id).then((images) => {
      this.imagesStl = images.images;
      this.isLoadingImage = false;
      if (this.selectedName) {
        this.$nextTick(() => {
          this.createScene();
        });
      }
    });
  }

  public async downloadFiles(): Promise<void> {
    this.isDownloading = true;
    const response = await fetchFile({
      fileName: this.selectedFile,
      fileType: this.selectedDownload,
      endpoint: 'three-d-model',
      id: this.id,
    });
    this.isDownloading = false;
    if (!response) {
      eventBus.$emit('generalError', {
        type: 'error',
        message: this.$t('error.file').toString(),
      });
    }
  }

  public createScene(): void {
    const scene = new Scene();
    const size = {
      width: 600,
      height: 400,
    };

    const aspect = 600 / 400;
    const camera = new PerspectiveCamera(30, aspect);
    camera.position.z = 0;
    camera.position.y = 20;
    camera.position.x = 20;

    const lightColor = 0xffffff;

    const ambientLight = new AmbientLight(lightColor, 0.5);
    scene.add(ambientLight);

    const directionalLight = new DirectionalLight(lightColor, 1);
    directionalLight.position.set(0, 10, 0);
    directionalLight.target.position.set(-5, 0, 0);
    scene.add(directionalLight);
    scene.add(directionalLight.target);
    scene.background = new THREE.Color(0xd6d6d6);

    const grid = new GridHelper(20, 20);
    scene.add(grid);

    const threeCanvas = document.getElementById('three-canvas');

    if (threeCanvas) {
      const renderer = new WebGLRenderer({
        canvas: threeCanvas,
        alpha: true,
      });
      renderer.setSize(size.width, size.height);
      renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

      const controls = new OrbitControls(camera, threeCanvas);
      controls.enableDamping = true;
      controls.target.set(-2, 0, 0);

      const animate = () => {
        controls.update();
        renderer.render(scene, camera);
        requestAnimationFrame(animate);
      };

      animate();

      window.addEventListener('resize', () => {
        camera.aspect = size.width / size.height;
        camera.updateProjectionMatrix();
        renderer.setSize(size.width, size.height);
      });

      const material = new THREE.MeshPhysicalMaterial({
        color: 0xb3250a8,
      });

      const loader = new STLLoader();
      if (this.imageStl?.img) {
        const geometry = loader.parse(this.imageStl.img);
        const mesh = new THREE.Mesh(geometry, material);
        mesh.rotation.x = 2 * Math.PI * (-90 / 360); // radian
        scene.add(mesh);
        this.isAvailable = true;
      } else {
        this.isAvailable = false;
        scene.clear();
      }
    }
  }
}
