









import { Component, Vue, Prop, Inject } from 'vue-property-decorator';
import User from '@/models/User';
import { PermissionsRouter } from '@/interfaces';
import UIPermissions from '@/models/UIPermissions';
import hasPermission from '@/helpers/hasPermission';

@Component
export default class StatisticsBOMView extends Vue {
  @Inject() user!: User;

  @Prop() partNumber!: string;

  public navigatorPerPermissions: PermissionsRouter[] = [
    {
      name: 'item.statistics.obsolescence',
      permissions: [UIPermissions.BOM_STATISTIC_OBSOLESCENCE],
    },
    {
      name: 'item.statistics.customerPartObsolescence',
      permissions: [UIPermissions.BOM_STATISTIC_CUSTOMER_RISK_STATUS],
    },
  ];

  async created(): Promise<void> {
    this.navigate();
  }

  public navigate(): void {
    if (this.$route.name === 'item.statistics') {
      const route = this.navigatorPerPermissions.find(
        (e) => hasPermission(this.user.permissions, e.permissions)
      );
      if (route) {
        this.$router.push({ name: route.name });
      }
    }
  }
}
