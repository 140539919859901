


























import { Component, Vue, Inject } from 'vue-property-decorator';
import User from '@/models/User';
import exportReport from '@/api/report';
import { Report } from '@/interfaces';

@Component
export default class DeclarationReports extends Vue {
  @Inject() user!: User;

  public fileUrl = '';

  public label = 'Report';

  public isLoading = false;

  public reports: Report[] = [
    { name: this.$t('reports.items').toString(), url: 'items/' },
    { name: this.$t('reports.declaration').toString(), url: 'declaration/' },
    { name: this.$t('reports.specification').toString(), url: 'specifications/' },
    { name: this.$t('reports.substancesBanned').toString(), url: 'substances/banned/' },
    { name: this.$t('reports.substancesAll').toString(), url: 'substances/all/' },
  ];

  public async fetchFiles(): Promise<void> {
    this.isLoading = true;
    await exportReport(this.fileUrl);
    this.isLoading = false;
  }
}
