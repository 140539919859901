





























































import { Component, Vue, Emit } from 'vue-property-decorator';
import QrcodeVue from 'qrcode.vue';
import { TranslateResult } from 'vue-i18n';
import { fetchTOTPDevice, confirmTOTPDevice, resetTOTPDevice } from '@/api/user';
import { codeValidation } from '@/validation/rules';
import SimpleSnackbar from '@/components/Snackbar/SimpleSnackbar.vue';
import { MultiFactorAuthForm } from '@/interfaces';

@Component({
  components: {
    QrcodeVue,
    SimpleSnackbar,
  },
})
export default class MultifactorAuth extends Vue {
  public qrcodeValue = '';

  public loading = false;

  public valid = false;

  public secretKey = '';

  public codeRule = codeValidation;

  public code = '';

  public error = false;

  public showSnackbar = false;

  public exists = false;

  get buttonColor(): string {
    return this.exists ? 'error' : 'primary';
  }

  get buttonText(): TranslateResult {
    return this.exists ? this.$t('confirmation.disable') : this.$t('confirmation.submit');
  }

  get errorMessage(): TranslateResult {
    return this.exists ? this.$t('multiFactorAuth.cannotRemove') : this.$t('multiFactorAuth.wrong');
  }

  async mounted(): Promise<void> {
    this.loading = true;
    const totpDevice = await fetchTOTPDevice();
    if (totpDevice.url) {
      this.qrcodeValue = totpDevice.url;
    } else {
      this.exists = true;
    }
    this.extractSecretKey();
    this.loading = false;
  }

  public extractSecretKey(): void {
    const [, parameters] = this.qrcodeValue.split('?');
    if (parameters) {
      const [secret] = parameters.split('&');
      [, this.secretKey] = secret.split('=');
    }
  }

  public async submit(): Promise<void> {
    const status = this.exists ? await resetTOTPDevice() : await confirmTOTPDevice(this.code);
    if (status) {
      this.closeForm(status);
    } else {
      this.error = true;
      this.showSnackbar = !this.showSnackbar;
    }
  }

  public onChangeInput(): void {
    this.error = false;
  }

  @Emit('closeForm')
  public closeForm(success: boolean): MultiFactorAuthForm {
    return { success, exists: this.exists };
  }
}
