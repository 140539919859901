







































import { Component, Mixins } from 'vue-property-decorator';
import TableComponent from '@/components/Table/TableComponent.vue';
import TableMixin from '@/mixins/tableMixins';
import { fetchDeclaration } from '@/api/drilldown';
import { exportDeclarationXML } from '@/api/export';
import { ExportType } from '@/models/Export';
import { FetchedData, View, AdditionalDataInfo, FetchedItem, IExport } from '@/interfaces';

@Component({
  components: {
    TableComponent,
  },
})
export default class ComplianceDeclaration extends Mixins(TableMixin) {
  public defaultView: View = {
    name: 'Default',
    fields: [
      'Substance',
      'CAS_Number',
      'Mass',
      'Calculated_Mass',
      'Result_Mass',
      'PPM',
      'Calculated_PPM',
      'Result_PPM',
      'Threshold_PPM',
      'Calculated_Compliance',
      'Declared_Compliance',
      'Result_Compliance',
      'Exemptions',
    ],
    default: false,
    selected: true,
  };

  public notSortable: string[] = ['all'];

  public additionalDataInfo: AdditionalDataInfo[] = [];

  get processedData(): FetchedData {
    const processedDataItems: FetchedItem[] = [];
    this.data.items.forEach((e) => {
      if (!e.parentsIds) {
        processedDataItems.push(e);
      } else {
        const expandedItem = this.additionalDataInfo.find(
          (element) => e.parentsIds?.includes(element.id) && element.expanded
        );
        if (!expandedItem) {
          processedDataItems.push(e);
        } else if (!expandedItem.expanded) {
          processedDataItems.push(e);
        }
      }
    });
    return {
      itemsLength: this.data.itemsLength,
      items: processedDataItems,
      views: this.data.views,
    };
  }

  async mounted(): Promise<void> {
    this.getURLParams();
    await this.fetchData();
    this.isLoadingNewRecords = false;
    this.isExportDisabled = false;
    this.emitListener();
  }

  public async fetchData(): Promise<void> {
    this.isExportDisabled = true;
    const declarationData = await fetchDeclaration({
      id: this.idDeclaration,
      filters: this.query,
      lowerThreshold: this.itemsRange[0],
      upperThreshold: this.itemsRange[1],
      sortingName: this.sorting.name,
      sortingOrder: this.sorting.order,
    });
    this.data = {
      itemsLength: declarationData.itemsLength,
      items: declarationData.items,
      views: declarationData.views,
    };
    this.isExportDisabled = false;
  }

  public async exportDeclarationTable(exportDataParams: IExport): Promise<void> {
    this.isExportLoading = true;
    if (exportDataParams.format === ExportType.XML) {
      const fileName = `PART_${this.itemName}_${this.specification}_NUM_${this.declaration}.xml`;
      await exportDeclarationXML(this.idDeclaration, fileName);
    } else {
      await this.exportTable(exportDataParams);
    }
    this.isExportLoading = false;
  }
}
