






















import { Component, Mixins } from 'vue-property-decorator';
import TableComponent from '@/components/Table/TableComponent.vue';
import Filters from '@/models/Filters';
import TableMixin from '@/mixins/tableMixins';
import { fetchPartList } from '@/api/drilldown';
import { View } from '@/interfaces';
import eventBus from '@/eventBus';

@Component({
  components: {
    TableComponent,
  },
})
export default class PartList extends Mixins(TableMixin) {
  public defaultView: View = {
    name: 'Default',
    fields: [
      'Num',
      'Part_Number',
      'Customer_Part_Number',
      'Type',
      'UOM',
      'Quantity',
      'Designators',
      'Description',
      'Vendor',
      'Vendor Part Number',
      'BOM Note',
      'Alternative',
    ],
    default: false,
    selected: true,
  };

  // public notSortable: string[] = [
  //   Filters.CUSTOMER_PART_OWNERS,
  //   Filters.CUSTOMER_PARTS,
  //   Filters.CUSTOMER_PART_STATUSES,
  //   Filters.CUSTOMER_PROJECTS,
  // ];

  public notSortable: string[] = ['all'];

  public disableFilterFields: string[] = [
    Filters.CUSTOMER_PART_OWNERS,
    Filters.CUSTOMER_PARTS,
    Filters.CUSTOMER_PART_STATUSES,
    Filters.CUSTOMER_PROJECTS,
  ];

  async mounted(): Promise<void> {
    this.getURLParams();
    await this.fetchData();
    this.isLoadingNewRecords = false;
    this.resetEmitListener();
  }

  public resetEmitListener(): void {
    eventBus.$on('resetForm', async () => {
      this.subFilters = [];
      this.updateURL();
      this.itemsRange = this.defaultRange;
    });
  }

  public async fetchData(): Promise<void> {
    this.isExportDisabled = true;
    this.data = await fetchPartList(this.id);
    this.isExportDisabled = false;
  }
}
