

















import { Component, Mixins } from 'vue-property-decorator';
import TableComponent from '@/components/Table/TableComponent.vue';
import TableMixin from '@/mixins/tableMixins';
import { fetchDocuments } from '@/api/drilldown';
import { View } from '@/interfaces';

@Component({
  components: {
    TableComponent,
  },
})
export default class Documents extends Mixins(TableMixin) {
  public defaultView: View = {
    name: 'Default',
    fields: ['File_Name', 'Folder_Type', 'File_Folder'],
    default: false,
    selected: true,
  };

  async mounted(): Promise<void> {
    this.getURLParams();
    await this.fetchData();
    this.isLoadingNewRecords = false;
    this.emitListener();
  }

  public async fetchData(): Promise<void> {
    this.isExportDisabled = true;
    this.data = await fetchDocuments({
      id: this.id,
      filters: this.query,
      lowerThreshold: this.itemsRange[0],
      upperThreshold: this.itemsRange[1],
      sortingName: this.sorting.name,
      sortingOrder: this.sorting.order,
    });
    this.isExportDisabled = false;
  }
}
