






























import { Component, Mixins } from 'vue-property-decorator';
import Filters from '@/models/Filters';
import { StatisticDataBOM, StatisticParameter, View, FetchedData, FetchedItem, IExport } from '@/interfaces';
import StatisticBOMContainer from '@/components/Statistics/Containers/StatisticBOMContainer.vue';
import TableComponent from '@/components/Table/TableComponent.vue';
import TableMixin from '@/mixins/tableMixins';
import { fetchStatisticsObsolescenceChart, fetchBOMObsolescence } from '@/api/drilldown';
import eventBus from '@/eventBus';
import exportData from '@/helpers/exportData';

@Component({
  components: {
    TableComponent,
    StatisticBOMContainer,
  },
})
export default class Obsolescence extends Mixins(TableMixin) {
  public defaultView: View = {
    name: 'Default',
    fields: ['Num', 'Part_Number', 'Customer_Parts'],
    default: false,
    selected: true,
  };

  public chartData: StatisticDataBOM = {
    message: '',
    status: [],
  };

  // public notSortable: string[] = [
  //   Filters.CUSTOMER_PART_OWNERS,
  //   Filters.CUSTOMER_PARTS,
  //   Filters.CUSTOMER_PART_STATUSES,
  //   Filters.CUSTOMER_PROJECTS,
  // ];

  public notSortable: string[] = ['all'];

  public disableFilterFields: string[] = [
    Filters.CUSTOMER_PART_OWNERS,
    Filters.CUSTOMER_PARTS,
    Filters.CUSTOMER_PART_STATUSES,
    Filters.CUSTOMER_PROJECTS,
  ];

  public isLoadingChartData = true;

  public status: string | null = null;

  public year: number | null = null;

  get field(): string {
    if (!this.year) {
      return '';
    }
    const currentYear = new Date().getFullYear();
    const diff = this.year - currentYear;
    if (diff === 0) {
      return 'Current_Year_MPN_Risk_Status';
    }
    return `Current_Year+${diff}_MPN_Risk_Status`;
  }

  get processedData(): FetchedData {
    const lengthRoot = this.dataFilteredByYearAndStatus.filter((e) => !e.parentsIds?.length).length;
    const itemsInRange = this.dataFilteredByYearAndStatus.filter((e) => !e.parentsIds?.length)
      .slice(this.itemsRange[0], this.itemsRange[1]);
    const ids = itemsInRange.map((e) => e.id || 0);
    const bomIds = itemsInRange.map((e) => e.tree || 0);
    const itemsWithNestedComponents = this.dataFilteredByYearAndStatus
      .filter(
        (e) => bomIds.includes(e.tree || 0) ||
          e.parentsIds?.some((element: number) => ((ids.indexOf(element) + 1) || -1) > 0)
      );
    const expandedItemsIds = this.additionalDataInfo.map((e) => {
      if (e.expanded) {
        return e.id;
      }
      return -1;
    });
    const processedDataItems = (this.year) ? itemsWithNestedComponents.filter((e) =>
      e.parentsIds?.every((element) => expandedItemsIds.includes(element))) : [];
    if (this.data.firstItem) {
      processedDataItems.splice(0, 0, this.data.firstItem);
    }
    return {
      itemsLength: lengthRoot,
      items: processedDataItems,
      views: this.data.views,
    };
  }

  get processedDataForExport(): FetchedData {
    const lengthRoot = this.dataFilteredByYearAndStatus.filter((e) => !e.parentsIds?.length).length;
    const itemsInRange = this.dataFilteredByYearAndStatus.filter((e) => !e.parentsIds?.length);
    const ids = itemsInRange.map((e) => e.id || 0);
    const bomIds = itemsInRange.map((e) => e.tree || 0);
    const itemsWithNestedComponents = this.dataFilteredByYearAndStatus
      .filter(
        (e) => bomIds.includes(e.tree || 0) ||
          e.parentsIds?.some((element: number) => ((ids.indexOf(element) + 1) || -1) > 0)
      );
    const expandedItemsIds = this.additionalDataInfo.map((e) => {
      if (e.expanded) {
        return e.id;
      }
      return -1;
    });
    const processedDataItems = (this.year) ? itemsWithNestedComponents.filter((e) =>
      e.parentsIds?.every((element) => expandedItemsIds.includes(element))) : [];
    if (this.data.firstItem) {
      processedDataItems.splice(0, 0, this.data.firstItem);
    }
    return {
      itemsLength: lengthRoot,
      items: processedDataItems,
      views: this.data.views,
    };
  }

  get dataFilteredByYearAndStatus(): FetchedItem[] {
    if (!this.year || !this.status) {
      return this.filteredData;
    }
    const filteredDataByStatus = this.filteredData
      .filter((e) => e[this.field] === this.status);
    const parentsIdsOfAllComponentsWithStatus = filteredDataByStatus.filter(
      (e) => e[this.field] === this.status
    ).map((e) => e.parentsIds).flat();
    const parentsIdsOfAllComponentsWithStatusFiltered = parentsIdsOfAllComponentsWithStatus
      .filter((e) => filteredDataByStatus.some((element) => typeof e === 'number' && element.parentsIds?.includes(e) && !element.expandable));
    return this.filteredData.filter(
      (e) => (e[this.field] === this.status ||
      parentsIdsOfAllComponentsWithStatusFiltered.includes(
        typeof e.id === 'number' ? e.id : 0
      )) && (!e.expandable || parentsIdsOfAllComponentsWithStatusFiltered.includes(
        typeof e.id === 'number' ? e.id : 0
      ))
    );
  }

  get exportTitle(): string {
    if (!this.status && !this.year) {
      return 'mpo';
    }
    if (!this.status) {
      return `mpo_${this.year}`;
    }
    return `mpo_${this.year}_${this.status.replaceAll(' ', '_')}`;
  }

  get title(): string {
    if (!this.status && !this.year) {
      return '';
    }
    if (!this.status) {
      return `BOM Components from year ${this.year}`;
    }
    return `BOM Components with Risk Status ${this.status} from year ${this.year}`;
  }

  async mounted(): Promise<void> {
    this.getURLParams();
    this.isExportDisabled = true;
    const promiseChart = fetchStatisticsObsolescenceChart(this.id);
    const promiseInfo = fetchBOMObsolescence(this.id);
    await Promise.all([promiseChart, promiseInfo]).then((result) => {
      [this.chartData, this.data] = result;
    });
    this.isExportDisabled = false;
    this.isLoadingNewRecords = false;
    this.isLoadingChartData = false;
    this.resetEmitListener();
  }

  public resetEmitListener(): void {
    eventBus.$on('resetForm', async () => {
      this.subFilters = [];
      this.updateURL();
      this.itemsRange = this.defaultRange;
    });
  }

  public async fetchData(): Promise<void> {
    this.isExportDisabled = true;
    this.data = await fetchBOMObsolescence(this.id);
    this.isExportDisabled = false;
  }

  public async clickTableValue(payload: StatisticParameter): Promise<void> {
    this.year = payload.year;
    this.status = payload.status;
    this.itemsRange = this.defaultRange;
  }

  public async exportProcessedDataTable(exportDataParams: IExport): Promise<void> {
    this.isExportLoading = true;
    const fileName = `${this.partNumber}_${this.exportTitle}_${new Date().getFullYear()}_${new Date().getMonth()}_${new Date().getDate()}`;
    const { items } = this.processedDataForExport;
    exportData(
      exportDataParams.format,
      items,
      fileName,
      this.exportTitle,
      exportDataParams.headers
    );
    this.isExportLoading = false;
  }
}
