





























import { Component, Mixins } from 'vue-property-decorator';
import Filters from '@/models/Filters';
import { StatisticDataMain, View, FetchedData, FetchedItem, IExport } from '@/interfaces';
import StatisticMixContainer from '@/components/Statistics/Containers/StatisticMixContainer.vue';
import TableComponent from '@/components/Table/TableComponent.vue';
import TableMixin from '@/mixins/tableMixins';
import { fetchStatisticsRoHsChart, fetchBOMRohs } from '@/api/drilldown';
import eventBus from '@/eventBus';
import exportData from '@/helpers/exportData';

@Component({
  components: {
    TableComponent,
    StatisticMixContainer,
  },
})
export default class Rohs extends Mixins(TableMixin) {
  public defaultView: View = {
    name: 'Default',
    fields: ['Num', 'Part_Number', 'RoHS_Status', 'MD_Service_Status'],
    default: false,
    selected: true,
  };

  public chartData: StatisticDataMain = {
    message: '',
    totalParts: 0,
    status: [],
  };

  // public notSortable: string[] = [
  //   Filters.CUSTOMER_PART_OWNERS,
  //   Filters.CUSTOMER_PARTS,
  //   Filters.CUSTOMER_PART_STATUSES,
  //   Filters.CUSTOMER_PROJECTS,
  // ];

  public notSortable: string[] = ['all'];

  public disableFilterFields: string[] = [
    Filters.CUSTOMER_PART_OWNERS,
    Filters.CUSTOMER_PARTS,
    Filters.CUSTOMER_PART_STATUSES,
    Filters.CUSTOMER_PROJECTS,
  ];

  public isLoadingChartData = true;

  public status: string | null = 'Total';

  get processedData(): FetchedData {
    const lengthRoot = this.dataFilteredByStatus.filter((e) => !e.parentsIds?.length).length;
    const itemsInRange = this.dataFilteredByStatus
      .filter((e) => !e.parentsIds?.length)
      .slice(this.itemsRange[0], this.itemsRange[1]);
    const ids = itemsInRange.map((e) => e.id || 0);
    const bomIds = itemsInRange.map((e) => e.tree || 0);
    const itemsWithNestedComponents = this.dataFilteredByStatus.filter(
      (e) =>
        bomIds.includes(e.tree || 0) ||
        e.parentsIds?.some((element: number) => (ids.indexOf(element) + 1 || -1) > 0)
    );
    const expandedItemsIds = this.additionalDataInfo.map((e) => {
      if (e.expanded) {
        return e.id;
      }
      return -1;
    });
    const processedDataItems = itemsWithNestedComponents.filter((e) =>
      e.parentsIds?.every((element) => expandedItemsIds.includes(element)));
    if (this.data.firstItem) {
      processedDataItems.splice(0, 0, this.data.firstItem);
    }
    return {
      itemsLength: lengthRoot,
      items: processedDataItems,
      views: this.data.views,
    };
  }

  get processedDataForExport(): FetchedData {
    const lengthRoot = this.dataFilteredByStatus.filter((e) => !e.parentsIds?.length).length;
    const itemsInRange = this.dataFilteredByStatus
      .filter((e) => !e.parentsIds?.length);
    const ids = itemsInRange.map((e) => e.id || 0);
    const bomIds = itemsInRange.map((e) => e.tree || 0);
    const itemsWithNestedComponents = this.dataFilteredByStatus.filter(
      (e) =>
        bomIds.includes(e.tree || 0) ||
        e.parentsIds?.some((element: number) => (ids.indexOf(element) + 1 || -1) > 0)
    );
    const expandedItemsIds = this.additionalDataInfo.map((e) => {
      if (e.expanded) {
        return e.id;
      }
      return -1;
    });
    const processedDataItems = itemsWithNestedComponents.filter((e) =>
      e.parentsIds?.every((element) => expandedItemsIds.includes(element)));
    if (this.data.firstItem) {
      processedDataItems.splice(0, 0, this.data.firstItem);
    }
    return {
      itemsLength: lengthRoot,
      items: processedDataItems,
      views: this.data.views,
    };
  }

  get dataFilteredByStatus(): FetchedItem[] {
    if (!this.status || this.status === 'Total') {
      return this.filteredData;
    }
    const filteredDataByStatus = this.filteredData
      .filter((e) => e.RoHS_Status === this.status);
    const parentsIdsOfAllComponentsWithStatus = filteredDataByStatus
      .map((e) => e.parentsIds)
      .flat();
    const parentsIdsOfAllComponentsWithStatusFiltered = parentsIdsOfAllComponentsWithStatus
      .filter((e) => filteredDataByStatus.some((element) => typeof e === 'number' && element.parentsIds?.includes(e) && !element.expandable));
    return this.filteredData.filter(
      (e) =>
        (e.RoHS_Status === this.status ||
          parentsIdsOfAllComponentsWithStatusFiltered.includes(typeof e.id === 'number' ? e.id : 0))
    );
  }

  get exportTitle(): string {
    if (!this.status) {
      return 'gra';
    }
    return `gra_${this.status.replaceAll(' ', '_')}`;
  }

  get title(): string {
    if (!this.status) {
      return '';
    }
    if (this.status === 'Total') {
      return 'BOM Components ALL';
    }
    return `BOM Components with ROHS Status ${this.status}`;
  }

  async mounted(): Promise<void> {
    this.getURLParams();
    this.isExportDisabled = true;
    const promiseChart = fetchStatisticsRoHsChart(this.id);
    const promiseInfo = fetchBOMRohs(this.id);
    await Promise.all([promiseChart, promiseInfo]).then((result) => {
      [this.chartData, this.data] = result;
    });
    this.isExportDisabled = false;
    this.isLoadingNewRecords = false;
    this.isLoadingChartData = false;
    this.resetEmitListener();
  }

  public resetEmitListener(): void {
    eventBus.$on('resetForm', async () => {
      this.subFilters = [];
      this.updateURL();
      this.itemsRange = this.defaultRange;
    });
  }

  public async fetchData(): Promise<void> {
    this.isExportDisabled = true;
    this.data = await fetchBOMRohs(this.id);
    this.isExportDisabled = false;
  }

  public async clickTableValue(payload: string): Promise<void> {
    this.status = payload;
    this.itemsRange = this.defaultRange;
  }

  public async exportProcessedDataTable(exportDataParams: IExport): Promise<void> {
    this.isExportLoading = true;
    const fileName = `${this.partNumber}_${this.exportTitle}_${new Date().getFullYear()}_${new Date().getMonth()}_${new Date().getDate()}`;
    const { items } = this.processedDataForExport;
    exportData(
      exportDataParams.format,
      items,
      fileName,
      this.exportTitle,
      exportDataParams.headers
    );
    this.isExportLoading = false;
  }
}
