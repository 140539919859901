import { Component, Vue, Prop } from 'vue-property-decorator';
import _, { Dictionary } from 'lodash';
import { Filter, View, FetchedData, Sorting, FetchedItem, AdditionalDataInfo, IExport, IFilterEmit } from '@/interfaces';
import { fillInFilters, createQuery } from '@/helpers/URLFilters';
import { refreshToken, cancelAllRequests } from '@/api/axios-base';
import exportData from '@/helpers/exportData';
import eventBus from '@/eventBus';

@Component
class TableMixin extends Vue {
  @Prop() id!: string;

  @Prop() idDeclaration!: string;

  @Prop() partNumber!: string;

  @Prop() itemId!: number;

  @Prop() itemName!: string;

  @Prop() specification!: string;

  @Prop() declaration!: string;

  @Prop() calculatedCompliance!: string;

  public subFilters: Filter[] = [];

  public data: FetchedData = {
    itemsLength: 0,
    items: [],
    views: [],
  };

  public views: View[] = [];

  public isLoadingNewRecords = true;

  public query: Dictionary<string | (string | null)[]> = {};

  private recordsPerPage = parseInt(localStorage.getItem('recordsPerPage') ?? '15', 10);

  public itemsRange: number[] = this.defaultRange;

  public sorting: Sorting = {
    name: '',
    order: 0,
  }

  public isExportLoading = false;

  public isExportDisabled = true;

  public additionalDataInfo: AdditionalDataInfo[] = [];

  get defaultRange(): number[] {
    return [0, this.recordsPerPage];
  }

  get sortedData(): FetchedItem[] {
    const coppiedArray = Array.from(this.data.items);
    if (this.sorting.name) {
      return coppiedArray.sort((a, b) => {
        if (typeof a[this.sorting.name] === 'string' && typeof b[this.sorting.name] === 'string') {
          const nameA = a[this.sorting.name]?.toString().toUpperCase();
          const nameB = b[this.sorting.name]?.toString().toUpperCase();
          if (nameA && nameB) {
            if (nameA < nameB) {
              return this.sorting.order * (-1);
            }
            if (nameA > nameB) {
              return this.sorting.order;
            }
          }
        } else if (typeof a[this.sorting.name] === 'number' && typeof b[this.sorting.name] === 'number') {
          const nameA = a[this.sorting.name];
          const nameB = b[this.sorting.name];
          if (nameA && nameB) {
            if (nameA < nameB) {
              return this.sorting.order * (-1);
            }
            if (nameA > nameB) {
              return this.sorting.order;
            }
          }
        } else {
          return 0;
        }

        return 0;
      });
    }

    return coppiedArray;
  }

  get filteredData(): FetchedItem[] {
    return this.sortedData.filter((e) => {
      const isValid = Object.entries(this.query).every(([key, value]) => {
        const parameter = e[key];
        if (typeof value === 'string' && parameter) {
          if (value.includes('*')) {
            const splitedValue = value.split('*');
            const correctnesList: boolean[] = [];
            splitedValue.forEach((element, index) => {
              if (index === 0 && element) {
                correctnesList.push(
                  parameter.toString().toUpperCase().startsWith(element.toUpperCase().trim())
                );
              }
              if ((index === splitedValue.length - 1) && element) {
                correctnesList.push(
                  parameter.toString().toUpperCase().endsWith(element.toUpperCase().trim())
                );
              }
              if ((index !== splitedValue.length - 1) && index !== 0) {
                correctnesList.push(
                  parameter.toString().toUpperCase().includes(element.toUpperCase().trim())
                );
              }
            });
            return !correctnesList.includes(false);
          }
          return (parameter.toString().toUpperCase() === value.toUpperCase());
        }
        return false;
      });
      return isValid;
    });
  }

  get dataFiltered(): FetchedItem[] {
    const parentsIdsOfAllComponentsWithStatus = this.filteredData
      .map((e) => e.parentsIds)
      .flat();
    return this.sortedData.filter(
      (e) =>
        this.filteredData.some(
          (element) =>
            e.id === element.id
        ) ||
        parentsIdsOfAllComponentsWithStatus.includes(typeof e.id === 'number' ? e.id : 0)
    );
  }

  get processedData(): FetchedData {
    const lengthRoot = this.dataFiltered.filter((e) => !e.parentsIds?.length).length;
    const itemsInRange = this.dataFiltered.filter((e) => !e.parentsIds?.length)
      .slice(this.itemsRange[0], this.itemsRange[1]);
    const ids = itemsInRange.map((e) => e.id || 0);
    const bomIds = itemsInRange.map((e) => e.tree || 0);
    const itemsWithNestedComponents = this.dataFiltered
      .filter(
        (e) => bomIds.includes(e.tree || 0) ||
          e.parentsIds?.some((element: number) => ((ids.indexOf(element) + 1) || -1) >= 0)
      );
    const expandedItemsIds = this.additionalDataInfo.map((e) => {
      if (e.expanded) {
        return e.id;
      }
      return -1;
    });

    const processedDataItems = itemsWithNestedComponents.filter((e) =>
      e.parentsIds?.every((element) => expandedItemsIds.includes(element)));
    if (this.data.firstItem) {
      processedDataItems.splice(0, 0, this.data.firstItem);
    }
    return {
      itemsLength: lengthRoot,
      items: processedDataItems,
      views: this.data.views,
    };
  }

  public async filterBySubFilters(data: IFilterEmit): Promise<void> {
    this.subFilters = data.filters;
    if (data.doFiltering) {
      this.isLoadingNewRecords = true;
      await this.updateURL();
      await this.fetchData();
      this.isLoadingNewRecords = false;
    }
  }

  /* eslint-disable class-methods-use-this */
  public async fetchData(): Promise<void> {
    // to be overwritten later by the component.
  }

  public async changeSorting(sorting: Sorting): Promise<void> {
    this.isLoadingNewRecords = true;
    this.sorting = sorting;
    cancelAllRequests();
    refreshToken();
    await this.fetchData();
    this.isLoadingNewRecords = false;
  }

  public async changeItemsRange(range: number[]): Promise<void> {
    this.isLoadingNewRecords = true;
    this.recordsPerPage = range[1] - range[0];
    localStorage.setItem('recordsPerPage', this.recordsPerPage.toString());
    this.itemsRange = range;
    await this.fetchData();
    this.isLoadingNewRecords = false;
  }

  public updateURL(): void {
    this.query = this.$route.query;
    const newQuery: Dictionary<string | (string | null)[]> = createQuery(this.subFilters);
    if (!_.isEqual(this.query, newQuery)) this.$router.replace({ query: newQuery });
    this.query = newQuery;
  }

  public filterNestedRecords(data: IFilterEmit): void {
    this.subFilters = data.filters;
    if (data.doFiltering) {
      this.updateURL();
    }
  }

  public changeNestedSorting(sorting: Sorting): void {
    this.sorting = sorting;
  }

  public changeDataRange(range: number[]): void {
    this.recordsPerPage = range[1] - range[0];
    localStorage.setItem('recordsPerPage', this.recordsPerPage.toString());
    this.itemsRange = range;
  }

  public getURLParams(): void {
    this.query = this.$route.query;
    this.subFilters = fillInFilters(this.query);
  }

  public async exportTable(exportDataParams: IExport): Promise<void> {
    this.isExportLoading = true;
    const fileName = `${this.partNumber}_${this.$route.meta?.export}_${new Date().getFullYear()}_${new Date().getMonth()}_${new Date().getDate()}`;
    const { items } = this.data;
    exportData(
      exportDataParams.format,
      items,
      fileName,
      this.$route.meta?.export,
      exportDataParams.headers
    );
    this.isExportLoading = false;
  }

  public emitListener(): void {
    eventBus.$on('resetForm', async () => {
      this.isLoadingNewRecords = true;
      this.subFilters = [];
      this.updateURL();
      this.itemsRange = this.defaultRange;
      await this.fetchData();
      this.isLoadingNewRecords = false;
    });
  }

  public expandItemInformation(additionalDataInfo: AdditionalDataInfo): void {
    this.additionalDataInfo = this.additionalDataInfo.filter(
      (e) => e.id !== additionalDataInfo.id && !e.parentsIds.includes(additionalDataInfo.id)
    );
    this.additionalDataInfo.push(additionalDataInfo);
  }
}

export default TableMixin;
