













import { Component, Vue, Prop } from 'vue-property-decorator';
import { ItemsSet, DetailItem } from '@/interfaces';
import ExpansionPanel from '@/components/ExpansionPanel.vue';
import { fetchAttributes } from '@/api/drilldown';

@Component({
  components: {
    ExpansionPanel,
  },
})
export default class Attributes extends Vue {
  @Prop() id!: string;

  private data: ItemsSet[] = [];

  public isLoading = true;

  get mappedData(): ItemsSet[] {
    const mappedData: ItemsSet[] = this.data.map((e) => {
      const item: DetailItem[] = [];
      e.item.forEach((detailItem) => {
        item.push({ ...detailItem, selected: null });
      });
      const element = { name: e.name, item };
      return element;
    });
    return mappedData;
  }

  async created(): Promise<void> {
    this.data = await fetchAttributes(this.id);
    this.isLoading = false;
  }
}
