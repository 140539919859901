var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"height":"100%","width":"100%"},attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"d-flex flex-wrap",attrs:{"cols":"12"}},[_c('div',{staticClass:"text-left py-0 pl-10",staticStyle:{"height":"5%","width":"75%"}},[(_vm.expandAll)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","disabled":_vm.isExportDisabled},on:{"click":function($event){_vm.isExpanded = !_vm.isExpanded}}},on),[(!_vm.isExpanded)?_c('v-icon',[_vm._v("mdi-expand-all")]):_c('v-icon',[_vm._v("mdi-collapse-all")])],1)]}}],null,false,2302595852)},[(!_vm.isExpanded)?_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.expand')))]):_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.expand')))])]):_vm._e(),(_vm.header)?_c('span',{staticClass:"text-h5 pl-2"},[_vm._v(_vm._s(_vm.header))]):_vm._e()],1),_c('div',{staticClass:"text-right py-0 pr-10",staticStyle:{"height":"5%","width":"25%","display":"flex","justify-content":"end"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","disabled":_vm.isExportDisabled},on:{"click":_vm.toogleFullscreen}},on),[(!_vm.fullscreen)?_c('v-icon',[_vm._v("mdi-fullscreen")]):_c('v-icon',[_vm._v("mdi-fullscreen-exit")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.fullscreen')))])]),(_vm.user.permissions.includes(_vm.permissions.ADMINISTRATION_SETTING))?_c('AdminViewTable',{attrs:{"disabledSelect":_vm.isExportDisabled,"fields":_vm.fields}}):_vm._e(),_c('ExportTableDialog',{attrs:{"loading":_vm.isExportLoading,"disabledExport":_vm.isExportDisabled,"enableXML":_vm.enableXML,"enableAll":_vm.enableAll},on:{"exportTable":_vm.exportTable}}),_c('SaveViewDialog',{attrs:{"fields":_vm.fields,"views":_vm.views,"disabledSelect":_vm.isExportDisabled},on:{"updateViews":_vm.updateViews}}),_c('HeadersDialog',{attrs:{"fields":_vm.fields,"headers":_vm.tableHeaders,"disabledSelect":_vm.isExportDisabled},on:{"updateHeader":_vm.updateHeader}})],1)])],1),_c('v-row',{staticStyle:{"height":"80%"}},[_c('v-col',{staticClass:"px-10",staticStyle:{"height":"100%"},attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"table-bottom-border",attrs:{"height":"100%","dense":"","loading":_vm.loadingData,"headers":_vm.selectedHeaders,"items":_vm.items,"server-items-length":_vm.itemsLength,"item-key":"id","fixed-header":"","hide-default-header":"","hide-default-footer":"","disable-pagination":"","group-by":"Group_ID","show-group-by":"","groupable":"","mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
var on = ref.on;
return [_c('thead',[_c('draggable',{attrs:{"tag":"tr"},model:{value:(_vm.selectedHeaders),callback:function ($$v) {_vm.selectedHeaders=$$v},expression:"selectedHeaders"}},_vm._l((props.headers),function(head){return _c('th',_vm._g({key:head.text,staticClass:"table-header-column text-button font-weight-bold",class:head.sortable ? 'table-header-column-hover' : '',staticStyle:{"white-space":"nowrap","padding":"0px 32px 16px"},attrs:{"scope":"col"},on:{"click":function($event){return _vm.sort(head.text, head.sortable)}}},on),[_vm._v(" "+_vm._s(head.text)+" "),(_vm.sorting.name === head.text && _vm.sorting.order === 1)?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-arrow-up-thin")]):(_vm.sorting.name === head.text && _vm.sorting.order === -1)?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-arrow-down-thin")]):_vm._e()],1)}),0)],1)]}},(!_vm.grouped)?{key:"body",fn:function(ref){
var items = ref.items;
var headers = ref.headers;
return [_c('tbody',_vm._l((items),function(item,index){return _c('CustomRow',{key:_vm.expandable ? ((item.id) + "-" + (item.tree)) : index,attrs:{"data":item,"headers":headers,"parentItemId":item.id,"loading":_vm.loadingData,"isExpandedAll":_vm.isExpanded},on:{"expandItemInformation":_vm.expandItemInformation,"fetchMoreData":_vm.fetchMoreData}})}),1)]}}:null,(_vm.grouped)?{key:"group",fn:function(ref){
var items = ref.items;
var headers = ref.headers;
var group = ref.group;
return [_c('CustomTableGroup',{attrs:{"group":group,"items":items,"headers":headers}})]}}:null],null,true)})],1)],1),_c('v-row',{staticStyle:{"height":"10%"}},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"text-center",staticStyle:{"max-height":"150px"},attrs:{"id":"pagination"}},[_c('Fotter',{attrs:{"tableLength":_vm.itemsLength,"loading":_vm.loadingData},on:{"itemsRange":_vm.itemsRange}})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }