














import { Component, Vue } from 'vue-property-decorator';
import GeneralSnackbar from '@/components/Snackbar/GeneralSnackbar.vue';

@Component({
  components: {
    GeneralSnackbar,
  },
})
export default class App extends Vue {
  mounted(): void {
    const theme = window.localStorage.getItem('theme');
    if (theme === 'dark') {
      this.$vuetify.theme.dark = true;
    }
  }
}
