

















































import { Component, Vue, PropSync, Prop } from 'vue-property-decorator';
import { DetailItem, SpecialCell } from '@/interfaces';
import eventBus from '@/eventBus';
import { specialCellsConfig, urls, dateFields } from '@/configuration';

@Component
export default class InfoContainer extends Vue {
  @PropSync('title', { required: false }) cardTitle!: string;

  @PropSync('items', { required: true }) cardItems!: DetailItem[];

  @Prop({ default: true }) checkboxes!: boolean;

  @Prop({ default: true }) displayBorder!: boolean;

  private sessionStorage = window.sessionStorage;

  public cellsConfig: SpecialCell[] = specialCellsConfig;

  private urlFields = urls;

  private window = window;

  get itemsToDisplay(): DetailItem[] {
    const items: DetailItem[] = [];
    this.cardItems.forEach((e) => {
      const selectedItems = this.sessionStorage.getItem(e.name);
      if (selectedItems === e.value) {
        items.push({
          name: e.name,
          value: e.value,
          selected: e.value,
        });
      } else {
        items.push({
          name: e.name,
          value: e.value,
          selected: null,
        });
      }
    });
    return items;
  }

  public isDateFields(name: string): boolean {
    return dateFields.includes(name);
  }

  public convertDate(value: string): string | null {
    if (value) {
      const dateTime = new Date(value);
      return `${dateTime.getFullYear()}-${String(dateTime.getMonth() + 1).padStart(
        2,
        '0'
      )}-${String(dateTime.getDate()).padStart(2, '0')}`;
    }
    return null;
  }

  public updateLocalStorage(name: string, value: string | null): void {
    if (value) {
      this.sessionStorage.setItem(name, value);
    } else {
      this.sessionStorage.removeItem(name);
    }
    eventBus.$emit('changeAttributesLength', this.sessionStorage.length);
  }

  public cellIconColor(itemName: string, header: string): string {
    const cellColor = this.cellsConfig.find((e) => e.name === itemName && e.header === header);
    return cellColor ? cellColor.color : '';
  }

  public cellIcon(itemName: string, header: string): string {
    const cellIcon = this.cellsConfig.find((e) => e.name === itemName && e.header === header);
    return cellIcon ? cellIcon.icon : '';
  }

  public specialHeader(header: string): boolean {
    const headerExist = this.cellsConfig.find((e) => e.header === header);
    return headerExist !== undefined;
  }

  public isUrl(value: string, data: string): boolean {
    return this.urlFields.some((e) => e === value) && data.startsWith('http');
  }

  public goToUrl(value: string): void {
    this.window.open(value, '_blank');
  }
}
