






























import { Component, Mixins } from 'vue-property-decorator';
import Filters from '@/models/Filters';
import { StatisticDataMain, View, FetchedData, FetchedItem, IExport } from '@/interfaces';
import StatisticMixContainer from '@/components/Statistics/Containers/StatisticMixContainer.vue';
import TableComponent from '@/components/Table/TableComponent.vue';
import TableMixin from '@/mixins/tableMixins';
import { fetchBOMRisk, fetchStatisticsEOLChart } from '@/api/drilldown';
import eventBus from '@/eventBus';
import exportData from '@/helpers/exportData';

@Component({
  components: {
    TableComponent,
    StatisticMixContainer,
  },
})
export default class RiskAnalysis extends Mixins(TableMixin) {
  public defaultView: View = {
    name: 'Default',
    fields: [
      'Num',
      'Part_Number',
      'Estimated_End_of_Life_Range',
      'Vendor_Part_Status',
      'Vendor_Part_Number',
    ],
    default: false,
    selected: true,
  };

  public chartData: StatisticDataMain = {
    message: 'According to Risk Analysis',
    totalParts: 0,
    status: [],
  };

  // public notSortable: string[] = [
  //   Filters.CUSTOMER_PART_OWNERS,
  //   Filters.CUSTOMER_PARTS,
  //   Filters.CUSTOMER_PART_STATUSES,
  //   Filters.CUSTOMER_PROJECTS,
  // ];

  public notSortable: string[] = ['all'];

  public disableFilterFields: string[] = [
    Filters.CUSTOMER_PART_OWNERS,
    Filters.CUSTOMER_PARTS,
    Filters.CUSTOMER_PART_STATUSES,
    Filters.CUSTOMER_PROJECTS,
  ];

  public isLoadingChartData = true;

  public status: string | null = 'Total';

  get processedData(): FetchedData {
    const lengthRoot = this.dataFilteredByStatus.filter((e) => !e.parentsIds?.length).length;
    const itemsInRange = this.dataFilteredByStatus
      .filter((e) => !e.parentsIds?.length)
      .slice(this.itemsRange[0], this.itemsRange[1]);
    const ids = itemsInRange.map((e) => e.id || 0);
    const bomIds = itemsInRange.map((e) => e.tree || 0);
    const itemsWithNestedComponents = this.dataFilteredByStatus.filter(
      (e) =>
        bomIds.includes(e.tree || 0) ||
        e.parentsIds?.some((element: number) => (ids.indexOf(element) + 1 || -1) > 0)
    );
    const expandedItemsIds = this.additionalDataInfo.map((e) => {
      if (e.expanded) {
        return e.id;
      }
      return -1;
    });
    const processedDataItems = itemsWithNestedComponents.filter((e) =>
      e.parentsIds?.every((element) => expandedItemsIds.includes(element)));
    if (this.data.firstItem) {
      processedDataItems.splice(0, 0, this.data.firstItem);
    }
    return {
      itemsLength: lengthRoot,
      items: processedDataItems,
      views: this.data.views,
    };
  }

  get processedDataForExport(): FetchedData {
    const lengthRoot = this.dataFilteredByStatus.filter((e) => !e.parentsIds?.length).length;
    const itemsInRange = this.dataFilteredByStatus
      .filter((e) => !e.parentsIds?.length);
    const ids = itemsInRange.map((e) => e.id || 0);
    const bomIds = itemsInRange.map((e) => e.tree || 0);
    const itemsWithNestedComponents = this.dataFilteredByStatus.filter(
      (e) =>
        bomIds.includes(e.tree || 0) ||
        e.parentsIds?.some((element: number) => (ids.indexOf(element) + 1 || -1) > 0)
    );
    const expandedItemsIds = this.additionalDataInfo.map((e) => {
      if (e.expanded) {
        return e.id;
      }
      return -1;
    });
    const processedDataItems = itemsWithNestedComponents.filter((e) =>
      e.parentsIds?.every((element) => expandedItemsIds.includes(element)));
    if (this.data.firstItem) {
      processedDataItems.splice(0, 0, this.data.firstItem);
    }
    return {
      itemsLength: lengthRoot,
      items: processedDataItems,
      views: this.data.views,
    };
  }

  get dataFilteredByStatus(): FetchedItem[] {
    if (!this.status || this.status === 'Total') {
      return this.filteredData;
    }
    const filteredDataByStatus = this.filteredData.filter(
      (e) => e.Estimated_End_of_Life_Range === this.status
    );
    const parentsIdsOfAllComponentsWithStatus = filteredDataByStatus
      .map((e) => e.parentsIds)
      .flat();
    const parentsIdsOfAllComponentsWithStatusFiltered = parentsIdsOfAllComponentsWithStatus.filter(
      (e) =>
        filteredDataByStatus.some(
          (element) =>
            typeof e === 'number' && element.parentsIds?.includes(e) && !element.expandable
        )
    );
    return this.filteredData.filter(
      (e) =>
        e.Estimated_End_of_Life_Range === this.status ||
        parentsIdsOfAllComponentsWithStatusFiltered.includes(typeof e.id === 'number' ? e.id : 0)
    );
  }

  get exportTitle(): string {
    if (!this.status) {
      return 'eol';
    }
    return `eol_${this.status.replaceAll(' ', '_')}`;
  }

  get title(): string {
    if (!this.status) {
      return '';
    }
    if (this.status === 'Total') {
      return 'BOM Components ALL';
    }
    return `BOM Components with Estimated End of Life ${this.status}`;
  }

  async mounted(): Promise<void> {
    this.getURLParams();
    this.isExportDisabled = true;
    const promiseChart = fetchStatisticsEOLChart(this.id);
    const promiseInfo = fetchBOMRisk(this.id);
    await Promise.all([promiseChart, promiseInfo]).then((result) => {
      [this.chartData, this.data] = result;
    });
    // this.computeChartData();
    this.isExportDisabled = false;
    this.isLoadingNewRecords = false;
    this.isLoadingChartData = false;
    this.resetEmitListener();
  }

  public resetEmitListener(): void {
    eventBus.$on('resetForm', async () => {
      this.subFilters = [];
      this.updateURL();
      this.itemsRange = this.defaultRange;
    });
  }

  public async fetchData(): Promise<void> {
    this.isExportDisabled = true;
    this.data = await fetchBOMRisk(this.id);
    this.isExportDisabled = false;
  }

  public async clickTableValue(payload: string): Promise<void> {
    this.status = payload;
    this.itemsRange = this.defaultRange;
  }

  public computeChartData(): void {
    let undefinedCount = 0;
    let oneTwoCount = 0;
    let threeFourCount = 0;
    let fiveSevenCount = 0;
    let overSevenCount = 0;
    this.data.items.forEach((e) => {
      if (e.Estimated_End_of_Life_Range === '1-2y') {
        oneTwoCount += 1;
      } else if (e.Estimated_End_of_Life_Range === '3-4y') {
        threeFourCount += 1;
      } else if (e.Estimated_End_of_Life_Range === '5-7y') {
        fiveSevenCount += 1;
      } else if (e.Estimated_End_of_Life_Range === '>7y') {
        overSevenCount += 1;
      } else if (e.Estimated_End_of_Life_Range === 'Undefined') {
        undefinedCount += 1;
      }
    });
    this.chartData.totalParts =
      undefinedCount + oneTwoCount + threeFourCount + fiveSevenCount + overSevenCount;
    this.chartData.status = [
      {
        name: '1-2y',
        quantity: oneTwoCount,
        color: '#13ac6a',
      },
      {
        name: '3-4y',
        quantity: threeFourCount,
        color: '#5bc0de',
      },
      {
        name: '5-7y',
        quantity: fiveSevenCount,
        color: '#949fb1',
      },
      {
        name: '>7y',
        quantity: overSevenCount,
        color: '#51ac6a',
      },
      {
        name: 'Undefined',
        quantity: undefinedCount,
        color: '#a99002',
      },
    ];
  }

  public async exportProcessedDataTable(exportDataParams: IExport): Promise<void> {
    this.isExportLoading = true;
    const fileName = `${this.partNumber}_${this.exportTitle}_${new Date().getFullYear()}_${new Date().getMonth()}_${new Date().getDate()}`;
    const { items } = this.processedDataForExport;
    exportData(
      exportDataParams.format,
      items,
      fileName,
      this.exportTitle,
      exportDataParams.headers
    );
    this.isExportLoading = false;
  }
}
