import { Dictionary } from 'lodash';
import { Filter } from '@/interfaces';

function createQuery(filters: Filter[]): Dictionary<string | (string | null)[]> {
  const query: Dictionary<string | (string | null)[]> = {};
  filters.forEach((item) => {
    if (item.search !== '' && item.search !== null) query[item.value] = item.search;
  });
  return query;
}

function fillInFilters(query: Dictionary<string | (string | null)[]>): Filter[] {
  const filters: Filter[] = [];
  Object.entries(query).forEach(([key, value]) => {
    filters.push({
      text: key.replaceAll('_', ' '),
      search: value.toString(),
      value: key,
    });
  });
  return filters;
}

export { fillInFilters, createQuery };
