








































import { Vue, Component, PropSync } from 'vue-property-decorator';
import { SpecialCell, FetchedCustomerData } from '@/interfaces';
import { specialCustomerPartRiskConfig } from '@/configuration';

@Component
export default class CustomerPartNumber extends Vue {
  @PropSync('data', { required: true }) fetchedData!: FetchedCustomerData[];

  @PropSync('riskData', { required: true }) fetchedRiskData!: FetchedCustomerData[];

  get tableData(): FetchedCustomerData[] {
    const data: FetchedCustomerData[] = [];
    this.fetchedData.forEach((item) => {
      if (item.value !== '' && typeof item.value !== 'undefined') data.push(item);
      else data.push({ name: item.name, value: 'no data' });
    });
    return data;
  }

  public headers = [{
    text: 'Name',
    value: 'name',
    class: 'text-h6 font-weight-black pb-6 hover',
  },
  {
    text: 'Value',
    value: 'value',
    class: 'text-h6 font-weight-black pb-6 hover',
  },
  ]

  public riskStatusData: FetchedCustomerData[] = [];

  get thisYear(): number {
    return new Date().getFullYear();
  }

  public timelineItemStyle(item: string | null): SpecialCell {
    if (item === 'no data') return { color: '#000000', icon: 'mdi-close-circle', name: '', header: '' };
    const itemData = specialCustomerPartRiskConfig.find((e) => e.name === item);
    return itemData as SpecialCell;
  }

  public timelineItemName(item: string | null): string {
    if (typeof item === 'string') return item.replaceAll('_', ' ');
    return 'no data';
  }
}

