










































import { Component, Vue, PropSync, Prop, Emit } from 'vue-property-decorator';
import {
  StatisticDataBOM,
  StatisticHeader,
  StatisticParameter,
  StackedBarChartData,
} from '@/interfaces';
import { unclickableFields } from '@/configuration';

@Component
export default class StatusTableBOM extends Vue {
  @PropSync('tableData', { required: true }) data!: StatisticDataBOM;

  @Prop({ default: false }) showTotal!: boolean;

  public items: StackedBarChartData[] = [
    {
      name: '',
      year: new Date().getFullYear(),
      year_1: new Date().getFullYear() + 1,
      year_2: new Date().getFullYear() + 2,
      year_3: new Date().getFullYear() + 3,
      year_4: new Date().getFullYear() + 4,
      color: '',
    },
  ];

  public unclickable = unclickableFields;

  public headers: StatisticHeader[] = [
    { value: 'name' },
    { value: 'year' },
    { value: 'year_1' },
    { value: 'year_2' },
    { value: 'year_3' },
    { value: 'year_4' },
  ];

  get totalValues(): number {
    return this.data.status.reduce(
      (previousValue, currentValue) => previousValue + currentValue.year,
      0
    );
  }

  public mounted(): void {
    this.items = this.items.concat(this.data.status);
  }

  @Emit('clickTableValue')
  public handleClickValue(status: string, year: number): StatisticParameter {
    return { status, year };
  }
}
