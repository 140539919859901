













import { Component, Vue, Inject } from 'vue-property-decorator';
import UIPermissions from '@/models/UIPermissions';
import { PermissionsRouter } from '@/interfaces';
import hasPermission from '@/helpers/hasPermission';
import User from '@/models/User';

@Component
export default class Declaration extends Vue {
  @Inject() user!: User;

  public navigatorPerPermissions: PermissionsRouter[] = [
    {
      name: 'declaration.general',
      permissions: [
        UIPermissions.CAS_NUMBER_TAB,
        UIPermissions.SUBSTANCE_NAME_TAB,
        UIPermissions.PPM_RESULT_TAB,
      ],
    },
    {
      name: 'declaration.reports',
      permissions: [
        UIPermissions.CAS_NUMBER_TAB,
        UIPermissions.SUBSTANCE_NAME_TAB,
        UIPermissions.PPM_RESULT_TAB,
      ],
    },
  ];

  async created(): Promise<void> {
    this.navigate();
  }

  public navigate(): void {
    if (this.$route.name === 'declaration') {
      const route = this.navigatorPerPermissions.find(
        (e) => hasPermission(this.user.permissions, e.permissions)
      );
      if (route) {
        this.$router.push({ name: route.name });
      }
    }
  }
}
