






































































import { Component, Vue, PropSync, Emit, Watch } from 'vue-property-decorator';
import { Header } from '@/interfaces';
import { disableHeaders } from '@/configuration';

@Component
export default class HeadersDialog extends Vue {
  @PropSync('headers', { required: true }) tableHeaders!: Header[];

  @PropSync('disabledSelect', { default: false }) disabled!: boolean;

  @PropSync('fields') selectedFields!: string[];

  public dialog = false;

  private headersBuffer: string[] = [];

  public headersNotAllowed = disableHeaders;

  public savedHeaders: string[] = [];

  public searchValue = '';

  public order = 0;

  @Watch('fields')
  fieldsChange(newVal: string[]): void {
    this.savedHeaders = newVal;
    this.headersBuffer = newVal;
  }

  @Watch('tableHeaders')
  tableHeadersChange(newVal: Header[]): void {
    this.savedHeaders = this.savedHeaders.filter(
      (e) => newVal.map((element) => element.value).includes(e)
    );
    this.headersBuffer = this.headersBuffer.filter(
      (e) => newVal.map((element) => element.value).includes(e)
    );
    this.submit();
  }

  get casedSearchValue(): string {
    return this.searchValue.toLowerCase();
  }

  get sortedHeaders(): Header[] {
    let result = this.tableHeaders.slice(0);
    if (result && this.order === 1) {
      this.sorting(result);
    } else if (result && this.order === 2) {
      this.sorting(result);
      result.reverse();
    } else {
      result = this.tableHeaders;
    }
    return result;
  }

  get filteredHeaders(): string[] {
    const filteredHeaders: string[] = [];
    this.sortedHeaders.forEach((e) => {
      if (!this.headersNotAllowed.includes(e.value)) {
        filteredHeaders.push(e.value);
      }
    });
    return filteredHeaders;
  }

  mounted(): void {
    this.savedHeaders = this.selectedFields;
    this.headersBuffer = this.selectedFields;
  }

  public cancel(): void {
    this.dialog = false;
    this.savedHeaders = this.headersBuffer;
    this.order = 0;
  }

  public toggle(): void {
    if (this.filteredHeaders.some((e) => !this.savedHeaders.includes(e))) {
      this.filteredHeaders.forEach((e) => {
        if (!this.savedHeaders.includes(e)) {
          this.savedHeaders.push(e);
        }
      });
    } else {
      this.savedHeaders = [];
    }
  }

  public ordering(): void {
    if (this.order === 0) {
      this.order += 1;
    } else if (this.order === 1) {
      this.order += 1;
    } else {
      this.order = 0;
    }
  }

  public sorting(headers: Header[]): void {
    headers.sort((a, b): number => {
      const aCased = a.text.toLowerCase();
      const bCased = b.text.toLowerCase();
      if (aCased < bCased) {
        return -1;
      }
      if (aCased > bCased) {
        return 1;
      }
      return 0;
    });
  }

  @Emit('updateHeader')
  submit(): string[] {
    this.dialog = false;
    this.headersBuffer = this.savedHeaders;
    return this.savedHeaders;
  }
}
