import VueI18n from 'vue-i18n';
import { defaultLocale } from '@/configuration';
import languageInterface from '@/locale/interfaces';

function unifiesLanguageName(language: string): string {
  return (language.split('-')[0]).toLowerCase();
}

export default class Localization {
  languages: languageInterface;

  translator!: VueI18n;

  unifiesLanguageName: typeof unifiesLanguageName;

  constructor(languages: languageInterface, translator: VueI18n) {
    this.languages = languages;
    this.translator = translator;
    this.unifiesLanguageName = unifiesLanguageName.bind(this);
  }

  public getLanguage(): string {
    const storedLanguage = this.unifiesLanguageName(localStorage.getItem('language') || '');
    if (this.isSupported(storedLanguage)) {
      return storedLanguage;
    }

    const browserLang = this.unifiesLanguageName(navigator.language);
    if (this.isSupported(browserLang)) {
      return browserLang;
    }

    return this.unifiesLanguageName(defaultLocale);
  }

  public setLanguage(language: string): void {
    let newLanguage = language;
    if (!this.isSupported(newLanguage)) {
      newLanguage = this.unifiesLanguageName(defaultLocale);
    }

    this.translator.locale = newLanguage;
    localStorage.setItem('language', newLanguage);
  }

  private isSupported(language: string): boolean {
    return Object.prototype.hasOwnProperty.call(
      this.languages, this.unifiesLanguageName(language),
    );
  }
}
