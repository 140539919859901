


























































import { Component, Vue, Emit } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import { required, password } from '@/validation/rules';
import { changePassword } from '@/api/user';

@Component
export default class NewPassForm extends Vue {
  public isLoading = false;

  public showPassword = true;

  public showPasswordConfirmation = true;

  public password = '';

  public passwordConfirmation = '';

  public match = true;

  public valid = false;

  public requiredRule = required;

  public passwordRule = password;

  public errorText: TranslateResult = '';

  public async newPassword(): Promise<void> {
    if (this.passwordConfirmation === this.password) {
      this.isLoading = true;
      const status = await changePassword(this.password, this.passwordConfirmation);
      this.isLoading = false;
      if (status === 409) {
        this.errorText = this.$t('login.newPass.username');
        this.match = false;
      } else if (status === 200) {
        this.closeForm();
      } else {
        this.errorText = this.$t('login.newPass.serverError.message');
        this.match = false;
      }
    } else {
      (this.$refs.form as Vue & { reset: () => boolean }).reset();
      this.errorText = this.$t('login.newPass.error');
      this.match = false;
    }
  }

  @Emit('closeForm')
  public closeForm(): boolean {
    return true;
  }
}
