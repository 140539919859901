var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"pa-1",attrs:{"headers":_vm.headers,"items":_vm.items,"hide-default-header":"","hide-default-footer":"","fixed-header":"","height":_vm.$vuetify.breakpoint.smAndDown ? '95vh' : '45vh'},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index !== _vm.items.length - 1)?_c('a',{staticStyle:{"text-decoration-line":"none"},attrs:{"href":_vm.getRouterToValue(item.name),"exact":""},on:{"click":function (e) { return e.preventDefault(); }}},[_c('span',{on:{"click":function($event){_vm.goToUrl(_vm.getRouterToValue(item.name))}}},[_vm._v(_vm._s(item.name))])]):_c('span',{staticClass:"total-title",staticStyle:{"text-decoration-line":"none"}},[_c('strong',[_vm._v(_vm._s(item.name.toUpperCase()))])])]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.color,"dark":""}},[_vm._v(" "+_vm._s(item.quantity)+" ")])]}},{key:"item.percent",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.color,"dark":""}},[_vm._v(" "+_vm._s(item.percent)+" ")])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }