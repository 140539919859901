





























import { Component, Vue, PropSync } from 'vue-property-decorator';

@Component
export default class InfoCard extends Vue {
  @PropSync('title', { required: true }) infoTitle!: string;

  @PropSync('message', { required: true }) infoMessage!: string;

  @PropSync('error', { required: false }) infoError!: string;

  mounted(): void {
    if (!this.infoTitle || !this.infoMessage) {
      this.$router.push({ name: 'login.signIn' });
    }
  }
}
