



















import { Component, Mixins } from 'vue-property-decorator';
import TableComponent from '@/components/Table/TableComponent.vue';
import TableMixin from '@/mixins/tableMixins';
import Filters from '@/models/Filters';
import { fetchWhereUsed } from '@/api/drilldown';
import { View } from '@/interfaces';

@Component({
  components: {
    TableComponent,
  },
})
export default class WhereUsed extends Mixins(TableMixin) {
  public defaultView: View = {
    name: 'Default',
    fields: [
      'Part_Number',
      'Description',
      'Type',
      'Vendor',
      'Vendor_Part_Number',
      'Vendor_Part_Status',
      'Datasheet_URL',
      'Customer_Parts',
      'Value',
    ],
    default: false,
    selected: true,
  };

  public notSortable: string[] = [
    Filters.CUSTOMER_PART_OWNERS,
    Filters.CUSTOMER_PARTS,
    Filters.CUSTOMER_PART_STATUSES,
    Filters.CUSTOMER_PROJECTS,
    Filters.ALTIUM_PCB_FOOTPRINT_LEAST,
    Filters.ALTIUM_PCB_FOOTPRINT_MOST,
    Filters.ALTIUM_PCB_FOOTPRINT_NOMINAL,
    Filters.ALTIUM_SCHEMATIC_PART,
    Filters.ALTIUM_SECOND_CUSTOM_FOOTPRINTS,
    Filters.THREE_D_MODEL_DWF,
    Filters.THREE_D_MODEL_IPT,
    Filters.THREE_D_MODEL_STP,
    Filters.PCB_ALTERNATE_FOOTPRINT,
    Filters.PCB_FOOTPRINT,
    Filters.SCHEMATIC_PART,
  ];

  public disableFilterFields: string[] = [
    Filters.CUSTOMER_PART_OWNERS,
    Filters.CUSTOMER_PARTS,
    Filters.CUSTOMER_PART_STATUSES,
    Filters.CUSTOMER_PROJECTS,
  ];

  async mounted(): Promise<void> {
    this.getURLParams();
    await this.fetchData();
    this.isLoadingNewRecords = false;
    this.emitListener();
  }

  public async fetchData(): Promise<void> {
    this.isExportDisabled = true;
    this.data = await fetchWhereUsed({
      id: this.id,
      filters: this.query,
      lowerThreshold: this.itemsRange[0],
      upperThreshold: this.itemsRange[1],
      sortingName: this.sorting.name,
      sortingOrder: this.sorting.order,
    });
    this.isExportDisabled = false;
  }
}
