














































































































import { Component, Vue, PropSync, Emit, Watch } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import _ from 'lodash';
import SimpleSnackbar from '@/components/Snackbar/SimpleSnackbar.vue';
import DialogConfirm from '@/components/Dialog/DialogConfirm.vue';
import { View } from '@/interfaces';
import { required } from '@/validation/rules';
import {
  addTableViews,
  deleteTableViews,
  updateDefaultTableViews,
  updateFieldsTableViews,
} from '@/api/table';

@Component({
  components: {
    SimpleSnackbar,
    DialogConfirm,
  },
})
export default class HeadersDialog extends Vue {
  @PropSync('views') allViews!: View[];

  @PropSync('disabledSelect', { default: false }) disabled!: boolean;

  @PropSync('fields') selectedFields!: string[];

  public valid = false;

  public dialog = false;

  public showDeleteDialog = false;

  public viewName: string | View = '';

  public savedHeaders: string[] = [];

  public selectedView: string | null = null;

  public showSnackbar = false;

  public requiredRule = required;

  private modefiedViews: View[] = [];

  private viewToRemove: string | null = null;

  public loadingAddUpdateButton = false;

  public loadingStar = '';

  @Watch('views')
  updateViews(newVal: View[]): void {
    this.curentViews = Array.from(newVal);
  }

  @Watch('fields')
  updateFields(newVal: string[]): void {
    const view = this.curentViews.find((e) => e.selected);
    this.selectedView = view && _.isEqual(view.fields, newVal) ? view.name : null;
    if (!this.selectedView) {
      this.curentViews.forEach((e) => {
        e.selected = false;
      });
    }
  }

  get curentViews(): View[] {
    return this.modefiedViews;
  }

  set curentViews(views: View[]) {
    this.modefiedViews = views;
  }

  get buttonTitle(): TranslateResult {
    if (!this.viewName) {
      return this.$t('table.saveView.add');
    }
    const name = typeof this.viewName === 'object' ? this.viewName.name : this.viewName;
    return this.curentViews.find((e) => e.name === name)
      ? this.$t('table.saveView.update')
      : this.$t('table.saveView.add');
  }

  public handleViewKeyUp(e: KeyboardEvent): void {
    if (e.target instanceof HTMLInputElement) {
      this.viewName = e.target.value;
    }
  }

  public async setDefault(name: string): Promise<void> {
    const nameDefault = this.curentViews.find((e) => e.default)?.name;
    if (this.$route.name && name !== nameDefault) {
      this.loadingStar = name;
      await updateDefaultTableViews({
        name,
        type: this.$route.name,
      }).then((status) => {
        if (status === 200) {
          this.curentViews.forEach((e) => {
            e.default = e.name === name;
          });
        }
        this.loadingStar = '';
      });
    }
  }

  public setSelected(name: string): void {
    if (name) {
      this.curentViews.forEach((e) => {
        e.selected = e.name === name;
      });
      this.submit();
    }
  }

  public openDeleteDialog(name: string): void {
    this.showDeleteDialog = true;
    this.viewToRemove = name;
  }

  public closeDeleteDialog(): void {
    this.showDeleteDialog = false;
  }

  public async removeView(): Promise<void> {
    if (this.$route.name && this.viewToRemove) {
      await deleteTableViews(this.viewToRemove, this.$route.name).then((name) => {
        this.curentViews = this.curentViews.filter((e) => e.name !== this.viewToRemove);
        if (name) {
          const index = this.curentViews.findIndex((e) => e.name === name);
          this.curentViews[index].default = true;
          this.selectedView = this.curentViews[index].name;
          this.setSelected(this.selectedView);
        }
        this.showDeleteDialog = false;
      });
    }
  }

  public async handleSubmit(): Promise<void> {
    this.curentViews = this.curentViews.map((e) => {
      e.selected = false;
      return e;
    });
    const name = typeof this.viewName === 'string' ? this.viewName : this.viewName.name;
    const view = this.curentViews.find((e) => e.name === name);
    if (!view) {
      await this.addView(name);
    } else {
      await this.updateView(name);
    }
    this.selectedView = this.curentViews.find((e) => e.selected)?.name || null;
    (this.$refs.form as Vue & { reset: () => boolean }).reset();
    this.submit();
  }

  private async addView(name: string): Promise<void> {
    this.loadingAddUpdateButton = true;
    await addTableViews({
      name,
      fields: this.selectedFields.join(';'),
      type: this.$route.name || '',
      default: this.curentViews.length === 0,
    }).then((status) => {
      if (status === 200) {
        this.curentViews.push({
          name,
          fields: this.selectedFields,
          selected: true,
          default: this.curentViews.length === 0,
        });
      } else {
        this.showSnackbar = !this.showSnackbar;
      }
      this.loadingAddUpdateButton = false;
    });
  }

  private async updateView(name: string): Promise<void> {
    this.loadingAddUpdateButton = true;
    const index = this.curentViews.findIndex((e) => e.name === name);
    if (index !== -1) {
      await updateFieldsTableViews({
        name: this.curentViews[index].name,
        type: this.$route.name || '',
        fields: this.selectedFields.join(';'),
      }).then((status) => {
        if (status === 200) {
          this.curentViews.splice(index, 1, {
            name: this.curentViews[index].name,
            fields: this.selectedFields,
            selected: true,
            default: this.curentViews[index].default,
          });
        } else {
          this.showSnackbar = !this.showSnackbar;
        }
        this.loadingAddUpdateButton = false;
      });
    }
  }

  public close(): void {
    this.dialog = false;
  }

  @Emit('updateViews')
  submit(): View[] {
    return this.curentViews;
  }
}
