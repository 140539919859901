









import { Component, Vue, Inject } from 'vue-property-decorator';
import User from '@/models/User';
import { PermissionsRouter } from '@/interfaces';
import UIPermissions from '@/models/UIPermissions';
import hasPermission from '@/helpers/hasPermission';

@Component
export default class Statistics extends Vue {
  @Inject() user!: User;

  public navigatorPerPermissions: PermissionsRouter[] = [
    {
      name: 'statistics.vendor',
      permissions: [UIPermissions.VENDOR_PART_STATUS],
    },
    {
      name: 'statistics.endOfLife',
      permissions: [UIPermissions.ESTIMATED_END_OF_LIFE],
    },
    {
      name: 'statistics.customerProject',
      permissions: [UIPermissions.CUSTOMER_PROJECT],
    },
  ];

  async created(): Promise<void> {
    this.navigate();
  }

  public navigate(): void {
    if (this.$route.name === 'statistics') {
      const route = this.navigatorPerPermissions.find(
        (e) => hasPermission(this.user.permissions, e.permissions)
      );
      if (route) {
        this.$router.push({ name: route.name });
      }
    }
  }
}
