
















































import { Component, PropSync, Vue, Emit, Prop } from 'vue-property-decorator';
import { Dictionary } from 'lodash';
import SimpleSnackbar from '@/components/Snackbar/SimpleSnackbar.vue';
import DialogConfirm from '@/components/Dialog/DialogConfirm.vue';
import { Export, ExportType } from '@/models/Export';

@Component({
  components: {
    SimpleSnackbar,
    DialogConfirm,
  },
})
export default class DownloadDialog extends Vue {
  @PropSync('loading', { default: false }) exportLoading!: boolean;

  @PropSync('disabledExport', { default: false }) disabled!: boolean;

  @Prop({ default: false }) enableXML!: boolean;

  @Prop({ default: false }) enableAll!: boolean;

  get query(): Dictionary<string | (string | null)[]> {
    const { query } = this.$route;
    delete query.Precise;
    return query;
  }

  get showFilters(): boolean {
    return Object.keys(this.query).length > 0;
  }

  get fileFormats(): string[] {
    if (this.enableAll) {
      return [ExportType.CSV, ExportType.XSLX, ExportType.ALL];
    }
    if (this.enableXML) {
      return [ExportType.CSV, ExportType.XSLX, ExportType.XML];
    }

    return [ExportType.CSV, ExportType.XSLX];
  }

  public dialogOpen = false;

  public radioValue = ExportType.CSV;

  public close(): void {
    this.dialogOpen = false;
  }

  @Emit('exportTable')
  public exportTable(): Export {
    return this.radioValue;
  }
}
