























import { Component, Vue } from 'vue-property-decorator';
import _, { Dictionary } from 'lodash';
import TableComponent from '@/components/Table/TableComponent.vue';
import SearchComponent from '@/components/Declaration/SearchComponent.vue';
import { createQuery } from '@/helpers/URLFilters';
import { Filter, View, FetchedData, Sorting, IExport, IFilterEmit } from '@/interfaces';
import { declarationFilters } from '@/configuration';
import fetchDeclaration from '@/api/declaration';
import { refreshToken, cancelAllRequests } from '@/api/axios-base';
import eventBus from '@/eventBus';
import Filters from '@/models/Filters';
import exportData from '@/helpers/exportData';

@Component({
  components: {
    SearchComponent,
    TableComponent,
  },
})
export default class DeclarationGeneral extends Vue {
  public subFilters: Filter[] = [];

  public mainFilters: Filter[] = [];

  private recordsPerPage = parseInt(localStorage.getItem('recordsPerPage') ?? '15', 10);

  public itemsRange: number[] = this.defaultRange;

  public data: FetchedData = {
    itemsLength: 0,
    items: [],
    views: [],
  };

  private headersOnly = true;

  private countingLength = true;

  public isLoadingNewRecords = true;

  private query: Dictionary<string | (string | null)[]> = {};

  private sorting: Sorting = {
    name: '',
    order: 0,
  };

  public defaultView: View = {
    name: 'Default',
    fields: ['Customer_Part_Number', 'Part_Number', 'Declaration', 'Specification', 'Substance', 'CAS_Number', 'Result_PPM'],
    default: false,
    selected: true,
  };

  public notSortable: string[] = ['all'];

  public disableFilterFields: string[] = [
    Filters.SUBSTANCE,
    Filters.CAS_NUMBER,
    Filters.RESULT_PPM,
  ];

  public isExportLoading = false;

  public isExportDisabled = true;

  get defaultRange(): number[] {
    return [0, this.recordsPerPage];
  }

  async mounted(): Promise<void> {
    this.getURLParams();
    this.headersOnly = !(!!this.subFilters.length || !!this.mainFilters.length);
    await this.fetchData();
    this.headersOnly = false;
    this.countingLength = true;
    this.isLoadingNewRecords = false;
    eventBus.$on('resetForm', async () => {
      if (this.subFilters.length || this.mainFilters.length) {
        this.isLoadingNewRecords = true;
        this.subFilters = [];
        this.mainFilters = [];
        this.updateURL();
        this.itemsRange = this.defaultRange;
        this.countingLength = true;
        await this.fetchData();
        this.isLoadingNewRecords = false;
      }
    });
  }

  public async fetchData(): Promise<void> {
    this.isExportDisabled = true;
    const fetchedData = await fetchDeclaration({
      lowerThreshold: this.itemsRange[0],
      upperThreshold: this.itemsRange[1],
      filters: this.query,
      sortingName: this.sorting.name,
      sortingOrder: this.sorting.order,
      headersOnly: this.headersOnly,
      countingLength: this.countingLength,
    });
    if (this.countingLength) {
      this.data = fetchedData;
    } else {
      this.data.items = fetchedData.items;
      this.data.views = fetchedData.views;
    }
    this.countingLength = false;
    this.isExportDisabled = false;
  }

  public async exportTable(exportDataParams: IExport): Promise<void> {
    this.isExportLoading = true;
    const fileName = `DeclarationTable_${new Date().getFullYear()}_${new Date().getMonth()}_${new Date().getDate()}`;
    const { items } = this.data;
    exportData(exportDataParams.format, items, fileName, 'DECLARATION', exportDataParams.headers);
    this.isExportLoading = false;
  }

  public filterByAttributes(): void {
    this.updateURL();
  }

  public async filterByMainFilters(data: IFilterEmit): Promise<void> {
    this.mainFilters = data.filters;
    if (data.doFiltering) {
      this.isLoadingNewRecords = true;
      this.updateURL();
      this.itemsRange = this.defaultRange;
      this.countingLength = true;
      await this.fetchData();
      this.isLoadingNewRecords = false;
    }
  }

  public async filterBySubFilters(data: IFilterEmit): Promise<void> {
    this.subFilters = data.filters;
    if (data.doFiltering) {
      this.isLoadingNewRecords = true;
      this.updateURL();
      this.itemsRange = this.defaultRange;
      this.countingLength = true;
      await this.fetchData();
      this.isLoadingNewRecords = false;
    }
  }

  public async changeSorting(sorting: Sorting): Promise<void> {
    this.isLoadingNewRecords = true;
    this.sorting = sorting;
    cancelAllRequests();
    refreshToken();
    await this.fetchData();
    this.isLoadingNewRecords = false;
  }

  public async changeItemsRange(range: number[]): Promise<void> {
    this.isLoadingNewRecords = true;
    this.countingLength = this.itemsRange[1] - this.itemsRange[0] !== range[1] - range[0];
    this.recordsPerPage = range[1] - range[0];
    localStorage.setItem('recordsPerPage', this.recordsPerPage.toString());
    this.itemsRange = range;
    await this.fetchData();
    this.isLoadingNewRecords = false;
  }

  public updateURL(): void {
    this.query = this.$route.query;
    const mainFiltersQuery: Dictionary<string | (string | null)[]> = createQuery(this.mainFilters);
    const subFiltersQuery: Dictionary<string | (string | null)[]> = createQuery(this.subFilters);
    const newQuery: Dictionary<string | (string | null)[]> = Object.assign(mainFiltersQuery, {
      ...subFiltersQuery,
    });
    if (!_.isEqual(JSON.parse(JSON.stringify(this.query)), newQuery)) {
      this.$router.replace({ query: newQuery });
    }
    this.query = newQuery;
    this.getURLParams(newQuery);
  }

  public getURLParams(query: _.Dictionary<string | (string | null)[]> | null = null): void {
    const filters = declarationFilters.map((e) => e.filter.value);
    this.query = query || this.$route.query;
    Object.entries(this.query).forEach(([key, value]) => {
      if (filters.includes(key)) {
        this.mainFilters.push({
          text: key.replaceAll('_', ' '),
          search: value.toString(),
          value: key,
        });
      } else {
        this.subFilters.push({
          text: key.replaceAll('_', ' '),
          search: value.toString(),
          value: key,
        });
      }
    });
  }
}
