import getAxiosBase, { cancelSource } from '@/api/axios-base';
import { FetchedData, DeclarationQuery } from '@/interfaces';

export default async function fetchDeclaration(data: DeclarationQuery): Promise<FetchedData> {
  const dashboard: FetchedData = await getAxiosBase()
    .post('/notefied/declaration/fetch/', { ...data }, { signal: cancelSource })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
      return {};
    });
  return dashboard;
}
