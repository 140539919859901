import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import { themeDefault } from '@/configuration';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        ...themeDefault,
      },
    },
    options: { customProperties: true },
  },
});
