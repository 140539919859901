


























import { Component, Vue, Prop } from 'vue-property-decorator';
import { fetchSchematicPart } from '@/api/drilldown';
import fetchFile from '@/api/file';
import ImagePreview from '@/components/ImagePreview.vue';
import { FetchedImages, ImageToDownload } from '@/interfaces';
import eventBus from '@/eventBus';

@Component({
  components: {
    ImagePreview,
  },
})
export default class SchematicPart extends Vue {
  @Prop() id!: string;

  public images: FetchedImages[] = [];

  public isLoading = true;

  public fetchingImage = false;

  public canDownload = false;

  async created(): Promise<void> {
    const data = await fetchSchematicPart(this.id);
    this.images = data.images;
    this.canDownload = data.download;
    this.isLoading = false;
  }

  public async downloadFiles(image: ImageToDownload): Promise<void> {
    this.fetchingImage = true;
    const response = await fetchFile({
      fileFolder: image.name,
      fileType: image.type,
      endpoint: 'schematic-part',
      id: this.id,
    });
    if (!response) {
      eventBus.$emit('generalError', {
        type: 'error',
        message: this.$t('error.file').toString(),
      });
    }
    this.fetchingImage = false;
  }
}
