import getAxiosBase, { cancelSource } from '@/api/axios-base';
import downloadFile from '@/helpers/downloadFile';

export default async function exportReport(url: string): Promise<boolean> {
  const success: boolean = await getAxiosBase()
    .get(
      `/notefied/report/${url}`,
      { signal: cancelSource, responseType: 'arraybuffer' }
    )
    .then(async (response) => {
      if (response.status === 200) {
        const file = new Blob([response.data], {
          type: response.headers['content-type'],
        });
        const content = response.headers['content-disposition'];
        downloadFile(file, content.split('filename=')[1]);
        return true;
      }
      return false;
    })
    .catch((error) => {
      console.error(error);
      return false;
    });

  return success;
}
