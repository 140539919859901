




































import { Component, Mixins, Inject } from 'vue-property-decorator';
import TableComponent from '@/components/Table/TableComponent.vue';
import TableMixin from '@/mixins/tableMixins';
import { ItemsSet, DetailItem, View } from '@/interfaces';
import VendorInfo from '@/components/ProductDetail/VendorTab/VendorInfo.vue';
import UIPermissions from '@/models/UIPermissions';
import { fetchVendorFiles, fetchVendorInfo, fetchVendorName } from '@/api/drilldown';
import User from '@/models/User';

@Component({
  components: {
    TableComponent,
    VendorInfo,
  },
})
export default class Vendor extends Mixins(TableMixin) {
  @Inject() user!: User;

  public defaultView: View = {
    name: 'Default',
    fields: ['File_Name', 'Folder_Type'],
    default: false,
    selected: true,
  };

  public vendorName = '';

  private vendorData: ItemsSet[] = [];

  public isLoadingInfo = true;

  get vendorInformationAllowed(): boolean {
    return this.user.permissions.includes(UIPermissions.VENDOR_INFORMATION);
  }

  get vendorDocumentsAllowed(): boolean {
    return this.user.permissions.includes(UIPermissions.VENDOR_DOCUMENTS);
  }

  get mappedData(): ItemsSet[] {
    const mappedData: ItemsSet[] = this.vendorData.map((e) => {
      const item: DetailItem[] = [];
      e.item.forEach((detailItem) => {
        item.push({ ...detailItem, selected: null });
      });
      const element = { name: e.name, item };
      return element;
    });
    return mappedData;
  }

  async mounted(): Promise<void> {
    this.getURLParams();
    this.isExportDisabled = true;
    const promiseData = fetchVendorFiles({
      id: this.id,
      filters: this.query,
      lowerThreshold: this.itemsRange[0],
      upperThreshold: this.itemsRange[1],
      sortingName: this.sorting.name,
      sortingOrder: this.sorting.order,
    });
    const promiseInfo = fetchVendorInfo(this.id);
    const promiseName = fetchVendorName(this.id);
    await Promise.all([promiseData, promiseInfo, promiseName]).then((result) => {
      [this.data, this.vendorData, this.vendorName] = result;
    });
    this.isExportDisabled = false;
    this.isLoadingNewRecords = false;
    this.isLoadingInfo = false;
    this.emitListener();
  }

  public async fetchData(): Promise<void> {
    this.isExportDisabled = true;
    this.data = await fetchVendorFiles({
      id: this.id,
      filters: this.query,
      lowerThreshold: this.itemsRange[0],
      upperThreshold: this.itemsRange[1],
      sortingName: this.sorting.name,
      sortingOrder: this.sorting.order,
    });
    this.isExportDisabled = false;
  }
}
