


































































import { Component, Vue, PropSync, Prop, Emit } from 'vue-property-decorator';
import { Dictionary } from 'lodash';
import { Header, Filter, IFilterEmit } from '@/interfaces';
import { filterWithOptions, disableFilters } from '@/configuration';
import eventBus from '@/eventBus';

@Component
export default class FilterComponent extends Vue {
  @PropSync('parameters', { required: true }) selectedParameters!: string[];

  @PropSync('headers', { required: true }) tableHeaders!: Header[];

  @PropSync('filters', { required: true }) filtersFromUrl!: Filter[];

  @Prop({ type: Array, default: () => [] }) disableFilterFields!: string[];

  private queryParameters: string[] = [];

  private sessionStorage = window.sessionStorage;

  private fieldsToDisplay: Filter[] = [];

  get availableParameters(): Header[] {
    return this.tableHeaders.filter(
      (e) =>
        (this.selectedParameters.includes(e.value) || this.queryParameters.includes(e.value)) &&
        !disableFilters.includes(e.value)
    );
  }

  get currentQuery(): Dictionary<string | (string | null)[]> {
    return this.$route.query;
  }

  get filtersWithOptions(): Filter[] {
    return filterWithOptions;
  }

  get searchFields(): Filter[] {
    const allFilters: Filter[] = [];
    this.updateFiltersByAttributes(this.$route.query);
    this.availableParameters.forEach((e) => {
      const subFilter = this.filtersFromUrl.find((element) => element.value === e.value);
      const search = subFilter ? subFilter.search : '';
      this.filtersWithOptions.forEach((filter) => {
        if (filter.value === e.value) {
          allFilters.push({
            text: filter.text,
            value: filter.value,
            options: filter.options,
            search,
          });
        }
      });
      allFilters.push({
        text: e.text,
        search,
        value: e.value,
      });
    });
    const difference = allFilters
      .filter((item) => !this.filtersWithOptions.every((e) => item.value !== e.value))
      .filter((filter) => !Object.prototype.hasOwnProperty.call(filter, 'options'));
    this.fieldsToDisplay = allFilters.filter((n) => !difference.includes(n));

    return this.fieldsToDisplay.filter((e) => !this.disableFilterFields.includes(e.value));
  }

  get disableSwitcher(): boolean {
    return !this.$route.name?.includes('item');
  }

  get showPrecisionSwitch(): boolean {
    return this.$route.name === 'main';
  }

  mounted(): void {
    eventBus.$on('removeAttribute', (value: string) => {
      this.filtersFromUrl.forEach((e) => {
        if (e.value === value) {
          e.search = '';
        }
      });
    });
    eventBus.$on('removeAllAttributes', (values: string[]) => {
      values.forEach((value) => {
        this.filtersFromUrl.forEach((e) => {
          if (e.value === value) {
            e.search = '';
          }
        });
      });
    });
    this.updateFiltersByAttributes(this.$route.query);
  }

  private updateFiltersByAttributes(query: Dictionary<string | (string | null)[]>): void {
    this.queryParameters = Object.keys(query);
  }

  public async resetForm(): Promise<void> {
    this.filtersFromUrl = [];
    eventBus.$emit('resetForm');
  }

  public removeHeader(header: string): void {
    const headers = this.availableParameters.map((e) => e.value);
    const index = headers.indexOf(header);
    headers.splice(index, 1);
    const indexQuery = this.queryParameters.indexOf(header);
    this.queryParameters.splice(indexQuery, 1);
    this.establishQuery();
    eventBus.$emit('updateHeaders', headers);
    eventBus.$emit('updateSubFilters', headers);
  }

  private async establishQuery(): Promise<void> {
    const query: Dictionary<string | (string | null)[]> = {};
    this.queryParameters.forEach((e) => {
      const value = this.sessionStorage.getItem(e);
      query[e] = value || '';
    });
    await this.$router.replace({ query });
  }

  @Emit('submit')
  public handleSubmit(doFiltering: boolean): IFilterEmit {
    return { filters: this.searchFields, doFiltering };
  }
}
