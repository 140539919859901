

















































import { Component, Vue, PropSync, Emit, Inject, Watch } from 'vue-property-decorator';
import { declarationFilters } from '@/configuration';
import { FetchedCustomerData, Filter, MainFilter, IFilterEmit } from '@/interfaces';
import UIPermissions from '@/models/UIPermissions';
import eventBus from '@/eventBus';
import User from '@/models/User';

@Component
export default class SearchComponent extends Vue {
  @Inject() user!: User;

  @PropSync('filters', { required: true }) filtersFromUrl!: Filter[];

  @Watch('filters')
  onFiltersChange(): void {
    this.assignMainFilters();
  }

  public filtersForDashboard: MainFilter[] = [];

  public widgetData: FetchedCustomerData[] = [];

  public riskData: FetchedCustomerData[] = [];

  get filteredFiltersForDashboard(): MainFilter[] {
    return this.filtersForDashboard.filter(
      (e) => this.user.permissions.includes(e.permission) || e.permission === UIPermissions.ALLOWED
    );
  }

  created(): void {
    this.assignMainFilters();
  }

  async mounted(): Promise<void> {
    this.assignMainFilters();
    eventBus.$on('resetForm', () => {
      this.filtersForDashboard.forEach((e) => {
        e.filter.search = '';
      });
    });
  }

  public assignMainFilters(): void {
    declarationFilters.forEach((e, index) => {
      const selectedValue = this.filtersFromUrl.find((element) => element.value === e.filter.value);
      this.filtersForDashboard.splice(index, 1, {
        autocomplete: e.autocomplete,
        permission: e.permission,
        options: [],
        filter: {
          text: e.filter.text,
          value: e.filter.value,
          search: selectedValue ? selectedValue.search : '',
        },
      });
    });
  }

  @Emit('submit')
  public async submit(doFiltering: boolean): Promise<IFilterEmit> {
    return { filters: this.filtersForDashboard.map((e) => e.filter), doFiltering };
  }
}
