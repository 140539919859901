import axios, { AxiosError, AxiosInstance } from 'axios';
import eventBus from '@/eventBus';

const { BACKEND } = window.KevTeebTsa;

const baseURL = BACKEND.URL;

let controller = new AbortController();
// eslint-disable-next-line import/no-mutable-exports
export let cancelSource = controller.signal;
export const refreshToken = (): void => {
  controller = new AbortController();
  cancelSource = controller.signal;
};
export const cancelAllRequests = (): void => {
  controller.abort();
};

/**
 *
 * @todo Add sentry for catch
 */
export default function getAxiosBase(): AxiosInstance {
  const axiosBase: AxiosInstance = axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  });

  axiosBase.interceptors.request.use((config) => {
    const newConfig = config;
    const token = localStorage.getItem('token');
    if (token !== 'undefined' && token) {
      newConfig.data = {
        ...config.data,
      };
      if (newConfig.headers) {
        newConfig.headers.Authorization = `Bearer ${token}`;
      }
    }
    return newConfig;
  });

  const responseInterceptor = axiosBase.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
      const errorResponse = error.response;
      if (errorResponse && (errorResponse.status === 401 || errorResponse.status === 502)) {
        axiosBase.interceptors.response.eject(responseInterceptor);
        return axiosBase
          .post('/notefied/token/refresh/', {
            refresh: localStorage.getItem('refresh'),
          })
          .then((response) => {
            localStorage.setItem('token', response.data.access);
            const parseredData = JSON.parse(errorResponse.config.data);
            const { url, responseType, params } = errorResponse.config;
            switch (errorResponse.config.method) {
              case 'post':
                return axiosBase.post(url ?? '', parseredData, { responseType });
              case 'put':
                return axiosBase.put(url ?? '', parseredData);
              case 'delete':
                return axiosBase.delete(url ?? '', { data: parseredData });
              default:
                return axiosBase.get(url ?? '', { params, responseType });
            }
          })
          .catch((err) => {
            if (err.response.status === 502) {
              eventBus.$emit('generalError', {
                type: 'error',
                message: 'Timeout! Please try again later.',
                timeout: 20000,
              });
            } else {
              const message = err.response.data.messages
                ? err.response.data.messages
                : err.response.data.errors;
              console.log(err.response.data);
              eventBus.$emit('generalError', {
                type: 'error',
                message: message.toString(),
                timeout: 20000,
              });
            }
            return err.response;
          })
          .finally(getAxiosBase);
      }
      if (errorResponse && errorResponse.status === 404 && errorResponse.data.code === 'item_not_found') {
        return errorResponse;
      }
      if (errorResponse && errorResponse.status === 404 && errorResponse.data.code === 'declaration_not_found') {
        return errorResponse;
      }
      if (errorResponse) {
        eventBus.$emit('generalError', {
          type: 'error',
          message: 'Internal Error! Try again later.',
          timeout: 20000,
        });
      }
      return errorResponse;
    }
  );

  return axiosBase;
}
