
































import { Component, Vue, PropSync } from 'vue-property-decorator';
import { Fragment } from 'vue-frag';
import { Header, FetchedItem } from '@/interfaces';

@Component({
  components: {
    Fragment,
  },
})
export default class CustomTableGroup extends Vue {
  @PropSync('items', { required: true }) groupData!: FetchedItem[];

  @PropSync('headers', { required: true }) headerData!: Header[];

  @PropSync('group', { required: true }) groupId!: string;

  public isOpen = true;

  get firstRow(): FetchedItem {
    return this.groupData[0];
  }

  get subRows(): FetchedItem[] {
    const subRows = Array.from(this.groupData);
    subRows.shift();
    return this.isOpen ? subRows : [];
  }
}
