



import { Component, Vue, PropSync } from 'vue-property-decorator';
import VueApexCharts from 'vue-apexcharts';
import { ApexOptions } from 'apexcharts';
import { StatisticDataMain, Series, SeriesFormat } from '@/interfaces';

@Component({
  components: {
    VueApexCharts,
  },
})
export default class BarChart extends Vue {
  @PropSync('chartData', { required: true }) data!: StatisticDataMain;

  public series: Series[] = [];

  public colors: string[] = [];

  public created(): void {
    const data: SeriesFormat[] = [];
    this.data.status.forEach((element) => {
      data.push({
        x: element.name,
        y: element.quantity,
      });
      this.colors.push(element.color);
    });
    this.series.push({
      name: this.$t('statistic.quantity').toString(),
      data,
    });
  }

  get labelColor(): string {
    return this.$vuetify.theme.dark ? 'white' : 'black';
  }

  get themeValue(): string {
    return this.$vuetify.theme.dark ? 'dark' : 'light';
  }

  get chartOptions(): ApexOptions {
    return {
      colors: this.colors,
      chart: {
        animations: {
          enabled: false,
        },
        toolbar: {
          tools: {
            download: false,
          },
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          distributed: true,
        },
      },
      xaxis: {
        tooltip: {
          enabled: false,
        },
        labels: {
          show: true,
          style: {
            colors: this.labelColor,
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: this.labelColor,
          },
        },
      },
      legend: {
        show: true,
        position: 'top',
        labels: {
          useSeriesColors: true,
        },
      },
      tooltip: {
        theme: this.themeValue,
        x: {
          show: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
    };
  }
}
