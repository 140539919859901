


































import { Component, Vue, Prop, Watch, Inject } from 'vue-property-decorator';
import { fetchPartNumberAndDeclaration } from '@/api/drilldown';
import User from '@/models/User';

@Component
export default class OverallCompliance extends Vue {
  @Inject() user!: User;

  @Prop() idDeclaration!: string;

  public notFound = false;

  @Watch('idDeclaration')
  public onIdChange(newVal: string): void {
    fetchPartNumberAndDeclaration(newVal).then((value) => {
      this.notFound = false;
      if (value) {
        this.partNumber = `${value.itemName}_${value.declaration}_${value.specification}`;
        this.itemId = value.itemId;
        this.itemName = value.itemName;
        this.specification = value.specification;
        this.declaration = value.declaration;
        this.calculatedCompliance = value.calculatedCompliance;
      } else {
        this.notFound = true;
      }
    });
  }

  public itemId = 0;

  public itemName = '';

  public specification = '';

  public partNumber = '';

  public declaration = '';

  public calculatedCompliance = '';

  async created(): Promise<void> {
    fetchPartNumberAndDeclaration(this.idDeclaration).then((value) => {
      this.notFound = false;
      if (value) {
        this.partNumber = `${value.itemName}_${value.declaration}_${value.specification}`;
        this.itemId = value.itemId;
        this.itemName = value.itemName;
        this.declaration = value.declaration;
        this.specification = value.specification;
        this.calculatedCompliance = value.calculatedCompliance;
      } else {
        this.notFound = true;
      }
    });
  }
}
