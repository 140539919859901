


















































import { Component, Vue, PropSync, Emit, Inject, Watch } from 'vue-property-decorator';
import { mainFilters, customerPartStatus } from '@/configuration';
import { FetchedCustomerData, Filter, MainFilter, IFilterEmit } from '@/interfaces';
import { fetchComponents, fetchCustomerPartNumber } from '@/api/dashboard';
import CustomerPartNumber from '@/components/Table/CustomerPartNumber.vue';
import Filters from '@/models/Filters';
import UIPermissions from '@/models/UIPermissions';
import eventBus from '@/eventBus';
import User from '@/models/User';

@Component({
  components: {
    CustomerPartNumber,
  },
})
export default class SearchComponent extends Vue {
  @Inject() user!: User;

  @PropSync('filters', { required: true }) filtersFromUrl!: Filter[];

  @Watch('filters')
  onFiltersChange(): void {
    this.assignMainFilters(this.components);
  }

  private components: string[] = [];

  public filtersForDashboard: MainFilter[] = [];

  public status = false;

  private customerPartNumber = '';

  public widgetData: FetchedCustomerData[] = [];

  public riskData: FetchedCustomerData[] = [];

  get filteredFiltersForDashboard(): MainFilter[] {
    return this.filtersForDashboard.filter(
      (e) => this.user.permissions.includes(e.permission) || e.permission === UIPermissions.ALLOWED
    );
  }

  created(): void {
    this.assignMainFilters([]);
  }

  async mounted(): Promise<void> {
    this.components = await fetchComponents();
    if (
      this.$route.query.Customer_Part_Number &&
      typeof this.$route.query.Customer_Part_Number === 'string'
    ) {
      this.customerPartNumber = this.$route.query.Customer_Part_Number;
      this.fetchData();
    }
    this.assignMainFilters(this.components);
    eventBus.$on('removeAttribute', (value: string) => {
      this.filtersForDashboard.forEach((e) => {
        if (e.filter.value === value) {
          e.filter.search = '';
        }
      });
      this.submit(true);
    });
    eventBus.$on('removeAllAttributes', (values: string[]) => {
      values.forEach((value) => {
        this.filtersForDashboard.forEach((e) => {
          if (e.filter.value === value) {
            e.filter.search = '';
          }
        });
      });
      this.submit(true);
    });
    eventBus.$on('resetForm', () => {
      this.filtersForDashboard.forEach((e) => {
        e.filter.search = '';
      });
      this.status = false;
    });
  }

  public assignMainFilters(components: string[]): void {
    mainFilters.forEach((e, index) => {
      let options: string[] = [];
      if (e.filter.value === Filters.PART_CLASS) {
        options = components;
      }
      if (e.filter.value === Filters.CUSTOMER_PART_STATUS) {
        options = customerPartStatus;
      }
      const selectedValue = this.filtersFromUrl.find((element) => element.value === e.filter.value);

      this.filtersForDashboard.splice(index, 1, {
        autocomplete: e.autocomplete,
        permission: e.permission,
        options,
        filter: {
          text: e.filter.text,
          value: e.filter.value,
          search: selectedValue ? selectedValue.search : '',
        },
      });
    });
  }

  public async fetchData(): Promise<void> {
    const fetchedCustomerData = await fetchCustomerPartNumber(this.customerPartNumber);
    /* eslint-disable dot-notation */
    this.widgetData = [
      { name: 'Customer Part Number', value: fetchedCustomerData['Customer_Part'] },
      {
        name: 'Max Estimated End of Life',
        value: fetchedCustomerData['Max_Estimated_End_of_Life'],
      },
      { name: 'Active / Total', value: fetchedCustomerData['Customer_PN_(Active/Total)'] },
      {
        name: 'Percent (Active / Total)',
        value: fetchedCustomerData['Customer_PN_Percentage_(Active/Total)'],
      },
    ];

    this.riskData = [
      {
        name: 'Customer Part Number Risk Status',
        value: fetchedCustomerData['Customer_PN_Part_Risk_Status'],
      },
      {
        name: 'Customer Part Number Risk Status (1 year)',
        value: fetchedCustomerData['Customer_PN_Part_Risk_Status_+1_year'],
      },
      {
        name: 'Customer Part Number Risk Status (2 year)',
        value: fetchedCustomerData['Customer_PN_Part_Risk_Status_+2_year'],
      },
      {
        name: 'Customer Part Number Risk Status (3 year)',
        value: fetchedCustomerData['Customer_PN_Part_Risk_Status_+3_year'],
      },
      {
        name: 'Customer Part Number Risk Status (4 year)',
        value: fetchedCustomerData['Customer_PN_Part_Risk_Status_+4_year'],
      },
    ];

    if (this.customerPartNumber) {
      this.status = this.customerPartNumber === fetchedCustomerData.Customer_Part;
    } else {
      this.status = false;
    }
  }

  @Emit('submit')
  public async submit(doFiltering: boolean): Promise<IFilterEmit> {
    const customerPartNumberNew = this.filtersForDashboard.find(
      (e) => e.filter.value === Filters.CUSTOMER_PART_NUMBER
    )?.filter.search;
    if (customerPartNumberNew) {
      this.customerPartNumber = customerPartNumberNew;
      this.fetchData();
    } else {
      this.status = false;
    }
    return { filters: this.filtersForDashboard.map((e) => e.filter), doFiltering };
  }
}
