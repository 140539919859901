



























































import { Component, Vue, Inject } from 'vue-property-decorator';
import User from '@/models/User';
import { emailAddress, required, codeValidation } from '@/validation/rules';
import { Auth, AuthResponse } from '@/models/Auth';

@Component
export default class LoginForm extends Vue {
  @Inject('user') user!: User;

  public hidePassword = true;

  private email = '';

  private password = '';

  public wrongCred = false;

  public loading = false;

  public code = '';

  public errorMFA = false;

  public mfa = false;

  public emailRule = emailAddress;

  public requiredRule = required;

  public codeRule = codeValidation;

  public valid = false;

  public disabledCredentialsFields = false;

  public async loginUser(): Promise<void> {
    const { redirectTo } = this.$route.query;
    this.loading = true;
    if (!this.mfa) {
      const credentials: Auth = await this.user.login(this.email, this.password);
      if (credentials === AuthResponse.YES) {
        if (redirectTo && typeof redirectTo === 'string') {
          this.$router.push({ path: redirectTo });
        } else {
          this.$router.push({ name: 'main' });
        }
      } else if (credentials === AuthResponse.PARTIALLY) {
        this.hidePassword = true;
        this.mfa = true;
        this.disabledCredentialsFields = true;
        this.wrongCred = false;
        this.loading = false;
      } else {
        this.wrongCred = true;
        this.loading = false;
      }
    } else {
      const credentials: Auth = await this.user.multifactorLogin(
        this.code,
        this.email,
        this.password
      );
      if (credentials === AuthResponse.YES) {
        if (redirectTo && typeof redirectTo === 'string') {
          this.$router.push({ path: redirectTo });
        } else {
          this.$router.push({ name: 'main' });
        }
      } else if (credentials === AuthResponse.PARTIALLY) {
        this.errorMFA = true;
        this.loading = false;
      } else {
        this.$router.push({ name: 'login' });
      }
    }
  }

  public onChangeInput(): void {
    this.errorMFA = false;
  }
}
