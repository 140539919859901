





































import { Component, Vue, Inject, Prop } from 'vue-property-decorator';
import UIPermissions from '@/models/UIPermissions';
import { Route } from '@/interfaces';
import User from '@/models/User';

@Component
export default class ProductDetailNavigator extends Vue {
  @Prop() partNumber!: string;

  @Prop() routes!: Route[];

  @Inject() user!: User;

  public menuOpened: boolean | null = false;

  get routerName(): string {
    const routesWithSubroutes = this.routes.filter((e) => !!e.subRoute);
    const routeWithSubroute = routesWithSubroutes.find((e) =>
      e.subRoute?.find((element) => this.$route.name?.includes(element.to)));
    if (routeWithSubroute) {
      return `${routeWithSubroute.label} | ${
        routeWithSubroute.subRoute
          ?.find((e) => this.$route.name?.includes(e.to))
          ?.label.toString() || ''
      }`;
    }
    const route = this.routes.find((e) => this.$route.name?.includes(e.to));
    if (route) {
      return route.label.toString();
    }
    return '';
  }

  get filteredRoutes(): Route[] {
    return this.routes.filter(
      (e) =>
        this.user.permissions.some((element) => e.name.includes(element)) ||
        e.name.includes(UIPermissions.ALLOWED)
    );
  }

  get filteredSubRoutes(): Route[] {
    const routes: Route[] = [];
    this.filteredRoutes.forEach((route) => {
      if (!route.subRoute?.length) {
        routes.push(route);
      } else {
        const subRoutes = route.subRoute.filter(
          (e) =>
            this.user.permissions.some((element) => e.name.includes(element)) ||
            e.name.includes(UIPermissions.ALLOWED)
        );
        routes.push({
          to: route.to,
          label: route.label,
          name: route.name,
          subRoute: subRoutes,
        });
      }
    });
    return routes;
  }

  get routesBasedOnComponentType(): Route[] {
    if (!this.partNumber.startsWith('5')) {
      return this.filteredSubRoutes.filter((e) => !e.name.includes(UIPermissions.BOM_STATISTIC));
    }
    return this.filteredSubRoutes;
  }

  public openMenu(): void {
    this.menuOpened = true;
  }

  public closeMenu(): void {
    this.menuOpened = null;
  }
}
