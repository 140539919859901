var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"500","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onMenu = ref.on;
var attrsMenu = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":_vm.disabled}},'v-btn',attrsMenu,false),Object.assign({}, onMenu, onTooltip)),[_c('v-icon',[_vm._v("mdi-arrow-down-drop-circle")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.header')))])])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-container',{staticClass:"header"},[_c('v-card-title',{staticClass:"pb-6 justify-center"},[_vm._v(" "+_vm._s(_vm.$t('table.headersView.title')))]),_c('v-container',{staticClass:"d-flex justify-end py-0"},[(_vm.order === 1)?_c('v-icon',[_vm._v("mdi-arrow-down-thin")]):(_vm.order === 2)?_c('v-icon',[_vm._v("mdi-arrow-up-thin")]):_vm._e(),_c('v-btn',{staticClass:"text-button py-0",attrs:{"ripple":false,"plain":"","header-sort":""},on:{"click":_vm.ordering}},[_vm._v("Sort")])],1),_c('v-text-field',{attrs:{"autofocus":"","placeholder":_vm.$t('table.headersDialog.label'),"dense":"","outlined":""},model:{value:(_vm.searchValue),callback:function ($$v) {_vm.searchValue=$$v},expression:"searchValue"}})],1),_c('v-list',{attrs:{"min-height":"62vh"}},[_c('v-list-item-group',{attrs:{"multiple":""},model:{value:(_vm.savedHeaders),callback:function ($$v) {_vm.savedHeaders=$$v},expression:"savedHeaders"}},[_vm._l((_vm.sortedHeaders),function(head){return [(!_vm.headersNotAllowed.includes(head.value))?_c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(head.text.toLowerCase().includes(_vm.casedSearchValue)),expression:"head.text.toLowerCase().includes(casedSearchValue)"}],key:head.text,attrs:{"value":head.value,"active-class":"primary--text text--accent-4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(head.text))])],1),_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"color":"primary accent-4"}})],1)]}}],null,true)}):_vm._e()]})],2)],1),_c('v-container',{staticClass:"footer"},[_c('v-card-actions',[_c('v-btn',{staticClass:"text-button py-0",attrs:{"ripple":false,"plain":"","header-sort":""},on:{"click":_vm.toggle}},[_vm._v("Toggle")]),_c('v-spacer'),_c('v-btn',{staticClass:"font-weight-bold",attrs:{"color":"primary","text":""},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$t('table.headersView.submit')))]),_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":_vm.cancel}},[_vm._v(_vm._s(_vm.$t('table.headersView.cancel')))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }