import Vue from 'vue';
import { createPinia, PiniaVuePlugin } from 'pinia';
import App from '@/App.vue';
import { languages, Localization } from '@/locale';
import router from '@/router';
import vuetify from '@/plugins/vuetify';
import i18n from '@/plugins/i18n';
import { user } from '@/models/User';
import '@/registerServiceWorker';
import { refreshToken, cancelAllRequests } from '@/api/axios-base';
import { AuthResponse } from '@/models/Auth';
import hasPermission from '@/helpers/hasPermission';
import historyStore from '@/stores/history';

Vue.config.productionTip = false;

Vue.use(PiniaVuePlugin);
const pinia = createPinia();

const localization = new Localization(languages, i18n);

router.beforeEach(async (to, from, next) => {
  cancelAllRequests();
  refreshToken();
  const access = await user.verify();
  const store = historyStore();
  store.$patch({ values: user.browsingItems });
  if (to.matched.some((record) => record.meta.requiresAuth) &&
    hasPermission(user.permissions, to.meta?.permissions)
  ) {
    if (access === AuthResponse.NO) {
      next({ name: 'login', query: { redirectTo: to.fullPath } });
    } else if (access === AuthResponse.PARTIALLY) {
      next({ name: 'multifactor' });
    } else if (to.meta !== undefined) {
      next();
    } else {
      next({ name: 'main' });
    }
  } else if (to.matched.some((record) => record.meta.requiresLogged) &&
    hasPermission(user.permissions, to.meta?.permissions)
  ) {
    if (access === AuthResponse.YES) {
      next({ name: 'main' });
    } else if (access === AuthResponse.PARTIALLY) {
      next({ name: 'multifactor' });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.requiresSecondLogged) &&
    hasPermission(user.permissions, to.meta?.permissions)
  ) {
    if (access === AuthResponse.YES) {
      next({ name: 'main' });
    } else if (access === AuthResponse.PARTIALLY) {
      next();
    } else {
      next({ name: 'login', query: { redirectTo: to.fullPath } });
    }
  } else if (hasPermission(user.permissions, to.meta?.permissions)) {
    next();
  } else {
    next({ name: 'main' });
  }
});

new Vue({
  router,
  vuetify,
  i18n,
  pinia,
  provide: {
    localization,
    user,
  },
  beforeCreate() {
    this.$root.$i18n.locale = localization.getLanguage();
  },
  render: (h) => h(App),
}).$mount('#app');
