













import { Component, Vue } from 'vue-property-decorator';
import { StatisticDataMain, StatisticUrlQuerySettings } from '@/interfaces';
import { fetchEstimatedChartsData } from '@/api/statistic';
import StatisticContainer from '@/components/Statistics/Containers/StatisticContainer.vue';
import { CUSTOMER_PART, ESTIMATED_END_OF_LIFE_RANGE } from '@/models/StatisticUrlQuery';

@Component({
  components: {
    StatisticContainer,
  },
})
export default class EstimatedEndOfLife extends Vue {
  public items: StatisticDataMain[] = [];

  public loading = true;

  public settings: StatisticUrlQuerySettings[][] = [[CUSTOMER_PART, ESTIMATED_END_OF_LIFE_RANGE]];

  public async created(): Promise<void> {
    this.items = await fetchEstimatedChartsData();
    this.loading = false;
  }
}
