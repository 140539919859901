






























































import { Component, Vue, Prop } from 'vue-property-decorator';
import BarChart from '@/components/Statistics/Containers/Charts/BarChart.vue';
import PieChart from '@/components/Statistics/Containers/Charts/PieChart.vue';
import PartTable from '@/components/Statistics/Containers/Tables/PartsTable.vue';
import { StatisticDataMain, StatisticUrlQuerySettings } from '@/interfaces';

@Component({
  components: {
    BarChart,
    PieChart,
    PartTable,
  },
})
export default class Statistic extends Vue {
  @Prop({ required: true }) data!: StatisticDataMain;

  @Prop({ required: true }) settings!: StatisticUrlQuerySettings[];

  @Prop({ default: '' }) incomeValue!: string;
}
