import getAxiosBase, { cancelSource } from '@/api/axios-base';
import {
  FetchedItemDetail,
  ItemsSet,
  DrilldownQuery,
  FetchedData,
  StatisticDataBOM,
  FetchedImagesData,
  FetchedThreeDFiles,
  FetchedThreeDImages,
  ItemInfoForCompliance,
  StatisticDataMain,
  IProductDetailInfo,
} from '@/interfaces';

export async function fetchGeneralInfo(id: string): Promise<FetchedItemDetail[]> {
  const generalInfo: FetchedItemDetail[] = await getAxiosBase()
    .get('/notefied/drilldown/info/general/', {
      params: {
        id,
      },
      signal: cancelSource,
    })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
      return [];
    });
  return generalInfo;
}

export async function fetchAttributes(id: string): Promise<ItemsSet[]> {
  const attributes: ItemsSet[] = await getAxiosBase()
    .get('/notefied/drilldown/info/attributes/', {
      params: {
        id,
      },
      signal: cancelSource,
    })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
      return [];
    });
  return attributes;
}

export async function fetchCustomerParts(data: DrilldownQuery): Promise<FetchedData> {
  const customerParts: FetchedData = await getAxiosBase()
    .post('/notefied/drilldown/info/cpn/', { ...data }, { signal: cancelSource })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
      return {};
    });
  return customerParts;
}

export async function fetchVendorInfo(id: string): Promise<ItemsSet[]> {
  const vendorInfo: ItemsSet[] = await getAxiosBase()
    .get('/notefied/drilldown/vendor/info/', { params: { id }, signal: cancelSource })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
      return [];
    });
  return vendorInfo;
}

export async function fetchVendorName(id: string): Promise<string> {
  const vendorName: string = await getAxiosBase()
    .get('/notefied/drilldown/vendor/name/', { params: { id }, signal: cancelSource })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
      return '';
    });
  return vendorName;
}

export async function fetchVendorFiles(data: DrilldownQuery): Promise<FetchedData> {
  const vendorFiles: FetchedData = await getAxiosBase()
    .post('/notefied/drilldown/vendor/files/', { ...data }, { signal: cancelSource })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
      return {};
    });
  return vendorFiles;
}

export async function fetchDocuments(data: DrilldownQuery): Promise<FetchedData> {
  const documents: FetchedData = await getAxiosBase()
    .post('/notefied/drilldown/libraries/documents/', { ...data }, { signal: cancelSource })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
      return {};
    });
  return documents;
}

export async function fetchHistory(data: DrilldownQuery): Promise<FetchedData> {
  const history: FetchedData = await getAxiosBase()
    .post('/notefied/drilldown/info/history/', { ...data }, { signal: cancelSource })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
      return {};
    });
  return history;
}

export async function fetchPSpice(data: DrilldownQuery): Promise<FetchedData> {
  const pspice: FetchedData = await getAxiosBase()
    .post('/notefied/drilldown/libraries/pspice/', { ...data }, { signal: cancelSource })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
      return {};
    });
  return pspice;
}

export async function fetchCompliance(data: DrilldownQuery): Promise<FetchedData> {
  const compliance: FetchedData = await getAxiosBase()
    .post('/notefied/drilldown/declaration/compliance/', { ...data }, { signal: cancelSource })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
      return {};
    });
  return compliance;
}

export async function fetchComplianceFiles(data: DrilldownQuery): Promise<FetchedData> {
  const complianceFiles: FetchedData = await getAxiosBase()
    .post(
      '/notefied/drilldown/declaration/compliance/files/',
      { ...data },
      { signal: cancelSource }
    )
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
      return {};
    });
  return complianceFiles;
}

export async function fetchPartList(id: string): Promise<FetchedData> {
  const partList: FetchedData = await getAxiosBase()
    .get('/notefied/drilldown/bom/part-list/', { params: { id }, signal: cancelSource })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
      return {};
    });
  return partList;
}

export async function fetchWhereUsed(data: DrilldownQuery): Promise<FetchedData> {
  const whereUsed: FetchedData = await getAxiosBase()
    .post('/notefied/drilldown/bom/where-used/', { ...data }, { signal: cancelSource })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
      return {};
    });
  return whereUsed;
}

export async function fetchDeclaration(data: DrilldownQuery): Promise<FetchedData> {
  const declaration: FetchedData = await getAxiosBase()
    .post('/notefied/drilldown/declaration/', { ...data }, { signal: cancelSource })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
      return {};
    });
  return declaration;
}

export async function fetchSpecificationInfo(id: string): Promise<ItemsSet[]> {
  const specificationInfo: ItemsSet[] = await getAxiosBase()
    .get('/notefied/drilldown/declaration/specification/info/', {
      params: { id },
      signal: cancelSource,
    })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
      return [];
    });
  return specificationInfo;
}

export async function fetchSpecificationSubstance(data: DrilldownQuery): Promise<FetchedData> {
  const declaration: FetchedData = await getAxiosBase()
    .post(
      '/notefied/drilldown/declaration/specification/substance/',
      { ...data },
      { signal: cancelSource }
    )
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
      return {};
    });
  return declaration;
}

export async function fetchDeclarationInfo(id: string): Promise<string> {
  const declarationInfo: string = await getAxiosBase()
    .get('/notefied/drilldown/declaration/info/', { params: { id }, signal: cancelSource })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
      return '';
    });
  return declarationInfo;
}

export async function fetchPartNumber(id: string): Promise<IProductDetailInfo | null> {
  const partNumber: IProductDetailInfo | null = await getAxiosBase()
    .get('/notefied/drilldown/info/part-number/', { params: { id } })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
      return null;
    });
  return partNumber;
}

export async function fetchPartNumberAndDeclaration(
  id: string
): Promise<ItemInfoForCompliance | null> {
  const itemInfo: ItemInfoForCompliance = await getAxiosBase()
    .get('/notefied/drilldown/declaration/part-number-declaration/', { params: { id } })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
      return null;
    });
  return itemInfo;
}

export async function fetchStatisticsObsolescenceChart(id: string): Promise<StatisticDataBOM> {
  const statisticsObsolescence: StatisticDataBOM = await getAxiosBase()
    .get('/notefied/drilldown/statistic/obsolescence/chart/', {
      params: { id },
      signal: cancelSource,
    })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
      return '';
    });
  return statisticsObsolescence;
}

export async function fetchStatisticsCustomerChart(id: string): Promise<StatisticDataBOM> {
  const statisticsCustomerObsolescence: StatisticDataBOM = await getAxiosBase()
    .get('/notefied/drilldown/statistic/customer/chart/', { params: { id }, signal: cancelSource })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
      return '';
    });
  return statisticsCustomerObsolescence;
}

export async function fetchStatisticsRoHsChart(id: string): Promise<StatisticDataMain> {
  const statisticsRoHs: StatisticDataMain = await getAxiosBase()
    .get('/notefied/drilldown/statistic/rohs/chart/', { params: { id }, signal: cancelSource })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
      return '';
    });
  return statisticsRoHs;
}

export async function fetchStatisticsVendorChart(id: string): Promise<StatisticDataMain> {
  const statisticsVendor: StatisticDataMain = await getAxiosBase()
    .get('/notefied/drilldown/statistic/vendor/chart/', { params: { id }, signal: cancelSource })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
      return '';
    });
  return statisticsVendor;
}

export async function fetchStatisticsEOLChart(id: string): Promise<StatisticDataMain> {
  const statisticsEOL: StatisticDataMain = await getAxiosBase()
    .get('/notefied/drilldown/statistic/eol/chart/', { params: { id }, signal: cancelSource })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
      return '';
    });
  return statisticsEOL;
}

export async function fetchBOMObsolescence(id: string): Promise<FetchedData> {
  const obsolescenceData: FetchedData = await getAxiosBase()
    .get('/notefied/drilldown/bom/part-list/', { params: { id }, signal: cancelSource })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
      return '';
    });
  return obsolescenceData;
}

export async function fetchBOMRohs(id: string): Promise<FetchedData> {
  const roshData: FetchedData = await getAxiosBase()
    .get('/notefied/drilldown/bom/part-list/', { params: { id }, signal: cancelSource })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
      return '';
    });
  return roshData;
}

export async function fetchBOMCustomerPartObsolescence(id: string): Promise<FetchedData> {
  const obsolescenceData: FetchedData = await getAxiosBase()
    .get('/notefied/drilldown/statistic/customer/data/', { params: { id }, signal: cancelSource })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
      return '';
    });
  return obsolescenceData;
}

export async function fetchSchematicPart(id: string): Promise<FetchedImagesData> {
  const schematicPartImages: FetchedImagesData = await getAxiosBase()
    .get('/notefied/drilldown/libraries/schematic-part/', { params: { id }, signal: cancelSource })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
      return '';
    });
  return schematicPartImages;
}

export async function fetchAltiumSchematicPart(id: string): Promise<FetchedImagesData> {
  const schematicPartImages: FetchedImagesData = await getAxiosBase()
    .get('/notefied/drilldown/libraries/altium-schematic-part/', {
      params: { id },
      signal: cancelSource,
    })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
      return '';
    });
  return schematicPartImages;
}

export async function fetchPCBFootprint(id: string): Promise<FetchedImagesData> {
  const pcbFootprintImages: FetchedImagesData = await getAxiosBase()
    .get('/notefied/drilldown/libraries/pcb-footprint/', { params: { id }, signal: cancelSource })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
      return [];
    });
  return pcbFootprintImages;
}

export async function fetchAltiumPCBFootprint(id: string): Promise<FetchedImagesData> {
  const pcbFootprintImages: FetchedImagesData = await getAxiosBase()
    .get('/notefied/drilldown/libraries/altium-pcb-footprint/', {
      params: { id },
      signal: cancelSource,
    })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
      return [];
    });
  return pcbFootprintImages;
}

export async function fetchThreeDModelFiles(id: string): Promise<FetchedThreeDFiles> {
  const threeDModel: FetchedThreeDFiles = await getAxiosBase()
    .get('/notefied/drilldown/libraries/three-d-model/files/', { params: { id }, signal: cancelSource })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
      return null;
    });
  return threeDModel;
}

export async function fetchThreeDModelImages(id: string): Promise<FetchedThreeDImages> {
  const threeDModel: FetchedThreeDImages = await getAxiosBase()
    .get('/notefied/drilldown/libraries/three-d-model/images/', { params: { id }, signal: cancelSource })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
      return null;
    });
  return threeDModel;
}

export async function fetchBOMVendorPartStatus(id: string): Promise<FetchedData> {
  const vendorPartStatusData: FetchedData = await getAxiosBase()
    .get('/notefied/drilldown/bom/part-list/', { params: { id }, signal: cancelSource })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
      return '';
    });
  return vendorPartStatusData;
}

export async function fetchBOMRisk(id: string): Promise<FetchedData> {
  const riskData: FetchedData = await getAxiosBase()
    .get('/notefied/drilldown/bom/part-list/', { params: { id }, signal: cancelSource })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
      return '';
    });
  return riskData;
}
