import ValueOrigin from '@/models/ValueOrigin';
import { StatisticUrlQuerySettings } from '@/interfaces';

const VENDOR_PART: StatisticUrlQuerySettings = {
  name: 'Vendor_Part_Status',
  value: ValueOrigin.LOCAL,
};

const KDA_VENDOR_PART: StatisticUrlQuerySettings = {
  name: 'KDA_Vendor_Part_Status',
  value: ValueOrigin.LOCAL,
};

const CUSTOMER_PART: StatisticUrlQuerySettings = {
  name: 'Customer_Part_Number',
  value: ValueOrigin.CONSTANT,
  constant: '%2a',
};

const CUSTOMER_PROJECT: StatisticUrlQuerySettings = {
  name: 'Customer_Projects',
  value: ValueOrigin.GLOBAL,
};

const ESTIMATED_END_OF_LIFE_RANGE: StatisticUrlQuerySettings = {
  name: 'Estimated_End_of_Life_Range',
  value: ValueOrigin.LOCAL,
};

export {
  VENDOR_PART,
  KDA_VENDOR_PART,
  CUSTOMER_PART,
  CUSTOMER_PROJECT,
  ESTIMATED_END_OF_LIFE_RANGE,
};
