

























import { Component, Mixins, Watch } from 'vue-property-decorator';
import TableComponent from '@/components/Table/TableComponent.vue';
import TableMixin from '@/mixins/tableMixins';
import { fetchCompliance, fetchDeclarationInfo } from '@/api/drilldown';
import { View, FetchedData } from '@/interfaces';

@Component({
  components: {
    TableComponent,
  },
})
export default class ComplianceMain extends Mixins(TableMixin) {
  @Watch('$route.name')
  async onRouteChange(): Promise<void> {
    if (this.$route.name === 'item.compliance.main') {
      this.getURLParams();
      this.isExportDisabled = true;
      const promiseData = fetchCompliance({
        id: this.id,
        filters: this.query,
        lowerThreshold: this.itemsRange[0],
        upperThreshold: this.itemsRange[1],
        sortingName: this.sorting.name,
        sortingOrder: this.sorting.order,
      });
      const promiseInfo = fetchDeclarationInfo(this.id);
      await Promise.all<FetchedData, string>([promiseData, promiseInfo]).then((result) => {
        [this.data, this.overallCompliance] = result;
      });
      this.isExportDisabled = false;
      this.isLoadingNewRecords = false;
      this.emitListener();
    }
  }

  public defaultView: View = {
    name: 'Default',
    fields: ['Declaration', 'Type', 'Specification'],
    default: false,
    selected: true,
  };

  public overallCompliance = '';

  get isDeclarationOrSpecification(): boolean {
    return !!this.$route.name?.includes('declaration') || !!this.$route.name?.includes('specification');
  }

  async mounted(): Promise<void> {
    if (this.$route.name === 'item.compliance.main') {
      this.getURLParams();
      this.isExportDisabled = true;
      const promiseData = fetchCompliance({
        id: this.id,
        filters: this.query,
        lowerThreshold: this.itemsRange[0],
        upperThreshold: this.itemsRange[1],
        sortingName: this.sorting.name,
        sortingOrder: this.sorting.order,
      });
      const promiseInfo = fetchDeclarationInfo(this.id);
      await Promise.all<FetchedData, string>([promiseData, promiseInfo]).then((result) => {
        [this.data, this.overallCompliance] = result;
      });
      this.isExportDisabled = false;
      this.isLoadingNewRecords = false;
      this.emitListener();
    }
  }

  public async fetchData(): Promise<void> {
    this.isExportDisabled = true;
    this.data = await fetchCompliance({
      id: this.id,
      filters: this.query,
      lowerThreshold: this.itemsRange[0],
      upperThreshold: this.itemsRange[1],
      sortingName: this.sorting.name,
      sortingOrder: this.sorting.order,
    });
    this.isExportDisabled = false;
  }
}
