





















import { Component, Vue, Inject } from 'vue-property-decorator';
import { Language } from '@/interfaces';
import { availableLanguages } from '@/configuration';
import { languages as availableLocales, Localization } from '@/locale';

@Component
export default class LocaleSwitcher extends Vue {
  @Inject('localization') localization!: Localization;

  public closeOnClick = true;

  public closeOnContentClick = true;

  public offsetY = true;

  public items: Language[] = availableLanguages.filter(
    (language) => Object.prototype.hasOwnProperty.call(availableLocales, language.value),
  );

  public changeLanguage(item: Language): void {
    this.localization.setLanguage(item.value);
    this.$router.go(0);
  }
}
