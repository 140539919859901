import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Dashboard from '@/views/Dashboard.vue';
import Login from '@/views/Login.vue';
import LoginForm from '@/components/Login/LoginForm.vue';
import SendPasswordReset from '@/components/Login/SendPasswordReset.vue';
import NewPass from '@/components/Login/NewPass.vue';
import InfoCard from '@/components/Login/InfoCard.vue';
import ProductDetail from '@/views/ProductDetail.vue';
import Navigator from '@/components/Navigators/Navigator.vue';
import GeneralInfo from '@/components/ProductDetail/GeneralInfo.vue';
import Attributes from '@/components/ProductDetail/Attributes.vue';
import CustomerParts from '@/components/ProductDetail/CustomerParts.vue';
import Documents from '@/components/ProductDetail/Documents.vue';
import PartList from '@/components/ProductDetail/PartList.vue';
import WhereUsed from '@/components/ProductDetail/WhereUsed.vue';
import PSpiceModel from '@/components/ProductDetail/PSpieceModel.vue';
import SchematicPart from '@/components/ProductDetail/SchematicPart.vue';
import AltiumSchematicPart from '@/components/ProductDetail/AltiumSchematicPart.vue';
import PCBfootprint from '@/components/ProductDetail/PCBfootprint.vue';
import AltiumPCBfootprint from '@/components/ProductDetail/AltiumPCBfootprint.vue';
import History from '@/components/ProductDetail/History.vue';
import Vendor from '@/components/ProductDetail/VendorTab/Vendor.vue';
import Compliance from '@/views/Compliance.vue';
import ComplianceMain from '@/components/ProductDetail/Compliance/Main.vue';
import ComplianceDocuments from '@/components/ProductDetail/Compliance/Documents.vue';
import ComplianceSpecification from '@/components/ProductDetail/Compliance/OverallCompliance/SpecificationTab/Specification.vue';
import ComplianceDeclaration from '@/components/ProductDetail/Compliance/OverallCompliance/Declaration.vue';
import Obsolescence from '@/components/ProductDetail/StatisticsBOM/Obsolescence.vue';
import Rohs from '@/components/ProductDetail/StatisticsBOM/Rohs.vue';
import VendorPartStatusStatistics from '@/components/ProductDetail/StatisticsBOM/VendorPartStatusStatistics.vue';
import RiskAnalysis from '@/components/ProductDetail/StatisticsBOM/RiskAnalysis.vue';
import CustomerPartObsolescence from '@/components/ProductDetail/StatisticsBOM/CustomerPartObsolescence.vue';
import OverallCompliance from '@/views/OverallCompliance.vue';
import Statistic from '@/views/Statistics.vue';
import SupplierData from '@/components/ProductDetail/SupplierData.vue';
import CustomerProject from '@/components/Statistics/CustomerProject.vue';
import EstimatedEndOfLife from '@/components/Statistics/EstimatedEndOfLife.vue';
import VendorPartStatus from '@/components/Statistics/VendorPartStatus.vue';
import Request from '@/views/Request.vue';
import StatisticsBOMView from '@/views/StatisticsBOM.vue';
import Declaration from '@/views/Declaration.vue';
import DeclarationGeneral from '@/components/Declaration/DeclarationGeneral.vue';
import DeclarationReports from '@/components/Declaration/DeclarationReports.vue';
import ThreeDModel from '@/components/ProductDetail/ThreeDModel.vue';
import UIPermissions from '@/models/UIPermissions';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      requiresLogged: true,
      permissions: [],
    },
    redirect: {
      name: 'login.signIn',
    },
    children: [
      {
        path: '',
        name: 'login.signIn',
        component: LoginForm,
      },
      {
        path: 'reset',
        name: 'login.reset',
        component: SendPasswordReset,
        props: true,
      },
      {
        path: 'new-password/:uid/:token',
        name: 'login.newPassword',
        component: NewPass,
        props: true,
      },
      {
        path: 'info',
        name: 'login.info',
        component: InfoCard,
        props: true,
      },
    ],
  },
  {
    path: '*',
    redirect: {
      name: 'main',
    },
  },
  {
    path: '/main',
    name: 'main',
    props: { default: true },
    components: {
      default: Dashboard,
      appbar: Navigator,
    },
    meta: {
      requiresAuth: true,
      permissions: [],
    },
  },
  {
    path: '/request',
    name: 'request',
    components: {
      default: Request,
      appbar: Navigator,
    },
    meta: {
      requiresAuth: true,
      permissions: [],
    },
  },
  {
    path: '/declaration',
    name: 'declaration',
    components: {
      default: Declaration,
      appbar: Navigator,
    },
    meta: {
      requiresAuth: true,
      permissions: [
        UIPermissions.CAS_NUMBER_TAB,
        UIPermissions.SUBSTANCE_NAME_TAB,
        UIPermissions.PPM_RESULT_TAB,
      ],
    },
    props: { default: true, appbar: true },
    children: [
      {
        path: 'general',
        name: 'declaration.general',
        component: DeclarationGeneral,
        props: true,
        meta: {
          export: 'declaration_general',
          permissions: [
            UIPermissions.CAS_NUMBER_TAB,
            UIPermissions.SUBSTANCE_NAME_TAB,
            UIPermissions.PPM_RESULT_TAB,
          ],
        },
      },
      {
        path: 'reports',
        name: 'declaration.reports',
        component: DeclarationReports,
        props: true,
        meta: {
          export: 'declaration_reports',
          permissions: [
            UIPermissions.CAS_NUMBER_TAB,
            UIPermissions.SUBSTANCE_NAME_TAB,
            UIPermissions.PPM_RESULT_TAB,
          ],
        },
      },
    ],
  },
  {
    path: '/item/:id',
    name: 'item',
    components: {
      default: ProductDetail,
      appbar: Navigator,
    },
    meta: {
      requiresAuth: true,
      permissions: [],
    },
    props: { default: true, appbar: true },
    children: [
      {
        path: 'general',
        name: 'item.general',
        component: GeneralInfo,
        props: true,
        meta: {
          export: 'general_info',
          permissions: [UIPermissions.GENERAL_INFORMATION],
        },
      },
      {
        path: 'customer',
        name: 'item.customer',
        component: CustomerParts,
        props: true,
        meta: {
          export: 'customer_parts',
          permissions: [],
        },
      },
      {
        path: 'attrributes',
        name: 'item.attrributes',
        component: Attributes,
        props: true,
        meta: {
          export: 'attributes',
          permissions: [
            UIPermissions.MANUFACTERING_PROCESS_DATA,
            UIPermissions.SUPPLIER_ANALYSIS,
            UIPermissions.ENVIOMENTAL_INFORMATION,
            UIPermissions.EDA_AND_CAD_INFORMATION,
            UIPermissions.TECHNICAL_INFORMATION,
          ],
        },
      },
      {
        path: 'vendor',
        name: 'item.vendor',
        component: Vendor,
        props: true,
        meta: {
          export: 'vendor',
          permissions: [UIPermissions.VENDOR_TAB],
        },
      },
      {
        path: 'compliance',
        name: 'item.compliance',
        component: Compliance,
        props: true,
        meta: {
          permissions: [UIPermissions.COMPLIANCE_TAB],
        },
        children: [
          {
            path: 'main',
            name: 'item.compliance.main',
            component: ComplianceMain,
            props: true,
            meta: {
              export: 'compliance_main',
              permissions: [UIPermissions.COMPLIANCE],
            },
            children: [
              {
                path: ':idDeclaration',
                name: 'item.compliance.main.overallCompliance',
                component: OverallCompliance,
                meta: {
                  requiresAuth: true,
                  permissions: [UIPermissions.DECLARATION, UIPermissions.SPECIFICATION],
                },
                props: true,
                children: [
                  {
                    path: 'declaration',
                    name: 'item.compliance.main.overallCompliance.declaration',
                    component: ComplianceDeclaration,
                    props: true,
                    meta: {
                      export: 'declaration',
                      permissions: [UIPermissions.DECLARATION],
                    },
                  },
                  {
                    path: 'specification',
                    name: 'item.compliance.main.overallCompliance.specification',
                    component: ComplianceSpecification,
                    props: true,
                    meta: {
                      export: 'specification',
                      permissions: [UIPermissions.SPECIFICATION],
                    },
                  },
                ],
              },
            ],
          },
          {
            path: 'documents',
            name: 'item.compilance.documents',
            component: ComplianceDocuments,
            props: true,
            meta: {
              export: 'compliance_documents',
              permissions: [UIPermissions.DECLARATION_DOCUMETNS],
            },
          },
        ],
      },
      {
        path: 'supplier-data',
        name: 'item.supplierData',
        component: SupplierData,
        props: true,
        meta: {
          export: 'supplier_data',
          permissions: [UIPermissions.SUPPLIER_DATA],
        },
      },
      {
        path: 'documents',
        name: 'item.documents',
        component: Documents,
        props: true,
        meta: {
          export: 'documents',
          permissions: [],
        },
      },
      {
        path: 'part-list',
        name: 'item.partList',
        component: PartList,
        props: true,
        meta: {
          export: 'part_list',
          permissions: [UIPermissions.PART_LIST],
        },
      },
      {
        path: 'where-used',
        name: 'item.whereUsed',
        component: WhereUsed,
        props: true,
        meta: {
          export: 'where_used',
          permissions: [UIPermissions.WHERE_USED],
        },
      },
      {
        path: 'pspice-model',
        name: 'item.spiceModel',
        component: PSpiceModel,
        props: true,
        meta: {
          export: 'pspice_model',
          permissions: [UIPermissions.PSPICE_MODEL],
        },
      },
      {
        path: 'schematic-part',
        name: 'item.schematicPart',
        component: SchematicPart,
        props: true,
        meta: {
          export: 'schematic_part',
          permissions: [UIPermissions.SCHEMATIC_PART],
        },
      },
      {
        path: 'altium-schematic-part',
        name: 'item.altiumSchematicPart',
        component: AltiumSchematicPart,
        props: true,
        meta: {
          export: 'altium_schematic_part',
          permissions: [UIPermissions.ALTIUM_SCHEMATIC_PART],
        },
      },
      {
        path: 'pcb-footprint',
        name: 'item.pcbFootprint',
        component: PCBfootprint,
        props: true,
        meta: {
          export: 'pcb_footprint',
          permissions: [UIPermissions.PCB_FOOTPRINT],
        },
      },
      {
        path: 'altium-pcb-footprint',
        name: 'item.altiumPcbFootprint',
        component: AltiumPCBfootprint,
        props: true,
        meta: {
          export: 'altium_pcb_footprint',
          permissions: [UIPermissions.ALTIUM_PCB_FOOTPRINT],
        },
      },
      {
        path: 'three-d-model',
        name: 'item.threeDModel',
        component: ThreeDModel,
        props: true,
        meta: {
          export: 'three_d_model',
          permissions: [UIPermissions.THREE_D_MODEL],
        },
      },
      {
        path: 'history',
        name: 'item.history',
        component: History,
        props: true,
        meta: {
          export: 'history',
          permissions: [UIPermissions.HISTORY],
        },
      },
      {
        path: 'statistics',
        name: 'item.statistics',
        component: StatisticsBOMView,
        props: true,
        meta: {
          permissions: [UIPermissions.BOM_STATISTIC],
        },
        children: [
          {
            path: 'obsolescence',
            name: 'item.statistics.obsolescence',
            component: Obsolescence,
            props: true,
            meta: {
              export: 'statistics_obsolescence',
              permissions: [UIPermissions.BOM_STATISTIC_OBSOLESCENCE],
            },
          },
          {
            path: 'customerPartObsolescence',
            name: 'item.statistics.customerPartObsolescence',
            component: CustomerPartObsolescence,
            props: true,
            meta: {
              export: 'statistics_cpn_obsolescence',
              permissions: [UIPermissions.BOM_STATISTIC_CUSTOMER_RISK_STATUS],
            },
          },
          {
            path: 'rohs',
            name: 'item.statistics.rohs',
            component: Rohs,
            props: true,
            meta: {
              export: 'statistics_rohs',
              permissions: [UIPermissions.BOM_STATISTIC_OBSOLESCENCE],
            },
          },
          {
            path: 'chartsVendor',
            name: 'item.statistics.chartsVendor',
            component: VendorPartStatusStatistics,
            props: true,
            meta: {
              export: 'statistics_charts_vendor',
              permissions: [UIPermissions.BOM_ALL_COMPONENTS],
            },
          },
          {
            path: 'chartsRisk',
            name: 'item.statistics.chartsRisk',
            component: RiskAnalysis,
            props: true,
            meta: {
              export: 'statistics_charts_risk',
              permissions: [UIPermissions.BOM_RISK_ANALYSIS],
            },
          },
        ],
      },
    ],
  },
  {
    path: '/statistics/',
    name: 'statistics',
    components: {
      default: Statistic,
      appbar: Navigator,
    },
    meta: {
      requiresAuth: true,
      permissions: [
        UIPermissions.VENDOR_PART_STATUS,
        UIPermissions.ESTIMATED_END_OF_LIFE,
        UIPermissions.CUSTOMER_PROJECT,
      ],
    },
    children: [
      {
        path: 'vendor',
        name: 'statistics.vendor',
        component: VendorPartStatus,
        meta: {
          permissions: [UIPermissions.VENDOR_PART_STATUS],
        },
      },
      {
        path: 'end-of-life',
        name: 'statistics.endOfLife',
        component: EstimatedEndOfLife,
        meta: {
          permissions: [UIPermissions.ESTIMATED_END_OF_LIFE],
        },
      },
      {
        path: 'customer-project',
        name: 'statistics.customerProject',
        component: CustomerProject,
        meta: {
          permissions: [UIPermissions.CUSTOMER_PROJECT],
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;

export { routes };
