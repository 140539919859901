

























































































import { Component, Vue, PropSync, Watch } from 'vue-property-decorator';
import SimpleSnackbar from '@/components/Snackbar/SimpleSnackbar.vue';
import DialogConfirm from '@/components/Dialog/DialogConfirm.vue';
import { Customer, AdminView, CustomerUser } from '@/interfaces';
import { getAvailableCustomers, addCustomerView } from '@/api/table';

@Component({
  components: {
    SimpleSnackbar,
    DialogConfirm,
  },
})
export default class HeadersDialog extends Vue {
  @PropSync('fields') selectedFields!: string[];

  @PropSync('disabledSelect', { default: false }) disabled!: boolean;

  public dialog = false;

  public showDeleteDialog = false;

  public showSnackbar = false;

  public customers: Customer[] = [];

  public loadingAddUpdateButton = false;

  public disabledAddBtn = true;

  public viewForm: AdminView = {
    name: '',
    fields: '',
    customer: { name: '', id: null },
    users: [],
  };

  get availableUsers(): CustomerUser[] {
    const customer = this.customers.find((e) => e.id === this.viewForm.customer.id);
    return customer === undefined ? [] : customer.users;
  }

  get selectedAllUsers(): boolean {
    return this.viewForm.users.length === this.availableUsers.length;
  }

  get someUSers(): boolean {
    return this.viewForm.users.length > 0 && !this.customers.length;
  }

  get icon(): string {
    if (this.selectedAllUsers) return 'mdi-close-box';
    if (this.someUSers) return 'mdi-minus-box';
    return 'mdi-checkbox-blank-outline';
  }

  public async handleSubmit(): Promise<void> {
    this.loadingAddUpdateButton = true;
    if (this.viewForm.customer.id != null) {
      await addCustomerView({
        name: this.viewForm.name,
        fields: this.selectedFields.join(';'),
        type: this.$route.name || '',
        users: this.viewForm.users.map((user) => user.id),
      });
    }
    this.loadingAddUpdateButton = false;
    this.close();
  }

  public test = [];

  public toggle(): void {
    this.$nextTick(() => {
      if (this.selectedAllUsers) {
        this.viewForm.users = [];
      } else {
        this.viewForm.users = this.availableUsers;
      }
    });
  }

  public handleViewKeyUp(e: KeyboardEvent): void {
    if (e.target instanceof HTMLInputElement) {
      const searchValue = e.target.value.toLowerCase();
      this.customers.filter((item) => item.name.toLowerCase().includes(searchValue));
    }
  }

  public closeDeleteDialog(): void {
    this.showDeleteDialog = false;
  }

  public close(): void {
    this.dialog = false;
    this.viewForm = {
      name: '',
      fields: '',
      customer: { name: '', id: null },
      users: [],
    };
  }

  async mounted(): Promise<void> {
    this.customers = await getAvailableCustomers();
  }

  @Watch('viewForm', { deep: true, immediate: true })
  isDisabled(newVal: AdminView): void {
    this.disabledAddBtn =
      newVal.name === '' || newVal.customer?.id === null || !newVal.users.length;
  }
}
