






























import { Component, Vue, PropSync, Emit } from 'vue-property-decorator';
import {
  StatisticDataMain,
  BarAndPieChartDataExtended,
  StatisticHeader,
} from '@/interfaces';

@Component
export default class PartsStatusTable extends Vue {
  @PropSync('tableData', { required: true }) data!: StatisticDataMain;

  public items: BarAndPieChartDataExtended[] = [];

  public headers: StatisticHeader[] = [
    { value: 'name' },
    { value: 'quantity' },
    { value: 'percent' },
  ];

  public mounted(): void {
    this.data.status.forEach((element) => {
      this.items.push({
        name: element.name,
        quantity: element.quantity,
        percent: `${((element.quantity / this.data.totalParts) * 100).toFixed(2).toString()}%`,
        color: element.color,
      });
    });
    this.items.push({
      name: this.$t('statistic.total').toString(),
      quantity: this.data.totalParts,
      percent: '100%',
      color: '#333333',
    });
  }

  @Emit('clickTableValue')
  public handleClickValue(status: string): string {
    return status;
  }
}
