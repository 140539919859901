import getAxiosBase, { cancelSource } from '@/api/axios-base';
import { FetchedData, DashboardQuery, CustomerPartNumberData } from '@/interfaces';

export async function fetchDashboard(data: DashboardQuery): Promise<FetchedData> {
  const dashboard: FetchedData = await getAxiosBase()
    .post('/notefied/dashboard/fetch/', { ...data }, { signal: cancelSource })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
      return {
        itemsLength: 0,
        items: [],
        views: [],
      };
    });
  return dashboard;
}

export async function fetchComponents(): Promise<string[]> {
  const components: string[] = await getAxiosBase()
    .get('/notefied/dashboard/components/', { signal: cancelSource })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
      return [];
    });
  return components;
}

export async function fetchCustomerPartNumber(customerPartNumber: string | null | undefined):
  Promise<CustomerPartNumberData> {
  const customerPartNumberData = await getAxiosBase()
    .get('notefied/dashboard/fetch-customer-part/', { params: { customerPartNumber } })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
      return {};
    });
  return customerPartNumberData;
}
