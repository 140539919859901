








































import { Vue, Component } from 'vue-property-decorator';
import { Dictionary } from 'lodash';
import eventBus from '@/eventBus';

@Component
export default class SearchTool extends Vue {
  private sessionStorage = window.sessionStorage;

  private numberOfSessionAttributes = 0;

  public attributesOfSession: string[] = [];

  private query: Dictionary<string | (string | null)[]> = {};

  get isMain(): boolean {
    return this.$route.name === 'main';
  }

  get numberOfAttributes(): number {
    return this.numberOfSessionAttributes;
  }

  get queryParameters(): Dictionary<string | (string | null)[]> {
    return this.query;
  }

  mounted(): void {
    this.attributesOfSession = Object.keys(this.sessionStorage);
    this.numberOfSessionAttributes = this.sessionStorage.length;
    eventBus.$on('changeAttributesLength', (value: number) => {
      this.attributesOfSession = Object.keys(this.sessionStorage);
      this.numberOfSessionAttributes = value;
      this.establishQuery();
    });
    this.establishQuery();
  }

  private establishQuery(): void {
    const keys = Object.keys(sessionStorage);
    const query: Dictionary<string | (string | null)[]> = {};
    keys.forEach((e) => {
      const value = this.sessionStorage.getItem(e);
      query[e] = value || '';
    });
    this.query = query;
  }

  public removeAttribute(value: string): void {
    this.sessionStorage.removeItem(value);
    this.attributesOfSession = this.attributesOfSession.filter((n) => n !== value);
    this.numberOfSessionAttributes = this.sessionStorage.length;
    this.establishQuery();
    eventBus.$emit('removeAttribute', value);
  }

  public removeAllAttributes(): void {
    const attributes = this.attributesOfSession;
    attributes.forEach((value) => {
      this.sessionStorage.removeItem(value);
      this.attributesOfSession = this.attributesOfSession.filter((n) => n !== value);
    });
    this.numberOfSessionAttributes = this.sessionStorage.length;
    this.establishQuery();
    eventBus.$emit('removeAllAttributes', attributes);
  }

  public getQueryByAttributes(): void {
    eventBus.$emit('getQueryByAttributes', this.queryParameters);
  }
}
