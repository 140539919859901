/* eslint-disable no-shadow */
enum Filters {
  PART_CLASS = 'Type',
  CUSTOMER_PART_NUMBER = 'Customer_Part_Number',
  CUSTOMER_PART_STATUS = 'Customer_Part_Status',
  CUSTOMER_PROJECTS = 'Customer_Projects',
  VENDOR_PART_NUMBER = 'Vendor_Part_Number',
  CUSTOMER_PARTS = 'Customer_Parts',
  CUSTOMER_PART_STATUSES = 'Customer_Part_Statuses',
  CUSTOMER_PART_OWNERS = 'Customer_Part_Owners',
  CUSTOMER = 'Customer',
  CAS_NUMBER = 'CAS_Number',
  SUBSTANCE = 'Substance',
  RESULT_PPM = 'Result_PPM',
  THREE_D_MODEL_DWF = '3D_Model_DWF',
  THREE_D_MODEL_IPT = '3D_Model_IPT',
  THREE_D_MODEL_STP = '3D_Model_STP',
  ALTIUM_PCB_FOOTPRINT_LEAST = 'Altium_PCB_Footprint_Least',
  ALTIUM_PCB_FOOTPRINT_MOST = 'Altium_PCB_Footprint_Most',
  ALTIUM_PCB_FOOTPRINT_NOMINAL = 'Altium_PCB_Footprint_Nominal',
  ALTIUM_SCHEMATIC_PART = 'Altium_Schematic_Part',
  ALTIUM_SECOND_CUSTOM_FOOTPRINTS = 'Altium_Second_Custom_Footprints',
  PCB_ALTERNATE_FOOTPRINT = 'PCB_Alternate_Footprint',
  PCB_FOOTPRINT = 'PCB_Footprint',
  SCHEMATIC_PART = 'Schematic_Part',
}

export default Filters;
