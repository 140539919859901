import getAxiosBase from '@/api/axios-base';
import { userUIPermissions } from '@/api/user';
import { Auth, AuthResponse } from '@/models/Auth';
import { BrowsingHistory } from '@/interfaces';

export default class User {
  public isLogged: boolean;

  public permissions: string[];

  public downloadPermissions: string[];

  public browsingItems: BrowsingHistory[] | null;

  public name: string;

  constructor() {
    this.isLogged = false;
    this.permissions = [];
    this.downloadPermissions = [];
    this.browsingItems = null;
    this.name = '';
  }

  public async verify(): Promise<Auth> {
    if (!localStorage.getItem('refresh')) {
      this.isLogged = false;
      this.resetLocalStorage();
      return AuthResponse.NO;
    }
    const isAuthenticated: Auth = await getAxiosBase()
      .get('/notefied/user/session/')
      .then((response) => {
        if (response.status === 200 && response.data.isAuthenticated === AuthResponse.YES) {
          this.setLocalStorage(response.data.token);
          this.resetLocalStorage(false, false);
          this.isLogged = true;
          return response.data.isAuthenticated;
        }
        if (response.status === 200 && response.data.isAuthenticated === AuthResponse.PARTIALLY) {
          this.setLocalStorage(null, null);
          this.resetLocalStorage(true, true);
          this.isLogged = false;
          return response.data.isAuthenticated;
        }
        this.isLogged = false;
        this.resetLocalStorage();
        return AuthResponse.NO;
      })
      .catch(() => {
        this.isLogged = false;
        this.resetLocalStorage();
        return AuthResponse.NO;
      });
    if (
      (!this.permissions.length || !this.downloadPermissions.length || !this.name) &&
      this.isLogged
    ) {
      const allPermissions = await userUIPermissions();
      this.permissions = allPermissions.ui;
      this.downloadPermissions = allPermissions.download;
      this.name = allPermissions.name;
      this.browsingItems = allPermissions.history;
    }
    return isAuthenticated;
  }

  public async logout(): Promise<void> {
    this.isLogged = false;
    await getAxiosBase().post('/notefied/user/logout/');
    this.resetLocalStorage();
    this.name = '';
    this.permissions = [];
    this.downloadPermissions = [];
    this.browsingItems = [];
  }

  public async login(email: string, password: string): Promise<Auth> {
    const isAuthenticated: Auth = await getAxiosBase()
      .get('/notefied/user/auth/', {
        auth: {
          username: email,
          password,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          if (response.data.isAuthenticated === AuthResponse.PARTIALLY) {
            this.setLocalStorage(null, null);
            this.resetLocalStorage(true, true);
            this.isLogged = false;
            return response.data.isAuthenticated;
          }
          this.setLocalStorage(response.data.token, response.data.refresh);
          this.resetLocalStorage(false, false);
          this.isLogged = true;
          return response.data.isAuthenticated;
        }
        this.isLogged = false;
        this.resetLocalStorage();
        return AuthResponse.NO;
      })
      .catch(() => {
        this.isLogged = false;
        this.resetLocalStorage();
        return AuthResponse.NO;
      });
    return isAuthenticated;
  }

  public async multifactorLogin(token: string, email: string, password: string): Promise<Auth> {
    const tokenAuth: Auth = await getAxiosBase()
      .get('/notefied/user/totp/verify/', {
        params: {
          token,
        },
        auth: {
          username: email,
          password,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          this.setLocalStorage(response.data.token, response.data.refresh);
          this.resetLocalStorage(false, false);
          this.isLogged = true;
          return response.data.isAuthenticated;
        }
        if (response.data.isAuthenticated === AuthResponse.PARTIALLY) {
          this.setLocalStorage(null, null);
          this.resetLocalStorage(true, true);
        }
        this.isLogged = false;
        return AuthResponse.NO;
      })
      .catch(() => {
        this.isLogged = false;
        return AuthResponse.NO;
      });
    return tokenAuth;
  }

  private resetLocalStorage(token = true, refresh = true) {
    if (token) localStorage.removeItem('token');
    if (refresh) localStorage.removeItem('refresh');
  }

  private setLocalStorage(
    token: string | null = null,
    refresh: string | null = null,
  ) {
    if (token) localStorage.setItem('token', token);
    if (refresh) localStorage.setItem('refresh', refresh);
  }
}

export const user = new User();
