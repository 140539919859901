












































import { Component, Vue, Prop } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import { emailAddress } from '@/validation/rules';
import { sendEmail } from '@/api/user';

@Component
export default class SendPasswordReset extends Vue {
  @Prop({ default: false }) firstLogin!: boolean;

  public isLoading = false;

  public email = '';

  public valid = false;

  public emailRule = emailAddress;

  public errorMessage: TranslateResult = '';

  public async resetPassword(): Promise<void> {
    this.isLoading = true;
    const res = await sendEmail(this.email.toLowerCase());
    this.isLoading = false;
    if (res === 200) {
      this.$router.push({
        name: 'login.info',
        params: {
          title: this.$t('login.resetDone.title').toString(),
          message: this.$t('login.resetDone.message').toString(),
        },
      });
    } else if (res === 404) {
      this.errorMessage = this.$t('login.sendPasswordReset.emailError');
    } else {
      this.$router.push({
        name: 'login.info',
        params: {
          title: this.$t('login.sendPasswordReset.generalError.title').toString(),
          message: this.$t('login.sendPasswordReset.generalError.message').toString(),
          error: 'true',
        },
      });
    }
  }
}
