


















































































































import { Component, Vue, PropSync, Prop, Inject, Emit, Watch } from 'vue-property-decorator';
import { Fragment } from 'vue-frag';
import {
  Header,
  FetchedItem,
  SpecialCell,
  AdditionalDataInfo,
  AttributeParamter,
} from '@/interfaces';
import {
  links,
  urls,
  specialCellsConfig,
  multiValueFields,
  multiValueDelimiter,
  fileFields,
  reports,
  queryValueFields,
  linksDictionary,
  replaceStrings,
} from '@/configuration';
import fetchFile from '@/api/file';
import { exportReport } from '@/api/export';
import eventBus from '@/eventBus';
import User from '@/models/User';

@Component({
  components: {
    Fragment,
  },
})
export default class CustomRow extends Vue {
  @Inject() user!: User;

  @PropSync('data', { required: true }) tableData!: FetchedItem;

  @PropSync('parentItemId', { required: true }) itemIdentifier!: number;

  @PropSync('loading', { required: true }) loadingData!: boolean;

  @Prop() headers!: Header[];

  @Prop({ default: false }) isExpandedAll!: boolean;

  public fileIsDownloading = false;

  public cellsConfig: SpecialCell[] = specialCellsConfig;

  private linksFields = links;

  private multiValue = multiValueFields;

  private queryValue = queryValueFields;

  private window = window;

  private urlFields = urls;

  private origin: string = window.location.origin;

  private items: FetchedItem[] = [];

  private indentationRow = 0;

  public delimiter = multiValueDelimiter;

  public isLoading = true;

  public isExpanded = false;

  public showMore = true;

  get fetchedItems(): FetchedItem[] {
    return this.items;
  }

  set fetchedItems(value: FetchedItem[]) {
    this.items = value;
  }

  get rowPadding(): string {
    return `padding-left:${this.incrementedIndentationRow * 32 - 32}px; white-space: nowrap`;
  }

  get rowHeaders(): Header[] {
    return this.headers;
  }

  get incrementedIndentationRow(): number {
    return this.indentationRow;
  }

  set incrementedIndentationRow(value: number) {
    this.indentationRow = value;
  }

  created(): void {
    const length = this.tableData.parentsIds?.length;
    if (typeof length === 'undefined') {
      this.incrementedIndentationRow = 2;
    } else {
      this.incrementedIndentationRow = length + 3;
    }
    this.isLoading = false;
    if (this.isExpandedAll) {
      this.expandItem(true);
    }
  }

  public async downloadFile(fileName: string, fileType: string, fileFolder: string): Promise<void> {
    let endpoint = 'document';
    if (this.$route.name === 'item.vendor') {
      endpoint = 'vendor';
    }
    if (this.$route.name === 'item.spiceModel') {
      endpoint = 'pspice';
    }
    if (this.$route.name === 'item.compilance.documents') {
      endpoint = 'declaration';
    }
    this.fileIsDownloading = true;
    const response = await fetchFile({
      fileName,
      fileType,
      fileFolder,
      endpoint,
      id: this.$route.params?.id,
    });
    if (!response) {
      eventBus.$emit('generalError', {
        type: 'error',
        message: this.$t('error.file').toString(),
      });
    }
    this.fileIsDownloading = false;
  }

  public async downloadReport(fileName: string, fileType: string): Promise<void> {
    this.fileIsDownloading = true;
    const endpoint = reports.find((e) => e.name === fileType)?.endpoint;
    if (endpoint) {
      const response = await exportReport(this.$route.params?.id, fileName, endpoint);
      if (!response) {
        eventBus.$emit('generalError', {
          type: 'error',
          message: this.$t('error.file').toString(),
        });
      }
    } else {
      eventBus.$emit('generalError', {
        type: 'error',
        message: this.$t('error.file').toString(),
      });
    }
    this.fileIsDownloading = false;
  }

  public expandItem(expandAll = false): void {
    if (!this.loadingData) {
      if (!expandAll) {
        this.isExpanded = !this.isExpanded;
      } else {
        this.isExpanded = this.isExpandedAll;
      }
      this.expandItemInformation({
        id: this.itemIdentifier,
        expanded: this.isExpanded,
        parentsIds: this.tableData.parentsIds || [],
      });
    }
  }

  public async handleArtificalRowClick(): Promise<void> {
    if (!this.loadingData) {
      this.fetchMoreData(this.itemIdentifier);
    }
  }

  public isLink(value: string): boolean {
    return this.linksFields.some((e) => e.itemName === value);
  }

  public isUrl(value: string, data: string): boolean {
    return this.urlFields.some((e) => e === value) && data.startsWith('http');
  }

  public isMultiValueLink(value: string): boolean {
    return this.multiValue.some((e) => e === value);
  }

  public isQueryValueLink(value: string): boolean {
    return this.queryValue.some((e) => e === value);
  }

  public isFile(value: string, type: string): boolean {
    return this.user.downloadPermissions.includes(type) && fileFields.includes(value);
  }

  public isReport(value: string, type: string): boolean {
    return (
      this.user.downloadPermissions.includes(type) &&
      reports.map((e) => e.name).includes(type) &&
      fileFields.includes(value)
    );
  }

  public routeName(value: string): string {
    const link = this.linksFields.find((e) => e.itemName === value);
    return link ? link.routerName : '';
  }

  public linkName(value: string): string {
    const link = this.linksFields.find((e) => e.itemName === value);
    return link ? link.linkName : '';
  }

  public routeAttribute(value: string): AttributeParamter[] {
    const link = this.linksFields.find((e) => e.itemName === value);
    return link ? link.attributes : [];
  }

  public seeItemDetails(value: FetchedItem, name: string, attributes: AttributeParamter[]): void {
    const tableKeys = Object.keys(value);
    const params: { [otherOptions: string]: string } = {};
    attributes.forEach((e) => {
      if (tableKeys.includes(e.key)) {
        params[e.name] = value[e.key] ? `${value[e.key]}` : '';
      }
    });
    const routeData = this.$router.resolve({
      name,
      params,
    });
    this.window.open(routeData.href, '_blank');
  }

  public goToUrl(value: string): void {
    this.window.open(this.adjustUrl(value), '_blank');
  }

  public adjustUrl(value: string): string {
    let modefiedValue = value;
    replaceStrings.forEach((e) => {
      modefiedValue = modefiedValue.replaceAll(e.current, e.new);
    });
    return modefiedValue;
  }

  public seeOneOfValue(value: string, name: string): void {
    const linkDict = linksDictionary.find((e) => e.tableName === name);
    const correctName = linkDict ? linkDict.queryName : name;
    const query = {
      [correctName]: value.trim(),
    };
    const routeData = this.$router.resolve({
      name: 'main',
      query,
    });
    this.window.open(routeData.href, '_blank');
  }

  public establishLinForQueryValueField(name: string, value: string): string {
    const linkDict = linksDictionary.find((e) => e.tableName === name);
    const correctName = linkDict ? linkDict.queryName : name;
    return `main?${correctName}=${value.trim()}`;
  }

  public establishLink(value: FetchedItem, name: string, attributes: AttributeParamter[]): string {
    const tableKeys = Object.keys(value);
    let newName = name;
    attributes.forEach((e) => {
      if (tableKeys.includes(e.key)) {
        newName = newName.replace(e.name.toString(), `${value[e.key]}`.toString());
      }
    });
    return `${this.origin}/${newName}`;
  }

  public cellIconColor(itemName: string, header: string): string {
    const cellColor = this.cellsConfig.find((e) => e.name === itemName && e.header === header);
    return cellColor ? cellColor.color : '';
  }

  public cellIcon(itemName: string, header: string): string {
    const cellIcon = this.cellsConfig.find((e) => e.name === itemName && e.header === header);
    return cellIcon ? cellIcon.icon : '';
  }

  public specialHeader(header: string): boolean {
    const headerExist = this.cellsConfig.find((e) => e.header === header);
    return headerExist !== undefined;
  }

  @Emit('expandItemInformation')
  public expandItemInformation(additionalDataInfo: AdditionalDataInfo): AdditionalDataInfo {
    return additionalDataInfo;
  }

  @Emit('fetchMoreData')
  public fetchMoreData(id: number): number {
    return id;
  }

  @Watch('isExpandedAll')
  onExpandedAll(): void {
    this.expandItem(true);
  }
}
