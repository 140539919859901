








import { Component, Vue } from 'vue-property-decorator';
import eventBus from '@/eventBus';
import { OptionSnackbar } from '@/interfaces';

@Component
export default class GeneralSnackbar extends Vue {
  public type = 'success';

  public message = '';

  public snackbar = false;

  public timeout = 5000;

  mounted(): void {
    eventBus.$on('generalError', (info: OptionSnackbar) => {
      this.type = info.type;
      this.message = info.message;
      if (info.timeout) {
        this.timeout = info.timeout;
      }
      this.snackbar = true;
    });
  }
}
