
































import { Component, Vue } from 'vue-property-decorator';
import LocaleSwitcher from '@/components/LocaleSwitcher.vue';

@Component({
  components: {
    LocaleSwitcher,
  },
})
export default class Login extends Vue {
  get themeDark(): boolean {
    return this.$vuetify.theme.dark;
  }

  set themeDark(value: boolean) {
    this.$vuetify.theme.dark = value;
  }

  public changeTheme(): void {
    this.themeDark = !this.themeDark;
    if (this.themeDark) {
      window.localStorage.setItem('theme', 'dark');
    } else {
      window.localStorage.setItem('theme', 'light');
    }
  }
}
