// eslint-disable-next-line no-shadow
enum AuthResponse {
  YES = 'yes',
  NO = 'no',
  PARTIALLY = 'partially',
}

type Auth = 'yes' | 'no' | 'partially';

export { Auth, AuthResponse };
