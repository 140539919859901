

















import { Vue, Component, Prop } from 'vue-property-decorator';
import ExpansionPanel from '@/components/ExpansionPanel.vue';
import { ItemsSet } from '@/interfaces';

@Component({
  components: {
    ExpansionPanel,
  },
})
export default class ComplianceSpecificationInfo extends Vue {
  @Prop() mappedData!: ItemsSet[];

  @Prop() isLoadingNewRecords!: boolean;
}
