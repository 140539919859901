

















import { Vue, Component, Prop, Inject } from 'vue-property-decorator';
import { ItemsSet } from '@/interfaces';
import ExpansionPanel from '@/components/ExpansionPanel.vue';
import UIPermissions from '@/models/UIPermissions';
import User from '@/models/User';

@Component({
  components: {
    ExpansionPanel,
  },
})
export default class VendorInfo extends Vue {
  @Inject() user!: User;

  @Prop() mappedData!: ItemsSet[];

  @Prop() isLoadingNewRecords!: boolean;

  get vendorDocumentsAllowed(): boolean {
    return this.user.permissions.includes(UIPermissions.VENDOR_DOCUMENTS);
  }
}
