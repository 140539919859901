import getAxiosBase, { cancelSource } from '@/api/axios-base';
import { TOTPDevice, Permissions } from '@/interfaces';

export async function sendEmail(email: string): Promise<number> {
  const responseStatus: number = await getAxiosBase()
    .post('/notefied/user/email/', { email }, { signal: cancelSource })
    .then((response) => response.status)
    .catch((error) => error.response.status);
  return responseStatus;
}

export async function setPassword(
  token: string,
  uidb64: string,
  password: string,
  passwordConfirm: string,
  check: boolean
): Promise<number> {
  const responseStatus: number = await getAxiosBase()
    .post(
      '/notefied/user/set/',
      {
        token,
        uidb64,
        password,
        passwordConfirm,
        check,
      },
      { signal: cancelSource }
    )
    .then((response) => response.status)
    .catch((error) => error.response.status);
  return responseStatus;
}

export async function changePassword(password: string, passwordConfirm: string): Promise<number> {
  const responseStatus: number = await getAxiosBase()
    .post('/notefied/user/change/', { password, passwordConfirm }, { signal: cancelSource })
    .then((response) => response.status)
    .catch((error) => error.response.status);
  return responseStatus;
}

export async function fetchTOTPDevice(): Promise<TOTPDevice> {
  const data: TOTPDevice = await getAxiosBase()
    .post('/notefied/user/totp/create/', {}, { signal: cancelSource })
    .then((response) => response.data)
    .catch((error) => error.response.status);
  return data;
}

export async function confirmTOTPDevice(token: string): Promise<boolean> {
  const status: boolean = await getAxiosBase()
    .post('/notefied/user/totp/confirm/', { token }, { signal: cancelSource })
    .then((response) => response.status === 200)
    .catch(() => false);
  return status;
}

export async function resetTOTPDevice(): Promise<boolean> {
  const status: boolean = await getAxiosBase()
    .post('/notefied/user/totp/reset/', {}, { signal: cancelSource })
    .then((response) => response.status === 200)
    .catch(() => false);
  return status;
}

export async function userUIPermissions(): Promise<Permissions> {
  const permissions: Permissions = await getAxiosBase()
    .get('/notefied/user/permissions/', { signal: cancelSource })
    .then((response) => response.data)
    .catch(() => []);
  return permissions;
}
