













import { Component, Vue } from 'vue-property-decorator';
import { StatisticDataMain, StatisticUrlQuerySettings } from '@/interfaces';
import { fetchVendorChartsData } from '@/api/statistic';
import StatisticContainer from '@/components/Statistics/Containers/StatisticContainer.vue';
import { VENDOR_PART, CUSTOMER_PART, KDA_VENDOR_PART } from '@/models/StatisticUrlQuery';

@Component({
  components: {
    StatisticContainer,
  },
})
export default class VendorPartStatus extends Vue {
  public items: StatisticDataMain[] = [];

  public settings: StatisticUrlQuerySettings[][] = [
    [VENDOR_PART],
    [VENDOR_PART, CUSTOMER_PART],
    [KDA_VENDOR_PART],
  ];

  public loading = true;

  public async created(): Promise<void> {
    this.items = await fetchVendorChartsData();
    this.loading = false;
  }
}
