



import { Component, Vue, PropSync } from 'vue-property-decorator';
import VueApexCharts from 'vue-apexcharts';
import { ApexOptions } from 'apexcharts';
import { StatisticDataMain } from '@/interfaces';

@Component({
  components: {
    VueApexCharts,
  },
})
export default class PieChart extends Vue {
  @PropSync('chartData', { required: true }) data!: StatisticDataMain;

  public labels: string[] = [];

  public series: number[] = [];

  public colors: string[] = [];

  mounted(): void {
    this.data.status.forEach((element) => {
      this.labels.push(element.name);
      this.series.push(element.quantity);
      this.colors.push(element.color);
    });
  }

  get chartOptions(): ApexOptions {
    return {
      colors: this.colors,
      labels: this.labels,
      chart: {
        animations: {
          enabled: false,
        },
      },
      legend: {
        show: true,
        position: 'top',
        labels: {
          useSeriesColors: true,
        },
      },
    };
  }
}
