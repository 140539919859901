






















import { Component, Vue } from 'vue-property-decorator';
import { StatisticDataMain, StatisticUrlQuerySettings } from '@/interfaces';
import { fetchCustomerProjectChartsData } from '@/api/statistic';
import StatisticContainer from '@/components/Statistics/Containers/StatisticContainer.vue';
import {
  VENDOR_PART,
  KDA_VENDOR_PART,
  CUSTOMER_PROJECT,
  ESTIMATED_END_OF_LIFE_RANGE,
} from '@/models/StatisticUrlQuery';

@Component({
  components: {
    StatisticContainer,
  },
})
export default class CustomerProject extends Vue {
  public items: StatisticDataMain[] = [];

  public settings: StatisticUrlQuerySettings[][] = [
    [VENDOR_PART, CUSTOMER_PROJECT],
    [CUSTOMER_PROJECT, ESTIMATED_END_OF_LIFE_RANGE],
    [CUSTOMER_PROJECT, KDA_VENDOR_PART],
  ];

  public loading = false;

  public projectName = '';

  public async handleSubmit(): Promise<void> {
    this.loading = true;
    if (this.projectName) {
      this.items = await fetchCustomerProjectChartsData(this.projectName);
    }
    this.loading = false;
  }
}
