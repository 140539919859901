














import { Component, Vue, Prop } from 'vue-property-decorator';
import InfoContainer from '@/components/ProductDetail/InfoContainer.vue';
import { ItemsSet } from '@/interfaces';

@Component({
  components: {
    InfoContainer,
  },
})
export default class ExpansionPanel extends Vue {
  @Prop() items!: ItemsSet[];

  @Prop({ default: true }) checkboxes!: boolean;

  public openedPanels: number[] = [];

  public created(): void {
    this.openedPanels = [...Array(this.items.length).keys()];
  }
}
