
































import { Component, Vue, Prop, Watch, Inject } from 'vue-property-decorator';
import { fetchPartNumber } from '@/api/drilldown';
import User from '@/models/User';
import { PermissionsRouter } from '@/interfaces';
import UIPermissions from '@/models/UIPermissions';
import hasPermission from '@/helpers/hasPermission';
import historyStore from '@/stores/history';
import eventBus from '@/eventBus';

@Component
export default class ProductDetail extends Vue {
  @Inject() user!: User;

  @Prop() id!: string;

  @Watch('id')
  public onIdChange(newVal: string): void {
    this.notFound = false;
    this.partNumber = '';
    fetchPartNumber(newVal).then((value) => {
      if (value) {
        this.partNumber = value.number;
        this.user.browsingItems = value.data;
        this.store.$patch({ values: value.data });
        eventBus.$emit('partNumber', this.partNumber);
        this.navigate();
      } else {
        this.notFound = true;
      }
    });
  }

  public store = historyStore();

  public partNumber = '';

  public notFound = false;

  public navigatorPerPermissions: PermissionsRouter[] = [
    {
      name: 'item.general',
      permissions: [UIPermissions.GENERAL_INFORMATION],
    },
    {
      name: 'item.customer',
      permissions: [],
    },
    {
      name: 'item.attrributes',
      permissions: [
        UIPermissions.MANUFACTERING_PROCESS_DATA,
        UIPermissions.SUPPLIER_ANALYSIS,
        UIPermissions.ENVIOMENTAL_INFORMATION,
        UIPermissions.EDA_AND_CAD_INFORMATION,
        UIPermissions.TECHNICAL_INFORMATION,
      ],
    },
    {
      name: 'item.vendor',
      permissions: [UIPermissions.VENDOR_TAB],
    },
    {
      name: 'item.compliance',
      permissions: [UIPermissions.COMPLIANCE_TAB],
    },
    // {
    //   name: 'item.supplierData',
    //   permissions: [UIPermissions.SUPPLIER_DATA],
    // },
    {
      name: 'item.documents',
      permissions: [],
    },
    {
      name: 'item.partList',
      permissions: [UIPermissions.PART_LIST],
    },
    {
      name: 'item.whereUsed',
      permissions: [UIPermissions.WHERE_USED],
    },
    {
      name: 'item.spiceModel',
      permissions: [UIPermissions.PSPICE_MODEL],
    },
    {
      name: 'item.schematicPart',
      permissions: [UIPermissions.SCHEMATIC_PART],
    },
    {
      name: 'item.pcbFootprint',
      permissions: [UIPermissions.PCB_FOOTPRINT],
    },
    {
      name: 'item.history',
      permissions: [UIPermissions.HISTORY],
    },
    {
      name: 'item.statistics',
      permissions: [UIPermissions.BOM_STATISTIC],
    },
  ];

  async created(): Promise<void> {
    fetchPartNumber(this.id).then((value) => {
      this.notFound = false;
      if (value) {
        this.partNumber = value.number;
        this.user.browsingItems = value.data;
        this.store.$patch({ values: value.data });
        eventBus.$emit('partNumber', this.partNumber);
        this.navigate();
      } else {
        this.notFound = true;
      }
    });
  }

  public navigate(): void {
    if (this.$route.name === 'item') {
      const route = this.navigatorPerPermissions.find((e) =>
        hasPermission(this.user.permissions, e.permissions)
      );
      if (route) {
        this.$router.push({ name: route.name });
      }
    }
  }
}
