import { Colors, Language, MainFilter, Filter, Link, SpecialCell, LinkDictionary, ReplaceStrings, ReportDictionary } from '@/interfaces';
import Filters from '@/models/Filters';
import UIPermissions from '@/models/UIPermissions';
import Report from '@/models/Report';

const defaultLocale = 'en';

const availableLanguages: Language[] = [
  { title: 'English', value: 'en' },
  { title: 'Norsk', value: 'no' },
];

const links: Link[] = [
  {
    itemName: 'Part_Number',
    linkName: 'item/id',
    routerName: 'item',
    attributes: [
      {
        name: 'id',
        key: 'id',
      },
      {
        name: 'id',
        key: 'idItem',
      },
    ],
  },
  {
    itemName: 'Declaration',
    linkName: 'item/id/compliance/main/idDeclaration/declaration',
    routerName: 'item.compliance.main.overallCompliance.declaration',
    attributes: [
      {
        name: 'id',
        key: 'id',
      },
      {
        name: 'id',
        key: 'idItem',
      },
      {
        name: 'idDeclaration',
        key: 'idDeclaration',
      },
    ],
  },
  {
    itemName: 'Specification',
    linkName: 'item/id/compliance/main/idDeclaration/specification',
    routerName: 'item.compliance.main.overallCompliance.specification',
    attributes: [
      {
        name: 'id',
        key: 'id',
      },
      {
        name: 'id',
        key: 'idItem',
      },
      {
        name: 'idDeclaration',
        key: 'idDeclaration',
      },
    ],
  },
];

const urls: string[] = ['Datasheet_URL', 'Additional_Datasheet_URL', 'Material_Declaration_URL'];

const disableHeaders: string[] = [
  'id',
  'expandable',
  'idItem',
  'idDeclaration',
  'idSpecification',
  'bom__item__id',
  'bomline__id',
  'bom__id',
  'tree',
  'bomtree__parents_ids',
  'parentsIds',
  'parents_ids',
  'bomline__items__id',
  'Group_ID',
  'artificial',
  'additionalsItemsFetched',
  'risk_status',
  'Child_ID',
  'Child_Level',
  'Parent_ID',
  'groupByInit',
  'files__id',
  'bom_id',
];

const dateFields: string[] = [
  'Last_Time_Repair',
  'End_of_Life',
  'Last_Time_Buy',
];

const mainFilters: MainFilter[] = [
  {
    autocomplete: true,
    options: [],
    filter: {
      text: 'Select Part Class',
      value: Filters.PART_CLASS,
      search: '',
    },
    permission: UIPermissions.ALLOWED,
  },
  {
    autocomplete: false,
    options: [],
    filter: {
      text: 'Customer Part Number',
      value: Filters.CUSTOMER_PART_NUMBER,
      search: '',
    },
    permission: UIPermissions.ALLOWED,
  },
  {
    autocomplete: true,
    options: [],
    filter: {
      text: 'Customer Part Status',
      value: Filters.CUSTOMER_PART_STATUS,
      search: '',
    },
    permission: UIPermissions.ALLOWED,
  },
  {
    autocomplete: false,
    options: [],
    filter: {
      text: 'Customer Projects',
      value: Filters.CUSTOMER_PROJECTS,
      search: '',
    },
    permission: UIPermissions.FILTER_CUSTOMER_PROJECT_DASHBOARD,
  },
  {
    autocomplete: false,
    options: [],
    filter: {
      text: 'Vendor Part Number',
      value: Filters.VENDOR_PART_NUMBER,
      search: '',
    },
    permission: UIPermissions.ALLOWED,
  },
];

const declarationFilters: MainFilter[] = [
  {
    autocomplete: false,
    options: [],
    filter: {
      text: 'CAS Number',
      value: Filters.CAS_NUMBER,
      search: '',
    },
    permission: UIPermissions.CAS_NUMBER_TAB,
  },
  {
    autocomplete: false,
    options: [],
    filter: {
      text: 'Substance Name',
      value: Filters.SUBSTANCE,
      search: '',
    },
    permission: UIPermissions.SUBSTANCE_NAME_TAB,
  },
  {
    autocomplete: false,
    options: [],
    filter: {
      text: 'Result PPM',
      value: Filters.RESULT_PPM,
      search: '',
    },
    permission: UIPermissions.PPM_RESULT_TAB,
  },
];

const disableFilters: string[] = ['Compliance_Calculated_Date'];

const filterWithOptions: Filter[] = [
  {
    text: 'Lifecycle Phase',
    value: 'Lifecycle_Phase',
    search: '',
    options: ['Released', 'Obsolete'],
  },
  {
    text: 'Vendor Part Status',
    value: 'Vendor_Part_Status',
    search: '',
    options: ['Preliminary', 'Active', 'NRND', 'End of Life', 'Obsolete', 'Unconfirmed'],
  },
  {
    text: 'KDA Vendor Part Status',
    value: 'KDA_Vendor_Part_Status',
    search: '',
    options: ['Mature', 'Obsolete', 'Unknown', 'Decline', 'Phase-out', 'Growth', 'Development'],
  },
  {
    text: 'Repairable',
    value: 'Repairable',
    search: '',
    options: ['Yes', 'No', 'Not Applicable'],
  },
  {
    text: 'MD Service Status',
    value: 'MD_Service_Status',
    search: '',
    options: ['Implemented', 'Not Available', 'Data Preparation', 'Not Applicable', 'Not Requested'],
  },
  // {
  //   text: 'Schematic Part',
  //   value: 'Schematic_Part',
  //   search: '',
  //   options: ['Null', 'Not Null'],
  // },
  // {
  //   text: 'PCB Footprint',
  //   value: 'PCB_Footprint',
  //   search: '',
  //   options: ['Null', 'Not Null'],
  // },
];

const replaceStrings: ReplaceStrings[] = [
  {
    current: '%25',
    new: '%',
  },
];

const customerPartStatus: string[] = ['Released', 'Obsolete', 'NRND', 'Under Review', 'Production'];

const themeDefault: Colors = {
  primary: '#007DBD',
  secondary: '#46783C',
  accent: '#007DBD',
  // error: '#D4A124',
  // info: '#D4A124',
  // success: '#D4A124',
  // warning: '#D4A124',
};

const specialCellsConfig: SpecialCell[] = [
  {
    color: '#BE02E9',
    icon: 'mdi-check-circle',
    name: 'Preliminary',
    header: 'Vendor_Part_Status',
  },
  {
    color: '#43ac6a',
    icon: 'mdi-check-circle',
    name: 'Active',
    header: 'Vendor_Part_Status',
  },
  {
    color: '#e99002',
    icon: 'mdi-close-circle',
    name: 'NRND',
    header: 'Vendor_Part_Status',
  },
  {
    color: '#5bc0de',
    icon: 'mdi-close-circle',
    name: 'End of Life',
    header: 'Vendor_Part_Status',
  },
  {
    color: '#f04124',
    icon: 'mdi-close-circle',
    name: 'Obsolete',
    header: 'Vendor_Part_Status',
  },
  {
    color: '#949fb1',
    icon: 'mdi-help-circle',
    name: 'Unconfirmed',
    header: 'Vendor_Part_Status',
  },
];

const specialCustomerPartRiskConfig: SpecialCell[] = [
  {
    color: '#43AC6A',
    icon: 'mdi-check-circle',
    name: 'Active',
  },
  {
    color: '#E99002',
    icon: 'mdi-check-circle',
    name: 'Medium Risk',
  },
  {
    color: '#5BBFDE',
    icon: 'mdi-check-circle',
    name: 'High Risk',
  },
  {
    color: '#F04124',
    icon: 'mdi-close-circle',
    name: 'Obsolete',
  },
  {
    color: '#A1A1A1',
    icon: 'mdi-help-circle',
    name: 'Unknown',
  },
];

const unclickableFields = ['To be verified'];

const multiValueFields = [Filters.CUSTOMER_PARTS];

const multiValueDelimiter = '|';

const queryValueFields = [Filters.CUSTOMER_PART_NUMBER];

const fileFields = ['File_Name'];

const reports: ReportDictionary[] = [
  {
    name: Report.GREEN_BOM_REPORT,
    endpoint: 'green',
  },
  {
    name: Report.OBSOLESCENCE_CUSTOM_REPORT,
    endpoint: 'obsolescence',
  },
];

const linksDictionary: LinkDictionary[] = [
  {
    queryName: Filters.CUSTOMER_PART_NUMBER,
    tableName: Filters.CUSTOMER_PARTS,
  },
];

export {
  mainFilters,
  filterWithOptions,
  themeDefault,
  defaultLocale,
  availableLanguages,
  links,
  urls,
  disableHeaders,
  customerPartStatus,
  specialCellsConfig,
  multiValueFields,
  multiValueDelimiter,
  unclickableFields,
  fileFields,
  queryValueFields,
  linksDictionary,
  specialCustomerPartRiskConfig,
  replaceStrings,
  dateFields,
  declarationFilters,
  disableFilters,
  reports,
};
