








import { Component, Vue, PropSync, Watch } from 'vue-property-decorator';

@Component
export default class SimpleSnackbar extends Vue {
  @PropSync('show', { required: true, default: false }) showSnackbar!: boolean;

  @PropSync('type', { required: false, default: 'success' }) snackbarType!: string;

  @Watch('show')
  showAlert(): void {
    this.snackbar = true;
  }

  public snackbar = false;

  public timeout = 5000;
}
