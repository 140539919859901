import getAxiosBase, { cancelSource } from '@/api/axios-base';
import { StatisticDataMain } from '@/interfaces';

export async function fetchVendorChartsData(): Promise<StatisticDataMain[]> {
  const data: StatisticDataMain[] = await getAxiosBase()
    .get('/notefied/statistic/vendor/', { signal: cancelSource })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
      return [];
    });
  return data;
}

export async function fetchEstimatedChartsData(): Promise<StatisticDataMain[]> {
  const data: StatisticDataMain[] = await getAxiosBase()
    .get('/notefied/statistic/end-of-life/', { signal: cancelSource })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
      return [];
    });
  return data;
}

export async function fetchCustomerProjectChartsData(name: string): Promise<StatisticDataMain[]> {
  const data: StatisticDataMain[] = await getAxiosBase()
    .get('/notefied/statistic/customer-project/', { params: { name }, signal: cancelSource })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
      return [];
    });
  return data;
}
