
















































import { Component, Vue, PropSync, Emit, Inject, Prop } from 'vue-property-decorator';
import { FetchedImages, ImageToDownload } from '@/interfaces';
import User from '@/models/User';

@Component
export default class ImagePreview extends Vue {
  @Inject() user!: User;

  @PropSync('files', { required: true }) images!: FetchedImages[];

  @PropSync('label', { required: true }) selectLabel!: string;

  @PropSync('loading', { required: true }) isLoading!: boolean;

  @PropSync('canDownload', { required: true }) downloadPermission!: boolean;

  @Prop() maxHeigth!: string;

  @Prop() maxWidth!: string;

  @Prop() minWidth!: string;

  @Prop() minHeigth!: string;

  @PropSync('folder', { required: true }) folderType!: string;

  @Prop({ default: true }) enableDownload!: boolean;

  public imageName = '';

  get showImg(): string {
    const selectedImage = this.images.find((e) => e.name === this.imageName);

    return selectedImage ? selectedImage.img : '';
  }

  get disableDownload(): boolean {
    return !this.user.downloadPermissions.includes(this.folderType);
  }

  get imageType(): string {
    return this.images.find((e) => e.name === this.imageName)?.type ?? '';
  }

  mounted(): void {
    this.imageName = this.images.length > 0 ? this.images[0].name : '';
  }

  @Emit('fetchFiles')
  public fetchFiles(): ImageToDownload {
    return { name: this.imageName, type: this.imageType };
  }
}
