var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"600px","overlay-opacity":"0.8","content-class":"scroll"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onMenu = ref.on;
var attrsMenu = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":_vm.disabled}},'v-btn',attrsMenu,false),Object.assign({}, onMenu, onTooltip)),[_c('v-icon',[_vm._v("mdi-application-settings")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.adminView')))])])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('SimpleSnackbar',{attrs:{"show":_vm.showSnackbar,"type":"error"}},[_vm._v(_vm._s(_vm.$t('table.adminView.error')))]),_c('v-card',{staticStyle:{"padding":"16px"}},[_c('v-card-title',[_vm._v(_vm._s(_vm.$t('table.adminView.title')))]),_c('v-divider'),_c('v-card',{staticStyle:{"padding":"16px"},attrs:{"elevation":"0"}},[_c('v-form',{ref:"form"},[_c('v-text-field',{attrs:{"label":_vm.$t('table.adminView.name'),"required":""},model:{value:(_vm.viewForm.name),callback:function ($$v) {_vm.$set(_vm.viewForm, "name", $$v)},expression:"viewForm.name"}}),_c('v-combobox',{attrs:{"id":"default-view-select","items":_vm.customers,"item-text":"name","item-value":"id","label":_vm.$t('table.adminView.customer')},on:{"keyup":_vm.handleViewKeyUp},model:{value:(_vm.viewForm.customer),callback:function ($$v) {_vm.$set(_vm.viewForm, "customer", $$v)},expression:"viewForm.customer"}}),_c('v-select',{attrs:{"items":_vm.availableUsers,"label":_vm.$t('table.adminView.users'),"item-text":"email","item-value":"item","return-object":"","multiple":""},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"mousedown":function($event){$event.preventDefault();},"click":_vm.toggle}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":_vm.viewForm.users.length > 0 ? 'indigo darken-4' : ''}},[_vm._v(" "+_vm._s(_vm.icon)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Select All ")])],1)],1),_c('v-divider',{staticClass:"mt-2"})]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item.email))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.viewForm.users.length - 1)+" others) ")]):_vm._e()]}}]),model:{value:(_vm.viewForm.users),callback:function ($$v) {_vm.$set(_vm.viewForm, "users", $$v)},expression:"viewForm.users"}})],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","loading":_vm.loadingAddUpdateButton,"disabled":_vm.disabledAddBtn},on:{"click":_vm.handleSubmit}},[_vm._v(" "+_vm._s(_vm.$t('table.adminView.add'))+" ")]),_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t('table.exportView.close'))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }