



















import { Component, Mixins } from 'vue-property-decorator';
import TableComponent from '@/components/Table/TableComponent.vue';
import SpecificationInfo from '@/components/ProductDetail/Compliance/OverallCompliance/SpecificationTab/SpecificationInfo.vue';
import TableMixin from '@/mixins/tableMixins';
import { fetchSpecificationInfo, fetchSpecificationSubstance } from '@/api/drilldown';
import { ItemsSet, DetailItem, View, FetchedData } from '@/interfaces';

@Component({
  components: {
    TableComponent,
    SpecificationInfo,
  },
})
export default class ComplianceSpecification extends Mixins(TableMixin) {
  public defaultView: View = {
    name: 'Default',
    fields: ['Name', 'CAS_Number', 'Threshold_Mass_PPM'],
    default: false,
    selected: true,
  };

  private specificationInfo: ItemsSet[] = [];

  public isLoadingAdditionalInfo = true;

  async mounted(): Promise<void> {
    this.isExportDisabled = true;
    this.getURLParams();
    const promiseData = fetchSpecificationSubstance({
      id: this.idDeclaration,
      filters: this.query,
      lowerThreshold: this.itemsRange[0],
      upperThreshold: this.itemsRange[1],
      sortingName: this.sorting.name,
      sortingOrder: this.sorting.order,
    });
    const promiseInfo = fetchSpecificationInfo(this.idDeclaration);
    await Promise.all<FetchedData, ItemsSet[]>([promiseData, promiseInfo]).then((result) => {
      [this.data, this.specificationInfo] = result;
      this.isLoadingAdditionalInfo = false;
    });
    this.isExportDisabled = false;
    this.isLoadingNewRecords = false;
    this.emitListener();
  }

  get mappedData(): ItemsSet[] {
    const mappedData: ItemsSet[] = this.specificationInfo.map((e) => {
      const item: DetailItem[] = [];
      e.item.forEach((detailItem) => {
        item.push({ ...detailItem, selected: null });
      });
      const element = { name: e.name, item };
      return element;
    });
    return mappedData;
  }

  public async fetchData(): Promise<void> {
    this.isExportDisabled = true;
    this.data = await fetchSpecificationSubstance({
      id: this.idDeclaration,
      filters: this.query,
      lowerThreshold: this.itemsRange[0],
      upperThreshold: this.itemsRange[1],
      sortingName: this.sorting.name,
      sortingOrder: this.sorting.order,
    });
    this.isExportDisabled = false;
  }
}
