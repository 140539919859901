import getAxiosBase, { cancelSource } from '@/api/axios-base';
import downloadFile from '@/helpers/downloadFile';
import { DashboardExportQuery } from '@/interfaces';

export async function exportDeclarationXML(id: string, fileName: string): Promise<boolean> {
  const success: boolean = await getAxiosBase()
    .get('/notefied/export/declaration/xml/', {
      params: { id, fileName },
      signal: cancelSource,
      responseType: 'arraybuffer',
    })
    .then(async (response) => {
      if (response.status === 200) {
        const file = new Blob([response.data], {
          type: response.headers['content-type'],
        });
        downloadFile(file, fileName);
        return true;
      }
      return false;
    })
    .catch((error) => {
      console.error(error);
      return false;
    });

  return success;
}

export async function exportReport(
  id: string,
  fileName: string,
  endpoint: string
): Promise<boolean> {
  const success: boolean = await getAxiosBase()
    .get(`/notefied/export/${endpoint}/`, {
      params: { id, fileName },
      signal: cancelSource,
      responseType: 'arraybuffer',
    })
    .then(async (response) => {
      if (response.status === 200) {
        const file = new Blob([response.data], {
          type: response.headers['content-type'],
        });
        const content = response.headers['content-disposition'];
        downloadFile(file, content.split('filename=')[1]);
        return true;
      }
      return false;
    })
    .catch((error) => {
      console.error(error);
      return false;
    });

  return success;
}

export async function exportMain(data: DashboardExportQuery): Promise<boolean> {
  const success: boolean = await getAxiosBase()
    .post(
      '/notefied/export/main/',
      { ...data },
      { signal: cancelSource, responseType: 'arraybuffer' }
    )
    .then(async (response) => {
      if (response.status === 200) {
        const file = new Blob([response.data], {
          type: response.headers['content-type'],
        });
        const content = response.headers['content-disposition'];
        downloadFile(file, content.split('filename=')[1]);
        return true;
      }
      return false;
    })
    .catch((error) => {
      console.error(error);
      return false;
    });

  return success;
}
