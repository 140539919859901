






























































import { Component, Vue, Inject, Emit } from 'vue-property-decorator';
import LocaleSwitcher from '@/components/LocaleSwitcher.vue';
import User from '@/models/User';
import fetchFile from '@/api/file';

@Component({
  components: {
    LocaleSwitcher,
  },
})
export default class UserMenu extends Vue {
  @Inject('user') user!: User;

  public dialog = true;

  public async logout(): Promise<void> {
    await this.user.logout();
    this.$router.push({ name: 'login' });
  }

  @Emit('openDialog')
  public openDialog(): boolean {
    return this.dialog;
  }

  @Emit('openPasswordDialog')
  public openPasswordDialog(): boolean {
    return this.dialog;
  }

  public async getHelp(): Promise<void> {
    await fetchFile({
      endpoint: 'help',
    });
  }

  public async getChangelog(): Promise<void> {
    await fetchFile({
      endpoint: 'changelog',
    });
  }
}
